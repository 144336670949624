import * as Yup from 'yup'
import { FormTextInput } from 'src/components/forms/FormTextInput'
import { FormSelectPartners } from 'src/components/forms/selects/FormSelectPartners'
import { updateBranch } from 'src/services/requests/branches'
import { useTranslation } from 'react-i18next'
import { Formik, Form } from 'formik'
import { FormSelectCountriesDisabled } from 'src/components/forms/selects/FormSelectCountriesDisabled'
import { EditDrawerHeading } from 'src/components/table/EditDrawerHeading'
import { EditDrawerFooter } from 'src/components/table/EditDrawerFooter'
import { useContext, useState } from 'react'
import { CFormFeedback } from '@coreui/react-pro'
import { AuthContext } from 'src/contexts/authContext'

export const EditBranchForm = ({
  rowData,
  fetchData,
  setEditOffcanvasVisible,
  setDeactivateOffcanvasVisible,
  setIsLoading,
  unsavedChangesOffcanvasVisible,
  setUnsavedChangesOffcanvasVisible,
  initialValuesChanged,
  setInitialvaluesChanged,
  setOverviewOffcanvasVisible,
}) => {
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  const { t } = useTranslation()
  const authContext = useContext(AuthContext)

  const handleSubmit = async (values) => {
    setIsLoading(true)
    const body = {
      type: 'building_contractor',
      name: values.name,
      country: values.country,
      legal_entity: {
        vat_num: values.vat,
        purpose: values.purpose || undefined,
      },
      address: {
        street: values.street,
        postal_code: values.postalCode,
        house_nr: values.houseNumber,
        city: values.city,
        country: values.country,
        contact_email: values.email,
        lon: Number(values.longitude) || undefined,
        lat: Number(values.latitude) || undefined,
      },
      parent_id: values.partner,
      domain: values.domain || undefined,
    }

    try {
      await updateBranch(rowData.id, JSON.stringify(body))
      await fetchData()
      setEditOffcanvasVisible(false)
      setOverviewOffcanvasVisible(true)
    } catch (error) {
      setErrorMsg(error.response.data.error)
      setError(true)
    }
    setIsLoading(false)
  }

  if (!rowData) return

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        partner: rowData.parent_id,
        name: rowData.name,
        branchCountry: rowData.address.country,
        vat: rowData.legal_entity.vat_num,
        purpose: rowData.legal_entity.purpose,
        email: rowData.address.contact_email,
        country: rowData.country,
        street: rowData.address.street,
        houseNumber: rowData.address.house_nr,
        city: rowData.address.city,
        postalCode: rowData.address.postal_code,
        domain: rowData.domain,
        longitude: rowData.address.lon,
        latitude: rowData.address.lat,
      }}
      onSubmit={(values) => handleSubmit(values)}
      validationSchema={Yup.object({
        partner: Yup.string().required(t('Yup.validation.error.required')),
        name: Yup.string().required(t('Yup.validation.error.required')),
        vat: Yup.string().required(t('Yup.validation.error.required')),
        purpose: Yup.string(),
        domain: Yup.string().min(3, t('Yup.validation.error.domain.minLength')),
        branchCountry: Yup.string()
          .required(t('Yup.validation.error.required'))
          .min(2, t('Yup.validation.error.country.length'))
          .max(2, t('Yup.validation.error.country.length')),
        email: Yup.string()
          .required(t('Yup.validation.error.required'))
          .email(t('Yup.validation.error.invalid.email')),
        country: Yup.string()
          .required(t('Yup.validation.error.required'))
          .min(2, t('Yup.validation.error.country.length'))
          .max(2, t('Yup.validation.error.country.length')),
        street: Yup.string().required(t('Yup.validation.error.required')),
        houseNumber: Yup.string(),
        city: Yup.string().required(t('Yup.validation.error.required')),
        postalCode: Yup.string().required(t('Yup.validation.error.required')),
        latitude: Yup.number().typeError(t('Yup.validation.error.invalid.lat')),
        longitude: Yup.number().typeError(t('Yup.validation.error.invalid.lon')),
      })}
    >
      <Form onChange={() => setInitialvaluesChanged(true)}>
        <div className="edit-drawer__body">
          <EditDrawerHeading text={t('Backoffice.Wizard.AddBranch.steps.first')} />
          <FormSelectPartners />
          <FormTextInput
            placeholder={t('Backoffice.Wizard.AddBranch.label.name')}
            type="text"
            id="name"
            name="name"
          />
          <FormSelectCountriesDisabled name="branchCountry" id="branchCountry" />
          <FormTextInput
            placeholder={t('Backoffice.Wizard.AddBranch.label.vat')}
            type="text"
            id="vat"
            name="vat"
          />
          <FormTextInput
            placeholder={t('Backoffice.Wizard.AddBranch.label.purpose')}
            type="text"
            id="purpose"
            name="purpose"
          />
          <FormTextInput
            placeholder={t('Backoffice.Wizard.AddBranch.label.domain')}
            type="text"
            id="domain"
            name="domain"
          />
          <EditDrawerHeading text={t('Backoffice.Wizard.AddBranch.steps.second')} />
          <FormTextInput
            placeholder={t('Backoffice.Wizard.AddBranch.label.email')}
            type="email"
            id="email"
            name="email"
          />
          <FormSelectCountriesDisabled id="country" name="country" />
          <FormTextInput
            placeholder={t('Backoffice.Wizard.AddBranch.label.street')}
            type="text"
            id="street"
            name="street"
          />
          <FormTextInput
            placeholder={t('Backoffice.Wizard.AddBranch.label.houseNumber')}
            type="text"
            id="houseNumber"
            name="houseNumber"
          />
          <FormTextInput
            placeholder={t('Backoffice.Wizard.AddBranch.label.postalCode')}
            type="text"
            id="postalCode"
            name="postalCode"
          />
          <FormTextInput
            placeholder={t('Backoffice.Wizard.AddBranch.label.city')}
            type="text"
            id="city"
            name="city"
          />
          <FormTextInput
            placeholder={t('Backoffice.Wizard.AddProject.label.latitude')}
            type="text"
            id="latitude"
            name="latitude"
          />
          <FormTextInput
            placeholder={t('Backoffice.Wizard.AddProject.label.longitude')}
            type="text"
            id="longitude"
            name="longitude"
          />
        </div>
        {error && (
          <CFormFeedback className="text-start" invalid>
            {errorMsg}
          </CFormFeedback>
        )}
        <EditDrawerFooter
          initialValuesChanged={initialValuesChanged}
          setEditOffcanvasVisible={setEditOffcanvasVisible}
          setDeactivateOffcanvasVisible={setDeactivateOffcanvasVisible}
          unsavedChangesOffcanvasVisible={unsavedChangesOffcanvasVisible}
          setUnsavedChangesOffcanvasVisible={setUnsavedChangesOffcanvasVisible}
        />
      </Form>
    </Formik>
  )
}
