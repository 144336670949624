import React, { useEffect, useState, useRef } from 'react'
import { CHeaderToggler, CNav, CNavItem } from '@coreui/react-pro'
import { useTranslation } from 'react-i18next'
import SelectLanguage from 'src/components/SelectLanguage/SelectLanguage'
import { UserDropdown } from 'src/components/UserDropdown/UserDropdown'
import { useBackofficeParams } from 'src/hooks/useBackofficeParams'
import CIcon from '@coreui/icons-react'
import { cilHamburgerMenu } from '@coreui/icons-pro'

export default function BackOfficeHeader({ toggleSidebar }) {
  const { selectedTab } = useBackofficeParams()
  const { t } = useTranslation()

  const [openedUserPopover, setIsOpenedUserPopover] = useState(false)
  const userPopoverRef = useRef(null)

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleOutsideClicks)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleOutsideClicks)
    }
  }, [openedUserPopover])

  //create a function in your component to handleOutsideClicks
  const handleOutsideClicks = (event) => {
    if (openedUserPopover && !userPopoverRef.current.contains(event.target)) {
      setIsOpenedUserPopover(false)
    }
  }

  const subpageTitle = selectedTab
    ? t(`Backoffice.layout.subpage.${selectedTab.replace('/', '.')}`)
    : ''

  return (
    <div className="backoffice-header mb-4">
      <CNav className="justify-content-between align-items-center backoffice-top-navigation">
        <div className="d-flex align-items-center">
          <CNavItem>
            <CHeaderToggler className="d-flex align-items-center mx-2" onClick={toggleSidebar}>
              <CIcon size="xl" icon={cilHamburgerMenu} />
            </CHeaderToggler>
          </CNavItem>
          <CNavItem className="ms-2">
            <h2 className="h4 spartan-font m-0">
              {t('Backoffice.layout.title.partnerManagement')}
              {`: ${subpageTitle}`}
            </h2>
          </CNavItem>
        </div>
        <div className="d-flex align-items-center">
          <UserDropdown
            openedUserPopover={openedUserPopover}
            setIsOpenedUserPopover={setIsOpenedUserPopover}
            userPopoverRef={userPopoverRef}
          />
          <SelectLanguage />
        </div>
      </CNav>
    </div>
  )
}
