import axios from 'axios'
import * as cognito from './../services/cognito'
import { authEventEmitter } from 'src/contexts/authContext'
import { errorEventEmitter } from 'src/contexts/globalVariablesContext'

export const instance = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    'Content-Type': 'application/json',
  },
})

const handleSignout = () => {
  authEventEmitter.emit('signout')
}

const reportError = (error) => {
  let array = window.localStorage.getItem('errors')
  array = array ? JSON.parse(array) : []
  array.push(error?.response?.data?.message ?? error?.response?.data?.error)
  window.localStorage.setItem('errors', JSON.stringify(array))
  errorEventEmitter.emit('error')
}

instance.interceptors.request.use(
  async (config) => {
    let token
    try {
      // Note: Cognito self manages the access token
      // and also uses the refresh token to get new access token
      // in case the token gets expired

      // Reason for not adding it in the authContext is because when calls would be sent on page refresh,
      // interceptor does not get attached before the calls get made, which adds glitch of 401 even though
      // the user is authenticated
      const session = await cognito.getSession()
      token = session.idToken.getJwtToken()
    } catch (err) {
      token = null
    }
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

instance.interceptors.response.use(
  (res) => {
    return res
  },
  (error) => {
    if (error.response) {
      if (error.response.status !== 401) {
        reportError(error)
      }

      // Token was expired
      if (error.response.status === 401 || error.response.code === 'ERR_NETWORK') {
        handleSignout()
      }
    }
    // Token was expired
    if (error.status === 401 || error.code === 'ERR_NETWORK') {
      handleSignout()
    }

    return Promise.reject(error)
  },
)

export default instance
