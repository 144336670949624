import {
  getProjectMaterialViewProductDeliveries,
  createProjectMaterialViewProductDelivery,
  removeProjectMaterialViewProductDelivery,
  updateProjectMaterialViewProductDelivery,
} from 'src/services/requests/projectMaterialViewDeliveryDetails'

import {
  getBuildingProject,
  updateProductInProjectMaterialView,
  updateDeliveredQuantityInProjectMaterialView,
} from 'src/services/requests/projectMaterialView'

export const fetchProductDeliveries = (materialData) => {
  return getProjectMaterialViewProductDeliveries(materialData.id)
}

export const fetchProductDeliveriesAndUpdateMaterial = async (materialData) => {
  return new Promise(function (resolve, reject) {
    fetchProductDeliveries(materialData)
      .then((response) => {
        materialData.deliveries = response.data
        materialData.quantity = getDeliveriesTotalQuantity(response.data)
        materialData.update(materialData)
        resolve(response)
      })
      .catch((exception) => {
        reject(exception)
      })
  })
}

export const updateDelivery = async (materialData, deliveryId, data) => {
  return new Promise(function (resolve, reject) {
    updateProjectMaterialViewProductDelivery(materialData.id, deliveryId, data)
      .then((response) => {
        resolve(response)
      })
      .catch((exception) => {
        console.log(exception)
        reject(exception)
      })
  })
}

export const createDelivery = async (
  materialData,
  addressId,
  quantity,
  date,
  incoterm,
  onDemand,
) => {
  return new Promise(function (resolve, reject) {
    const today = getRoundedUpToHalfHour()
    const data = {
      quantity: quantity,
      address_id: addressId,
      delivery_date: onDemand ? undefined : date || today,
      delivery_incoterm: incoterm || 'DPU',
      on_demand: onDemand || false,
    }

    createProjectMaterialViewProductDelivery(materialData.id, data)
      .then((response) => {
        resolve(response)
      })
      .catch((exception) => {
        console.log(exception)
        reject(exception)
      })
  })
}

export const deleteDelivery = async (materialData, deliveryId) => {
  return new Promise(function (resolve, reject) {
    removeProjectMaterialViewProductDelivery(materialData.id, deliveryId)
      .then((response) => {
        resolve(response)
      })
      .catch((exception) => {
        reject(exception)
      })
  })
}

export const getDeliveryAddresses = (contextId, setDeliveryAddresses) => {
  getBuildingProject(contextId).then((response) => {
    setDeliveryAddresses(response.data.addresses)
  })
}

export const getDeliveriesTotalQuantity = (deliveries) => {
  let cnt = 0
  if (!deliveries) return cnt

  deliveries.forEach((delivery) => {
    cnt += Number(delivery.quantity)
  })

  return cnt
}

export const calculateDeliveryDefaultQuantity = (productDeliveries, materialData) => {
  let quantity =
    materialData.orderingUnits.find((orderingUnit) => {
      return orderingUnit.id == materialData.orderingUnitId
    }).minimumOrderQuantity || 1

  if (productDeliveries.length === 0) return Number(materialData.quantity)

  if (Number(materialData.quantity) > getDeliveriesTotalQuantity(productDeliveries)) {
    quantity = Number(materialData.quantity) - getDeliveriesTotalQuantity(productDeliveries)
  }

  return quantity
}

export const deliveryTerms = [
  {
    id: 'DAP',
    name: 'Delivered at place (DAP)',
  },
  {
    id: 'DPU',
    name: 'Delivered at place unloaded (DPU)',
  },
  {
    id: 'EXW',
    name: 'Ex works (EXW)',
  },
]

// Edited to be now + 1 hour
export const getRoundedUpToHalfHour = () => {
  const date = new Date()
  const minutes = date.getMinutes()
  if (minutes !== 0 && minutes !== 30) {
    date.setMinutes(minutes < 30 ? 30 : 0)
  }
  const hours = date.getHours()
  if (hours <= 6) {
    date.setHours(7)
  } else {
    date.setHours(hours + 1)
  }

  date.setSeconds(0, 0)
  return date.toISOString()
}

export const getDateForDays = (days) => {
  const date = new Date()
  date.setDate(date.getDate() + days)

  return date.toISOString()
}

export const updateProductQuantity = (id, quantity) => {
  return new Promise(function (resolve, reject) {
    updateProductInProjectMaterialView(id, { quantity: quantity })
      .then((response) => {
        resolve(response)
      })
      .catch((exception) => {
        reject(exception)
      })
  })
}

export const updateProductDeliveredQuantity = (id, quantity) => {
  return new Promise(function (resolve, reject) {
    updateProductInProjectMaterialView(id, { deliveredQuantity: quantity })
      .then((response) => {
        resolve(response)
      })
      .catch((exception) => {
        reject(exception)
      })
  })
}

export const updateDeliveredQuantity = (productId, deliveryId, quantity) => {
  return new Promise(function (resolve, reject) {
    updateDeliveredQuantityInProjectMaterialView(productId, deliveryId, {
      deliveredQuantity: quantity,
    })
      .then((response) => {
        resolve(response)
      })
      .catch((exception) => {
        reject(exception)
      })
  })
}

export const MATERIALSTATE = {
  IN_NEGOTIATION_BC_BLOCKED: 'in_negotiation_bc_blocked',
  IN_NEGOTIATION_BC_SENT: 'in_negotiation_bc_sent',
  IN_NEGOTIATION_MF_BLOCKED: 'in_negotiation_mf_blocked',
  IN_NEGOTIATION_MF_SENT: 'in_negotiation_mf_sent',
  IN_NEGOTIATION_BC_REJECTED: 'in_negotiation_bc_rejected',
  IN_NEGOTIATION_MF_REJECTED: 'in_negotiation_mf_rejected',
  IN_NEGOTIATION_AWAITING_MF_ERP_CONFIRMATION: 'in_negotiation_awaiting_mf_erp_confirmation',
  APPROVED: 'approved',
  EXPIRED: 'expired',
  REJECTED: 'rejected',
  NEW_ITEM: 'new_item',
  IDLE: 'IDLE',
}

//TODO : finalize this
export const location2QueryParam = (hash) => {
  switch (hash) {
    case '#in-negotiation':
      return 'inNegotiation'
    case '#approved':
      return 'approved'
    default:
      return 'newItems'
  }
}

export const PMVSTATES = {
  NEWITEMS: 'newItems',
}

export const setIdle = (material) => {
  if (material.state === MATERIALSTATE.IDLE) {
    return
  }

  material.prevState = material.state
  material.state = MATERIALSTATE.IDLE
  material.update(material)
}

export const setReady = (material) => {
  if (!material.prevState && material.state === MATERIALSTATE.IDLE) {
    throw 'Material state can not be set to ready'
  }
  material.state = material.prevState ? material.prevState : material.state
  delete material.prevState
  material.update(material)
}

export const updateAppliedDiscount = (material, resData) => {
  material.productPrices.totals = resData.productPrices?.totals
  material.update(material)
}
