import React, { useContext } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { AuthContext } from '../../contexts/authContext'

export const ProtectedRoute = ({ allowedPermissions }) => {
  const auth = useContext(AuthContext)
  const userPermissions = [auth.role]
  if (allowedPermissions.length === 0) {
    return <Navigate to="/signin" replace state={{ from: location }} />
  }
  const isPermitted = userPermissions.some((permission) => allowedPermissions.includes(permission))

  const location = useLocation()
  if (!isPermitted) {
    return <Navigate to="/" replace state={{ from: location }} />
  }
  return <Outlet />
}
