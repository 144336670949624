const Validator = require('jsonschema').Validator
const validator = new Validator()
import productSchema from './validationSchemas/productSchema.json'
const languages = ['EN', 'DE', 'IT', 'FR']

export const workbookProductsValidator = (workbook, partnerId) => {
  const errorsInProducts = {
    invalidProducts: {},
    missingProducts: {},
  }
  const data = {}
  workbook.worksheets.forEach((sheet) => {
    data[sheet.name] = []
    const firstRow = sheet.getRow(1)
    if (!firstRow.cellCount) return
    const keys = firstRow.values
    sheet.eachRow((row, index) => {
      if (index == 1) return
      const values = row.values
      const obj = {}
      for (let i = 1; i < keys.length; i++) {
        obj[keys[i]] = values[i]
      }
      data[sheet.name].push(obj)
    })
  })

  for (let tab in data) {
    if (Object.prototype.hasOwnProperty.call(data, tab) && tab !== 'products') {
      errorsInProducts.missingProducts[tab] = data[tab].filter(
        (item) =>
          !data.products.some(
            (productItem) =>
              item.ARTICLE_NUMBER &&
              productItem.ARTICLE_NUMBER &&
              item.ARTICLE_NUMBER.toString() === productItem.ARTICLE_NUMBER.toString(),
          ),
      )
    }
  }

  data.products.forEach((row, index, object) => {
    const product = {}
    product.mf_partner_id = partnerId
    product.article_number = row.ARTICLE_NUMBER && row.ARTICLE_NUMBER.toString()
    product.product_number = row.PRODUCT_NUMBER && row.PRODUCT_NUMBER.toString()
    product.vat_type = row.VAT_TYPE || 'standard'
    product.status = row.STATUS || 'active'
    product.name = mapValues(row, 'NAME')
    product.descriptions = mapDescriptions(row)
    product.images = []
    if (row.MAIN_IMAGE) {
      const mainImage = row.MAIN_IMAGE.text || row.MAIN_IMAGE
      product.images.push({
        type: 'main',
        value: mainImage,
      })
    }
    if (row.SECONDARY_IMAGES) {
      const secondaryImages = row.SECONDARY_IMAGES.text || row.SECONDARY_IMAGES
      secondaryImages
        .split(';')
        .map((e) => ({
          type: 'secondary',
          value: e,
        }))
        .forEach((e) => product.images.push(e))
    }
    product.documents = []
    if (data.product_documents) {
      data.product_documents
        .filter((e) => e.ARTICLE_NUMBER == row.ARTICLE_NUMBER)
        .map((e) => ({
          type: e.TYPE,
          value: e.VALUE.text || e.VALUE,
          language: e.LANGUAGE,
        }))
        .forEach((e) => product.documents.push(e))
    }
    product.base_unit = {}
    product.base_unit.type = row.BASE_UNIT_TYPE
    product.base_unit.descriptions = mapValues(row, 'BASE_UNIT')
    product.price = []
    if (row.LIST_PRICE || row.LIST_PRICE === 0) {
      product.price.push({
        value: parseFloat(row.LIST_PRICE),
        currency: row.LIST_PRICE_CURRENCY || 'CHF',
        type: 'list',
      })
    }
    if (row.MANUFACTURER) {
      product.manufacturer = row.MANUFACTURER
    }
    product.stock = parseInt(row.STOCK || 0)

    try {
      if (Number.isInteger(row.CATEGORIES)) {
        row.CATEGORIES = row.CATEGORIES.toString()
      }

      product.categories = row.CATEGORIES.split(';') || []
    } catch (error) {
      console.err(error)
    }

    product.product_dimensions = []
    if (data.product_dimensions) {
      data.product_dimensions
        .filter((e) => e.ARTICLE_NUMBER == row.ARTICLE_NUMBER)
        .map((e) => ({
          type: e.TYPE,
          value: parseFloat(e.VALUE),
          unit: e.UNIT,
        }))
        .forEach((e) => product.product_dimensions.push(e))
    }

    product.product_attributes = []
    if (data.product_attributes) {
      const attributes =
        data.product_attributes.filter((e) => e.ARTICLE_NUMBER == row.ARTICLE_NUMBER) || []

      const attributeIds = new Set()
      attributes.forEach((e) => attributeIds.add(e.ID))
      ;[...attributeIds].forEach((id) => {
        const attribute = {
          attribute_id: id,
          descriptions: [],
        }
        attributes
          .filter((e) => id === e.ID)
          .map((e) => ({
            value: e.VALUE.text || e.VALUE,
            language: e.LANGUAGE,
            name: e.NAME,
            description: e.DESCRIPTION,
          }))
          .forEach((e) => attribute.descriptions.push(e))
        product.product_attributes.push(attribute)
      })
    }

    product.product_ordering_units = []
    if (data.product_ordering_units) {
      data.product_ordering_units
        .filter((e) => e.ARTICLE_NUMBER == row.ARTICLE_NUMBER)
        .map((e) => {
          const orderingUnit = {
            type: e.TYPE,
            descriptions: mapValues(e, 'DESCRIPTION'),
            conversion_factor: parseFloat(e.CONVERTION_FACTOR),
            increment: parseFloat(e.INCREMENT),
            minimum_order_quantity: parseFloat(e.MINIMUM_ORDER_QUANTITY),
            incoterms: e.INCOTERMS && e.INCOTERMS.split(';'),
          }
          if (e.NET_WEIGHT || e.NET_WEIGHT === 0) {
            orderingUnit.net_weight = parseFloat(e.NET_WEIGHT)
          }
          orderingUnit.net_weight_unit = e.NET_WEIGHT_UNIT ? e.NET_WEIGHT_UNIT : ''
          return orderingUnit
        })
        .forEach((e) => product.product_ordering_units.push(e))
    }

    product.product_shipping_units = []
    if (data.product_shipping_units) {
      data.product_shipping_units
        .filter((e) => e.ARTICLE_NUMBER == row.ARTICLE_NUMBER)
        .map((e) => ({
          type: e.TYPE,
          conversion_factor: parseFloat(e.CONVERTION_FACTOR),
          reference: e.REFERENCE,
        }))
        .forEach((e) => product.product_shipping_units.push(e))
    }

    let validityResult = validate(product)
    if (validityResult) {
      errorsInProducts.invalidProducts[product.article_number] = validityResult
    }
    object.splice(index, 1)
  })
  return errorsInProducts
}

const mapValues = (el, id) => {
  return languages.reduce((values, lang) => {
    if (el[`${id}_${lang}`]) {
      values.push({
        value: el[`${id}_${lang}`],
        language: lang.toLowerCase(),
      })
    }
    return values
  }, [])
}

const mapDescriptions = (el) => {
  return languages.reduce((values, lang) => {
    if (el[`DESCRIPTION_${lang}`] || el[`SHORT_DESCRIPTION_${lang}`]) {
      values.push({
        description: el[`DESCRIPTION_${lang}`],
        shortDescription: el[`SHORT_DESCRIPTION_${lang}`],
        language: lang.toLowerCase(),
      })
    }
    return values
  }, [])
}

const validate = (product) => {
  let validity = validator.validate(product, productSchema)

  if (validity.errors && validity.errors.length > 0) {
    return validity.errors
  }
}
