import { CFormTextarea } from '@coreui/react-pro'
import { useField } from 'formik'
import cn from 'classnames'
import { FormInvalidFeedback } from './FormInvalidFeedback'

export const FormTextarea = ({ className, type, id, placeholder, name, disabled }) => {
  const [field, meta] = useField({ type, id, placeholder, name })

  const isValid = !!(meta.touched && meta.error)
  const feedbackMsg = isValid ? meta.error : ''

  return (
    <div className={cn('my-3', className)}>
      <CFormTextarea
        floatingLabel={placeholder}
        type={type}
        id={id}
        disabled={disabled}
        placeholder={placeholder}
        name={name}
        {...field}
        invalid={isValid}
        valid={meta.touched && !isValid}
      />
      {isValid && <FormInvalidFeedback feedbackMsg={feedbackMsg} />}
    </div>
  )
}
