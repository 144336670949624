import React, { useRef } from 'react'
import { CToaster } from '@coreui/react-pro'
import Notification from './Notification'

const Notifications = ({ type, message, placement = 'top-end', ...props }) => {
  const toaster = useRef()

  return (
    <CToaster
      ref={toaster}
      push={<Notification type={type} message={message} />}
      placement={placement}
    />
  )
}

export default Notifications
