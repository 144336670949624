import httpsV2 from '../../config/httpsV2'

export const getAllProducts = () => {
  return httpsV2.get('/products')
}

export const getProduct = (id) => {
  return httpsV2.get(`/products/${id}`)
}

export const createProduct = (data) => {
  return httpsV2.post('/products', data)
}

export const updateProduct = (id, data) => {
  return httpsV2.put(`/products/${id}`, data)
}

export const removeProduct = (id) => {
  return httpsV2.delete(`/products/${id}`)
}

export const getProductUploadPreSignedURL = (fileName, manufacturerId) => {
  return httpsV2.get(`middleware/products/upload/?filename=${fileName}&partnerId=${manufacturerId}`)
}

export const getPriceUploadPreSignedURL = (
  fileName,
  manufacturerId,
  buildingContractorId,
  projectId,
) => {
  return httpsV2.get(
    `public/upload-prices/url/?filename=${fileName}&manufacturerId=${manufacturerId}&buildingContractorId=${buildingContractorId}` +
      (projectId ? `&projectId=${projectId}` : ''),
  )
}
