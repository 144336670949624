import React, { useState, useEffect } from 'react'
import chalk from 'chalk'
import * as logger from 'loglevel'
import prefix from 'loglevel-plugin-prefix'

export const LogContext = React.createContext({})
export const LogContextProvider = LogContext.Provider

const COMPONENTS = {
  MATERIAL: 'MATERIAL',
  DELIVERY: 'DELIVERY',
  MATERIALLIST: 'MATERIALLIST',
  MATERIALDELIVERYDETAILS: 'MATERIALDELIVERYDETAILS',
  STATEDEPENDENDMATERIAL: 'STATEDEPENDENDMATERIAL',
  FILTERS: 'FILTERS',
  PRODUCTLIST: 'PRODUCTLIST',
}
const colors = {
  TRACE: chalk.magenta,
  DEBUG: chalk.cyan,
  INFO: chalk.blue,
  WARN: chalk.yellow,
  ERROR: chalk.red,
}

const LogContextWrapper = (props) => {
  const [logLevel, setLoglevel] = useState('INFO')

  const log2console = (component, message, ...args) => {
    let loggingRequested = localStorage.getItem('log2console')

    if (!loggingRequested) {
      return
    }

    if (loggingRequested.includes('*') || loggingRequested.includes(component)) {
      console.log(component, message, ...args)
    }
  }

  useEffect(() => {}, [logLevel])

  prefix.reg(logger)

  prefix.apply(logger, {
    format(level, name, timestamp) {
      return `${chalk.gray(`[${timestamp}]`)} ${colors[level.toUpperCase()](level)}`
    },
  })

  prefix.apply(logger.getLogger('critical'), {
    format(level, name, timestamp) {
      return chalk.red.bold(`[${timestamp}] ${level} ${name}:`)
    },
  })

  return (
    <LogContextProvider value={{ log2console, COMPONENTS }}>{props.children}</LogContextProvider>
  )
}

export default LogContextWrapper
