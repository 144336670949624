import { CDatePicker } from '@coreui/react-pro'
import { useField, useFormikContext } from 'formik'
import cn from 'classnames'
import { FormInvalidFeedback } from './FormInvalidFeedback'

export const FormDatePicker = ({
  className,
  type,
  id,
  placeholder,
  name,
  disabled = false,
  setInitialvaluesChanged = () => {},
  inputReadOnly = false,
}) => {
  const { setFieldValue, values } = useFormikContext()
  const [field, meta] = useField({ type, id, placeholder, name })

  const isValid = !!(meta.touched && meta.error)
  const feedbackMsg = isValid ? meta.error : ''

  return (
    <div className={cn('my-3', className)}>
      <CDatePicker
        disabled={disabled}
        type={type}
        id={id}
        placeholder={placeholder}
        name={name}
        {...field}
        valid={!meta.touched && !meta.value ? null : meta.value || !meta.value}
        onDateChange={(date) => {
          setInitialvaluesChanged(true)
          !!date &&
            setFieldValue(
              id,
              new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())).toISOString(),
            )
        }}
        date={field.value}
        inputReadOnly
      />
      {isValid && <FormInvalidFeedback feedbackMsg={feedbackMsg} />}
    </div>
  )
}
