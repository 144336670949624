import httpsStrapi from '../../../config/httpsStrapi'

export const getCategoryMetaTags = async (lang) => {
  const pageSize = 100
  let page = 1
  let totalPages = 1
  let allData = []

  while (page <= totalPages) {
    const response = await httpsStrapi.get(
      `/category-lists?locale=${lang}&pagination[pageSize]=${pageSize}&pagination[page]=${page}`,
    )
    const data = response.data
    allData = allData.concat(data.data)
    totalPages = data.meta.pagination.pageCount
    page += 1
  }
  return allData
}
