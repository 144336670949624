import { getSheetByName } from '../../../../components/excel/upload'

export const workbookPriceValidator = (
  workbook,
  manufacturerId,
  buildingContractorId,
  projectId,
  t,
) => {
  const errorsInProducts = {
    invalidProducts: {},
  }
  const data = []
  const sheet = getSheetByName(workbook, 'price_agreement')

  if (!sheet) {
    errorsInProducts.invalidProducts['sheetNotFound'] = {
      product: 'Invalid Prices Excel File',
      error: t('Backoffice.PriceManagement.ValidationErrors.InvalidSheet'),
    }
    return errorsInProducts
  }

  const firstRow = sheet.getRow(3)
  if (!firstRow.cellCount) return
  const keys = firstRow.values
  sheet.eachRow((row, index) => {
    if (index <= 3) return
    const values = row.values
    const obj = {}
    for (let i = 1; i < keys.length; i++) {
      obj[keys[i]] = values[i]
    }
    obj.rowNumber = index
    data.push(obj)
  })
  for (const row of data) {
    let price = {}
    price.article_number = row['article_number'] && row['article_number'].toString()
    price.price_value_in_CHF =
      row['price_value_in_CHF'] !== null && row['price_value_in_CHF'] !== undefined
        ? parseFloat(row['price_value_in_CHF'])
        : null
    price.discount_value_in_percent =
      row['discount_value_in_percent'] != null && row['discount_value_in_percent'] !== undefined
        ? parseFloat(row['discount_value_in_percent'])
        : null
    price.currency = row['currency']
    price.type = projectId ? 'context' : 'yearly_agreement'
    price.bc_partner_id = buildingContractorId
    price.building_project_id = projectId || null
    price.syndicate_id = null
    price.scale_value_1 =
      row['scale_value_1'] !== null && row['scale_value_1'] !== undefined
        ? parseFloat(row['scale_value_1'])
        : null
    price.threshold_1 =
      row['threshold_1'] !== null && row['threshold_1'] !== undefined
        ? parseFloat(row['threshold_1'])
        : null
    price.scale_value_2 =
      row['scale_value_2'] !== null && row['scale_value_2'] !== undefined
        ? parseFloat(row['scale_value_2'])
        : null
    price.threshold_2 =
      row['threshold_2'] !== null && row['threshold_2'] !== undefined
        ? parseFloat(row['threshold_2'])
        : null
    price.scale_value_3 =
      row['scale_value_3'] !== null && row['scale_value_3'] !== undefined
        ? parseFloat(row['scale_value_3'])
        : null
    price.threshold_3 =
      row['threshold_3'] !== null && row['threshold_3'] !== undefined
        ? parseFloat(row['threshold_3'])
        : null
    price.scale_value_4 =
      row['scale_value_4'] !== null && row['scale_value_4'] !== undefined
        ? parseFloat(row['scale_value_4'])
        : null
    price.threshold_4 =
      row['threshold_4'] !== null && row['threshold_4'] !== undefined
        ? parseFloat(row['threshold_4'])
        : null
    price.scale_value_5 =
      row['scale_value_5'] !== null && row['scale_value_5'] !== undefined
        ? parseFloat(row['scale_value_5'])
        : null
    price.threshold_5 =
      row['threshold_5'] !== null && row['threshold_5'] !== undefined
        ? parseFloat(row['threshold_5'])
        : null
    let validationResults = logicValidation(price, t)
    if (validationResults) {
      errorsInProducts.invalidProducts[
        typeof price.article_number === 'string'
          ? price.article_number
          : 'Unknown Article Number. Row Number: ' + row.rowNumber
      ] = {
        product:
          typeof price.article_number === 'string'
            ? price.article_number
            : t('Backoffice.PriceManagement.ValidationErrors.UnknownArticle') +
              ': ' +
              row.rowNumber,
        error: validationResults,
      }
    }
  }
  return errorsInProducts
}

const logicValidation = (price, t) => {
  let errorMessage = ''
  if (typeof price.article_number !== 'string') {
    errorMessage =
      errorMessage +
      t('Backoffice.PriceManagement.ValidationErrors.InvalidParameter.InvalidValue').replace(
        '{{field}}',
        'articleNumber',
      ) +
      ' '
  }
  if (price.currency !== 'CHF') {
    errorMessage =
      errorMessage +
      t('Backoffice.PriceManagement.ValidationErrors.InvalidParameter.InvalidCurrency').replace(
        '{{field}}',
        'currency',
      ) +
      ' '
  }
  if (price.price_value_in_CHF !== null && isNaN(price.price_value_in_CHF)) {
    errorMessage =
      errorMessage +
      t('Backoffice.PriceManagement.ValidationErrors.InvalidParameter.InvalidNumber').replace(
        '{{field}}',
        'price_value_in_CHF',
      ) +
      ' '
  }
  if (price.discount_value_in_percent !== null && isNaN(price.discount_value_in_percent)) {
    errorMessage =
      errorMessage +
      t('Backoffice.PriceManagement.ValidationErrors.InvalidParameter.InvalidNumber').replace(
        '{{field}}',
        'discount_value_in_percent',
      ) +
      ' '
  }
  if (price.scale_value_1 !== null && isNaN(price.scale_value_1)) {
    errorMessage =
      errorMessage +
      t('Backoffice.PriceManagement.ValidationErrors.InvalidParameter.InvalidNumber').replace(
        '{{field}}',
        'scale_value_1',
      ) +
      ' '
  }
  if (price.threshold_1 !== null && isNaN(price.threshold_1)) {
    errorMessage =
      errorMessage +
      t('Backoffice.PriceManagement.ValidationErrors.InvalidParameter.InvalidNumber').replace(
        '{{field}}',
        'threshold_1',
      ) +
      ' '
  }
  if (price.scale_value_2 !== null && isNaN(price.scale_value_2)) {
    errorMessage =
      errorMessage +
      t('Backoffice.PriceManagement.ValidationErrors.InvalidParameter.InvalidNumber').replace(
        '{{field}}',
        'scale_value_2',
      ) +
      ' '
  }
  if (price.threshold_2 !== null && isNaN(price.threshold_2)) {
    errorMessage =
      errorMessage +
      t('Backoffice.PriceManagement.ValidationErrors.InvalidParameter.InvalidNumber').replace(
        '{{field}}',
        'threshold_2',
      ) +
      ' '
  }
  if (price.scale_value_3 !== null && isNaN(price.scale_value_3)) {
    errorMessage =
      errorMessage +
      t('Backoffice.PriceManagement.ValidationErrors.InvalidParameter.InvalidNumber').replace(
        '{{field}}',
        'scale_value_3',
      ) +
      ' '
  }
  if (price.threshold_3 !== null && isNaN(price.threshold_3)) {
    errorMessage =
      errorMessage +
      t('Backoffice.PriceManagement.ValidationErrors.InvalidParameter.InvalidNumber').replace(
        '{{field}}',
        'threshold_3',
      ) +
      ' '
  }
  if (price.scale_value_4 !== null && isNaN(price.scale_value_4)) {
    errorMessage =
      errorMessage +
      t('Backoffice.PriceManagement.ValidationErrors.InvalidParameter.InvalidNumber').replace(
        '{{field}}',
        'scale_value_4',
      ) +
      ' '
  }
  if (price.threshold_4 !== null && isNaN(price.threshold_4)) {
    errorMessage =
      errorMessage +
      t('Backoffice.PriceManagement.ValidationErrors.InvalidParameter.InvalidNumber').replace(
        '%field%',
        'threshold_4',
      ) +
      ' '
  }
  if (price.scale_value_5 !== null && isNaN(price.scale_value_5)) {
    errorMessage =
      errorMessage +
      t('Backoffice.PriceManagement.ValidationErrors.InvalidParameter.InvalidNumber').replace(
        '{{field}}',
        'scale_value_5',
      ) +
      ' '
  }
  if (price.threshold_5 !== null && isNaN(price.threshold_5)) {
    errorMessage =
      errorMessage +
      t('Backoffice.PriceManagement.ValidationErrors.InvalidParameter.InvalidNumber').replace(
        '{{field}}',
        'threshold_5',
      ) +
      ' '
  }
  if (!price.price_value_in_CHF && !price.discount_value_in_percent) {
    errorMessage =
      errorMessage +
      t('Backoffice.PriceManagement.ValidationErrors.InvalidParameter.InvalidPrice') +
      ' '
  }
  if (
    (price.scale_value_1 === null && price.threshold_1 !== null) ||
    (price.scale_value_1 !== null && price.threshold_1 === null) ||
    (price.scale_value_2 === null && price.threshold_2 !== null) ||
    (price.scale_value_2 !== null && price.threshold_2 === null) ||
    (price.scale_value_3 === null && price.threshold_3 !== null) ||
    (price.scale_value_3 !== null && price.threshold_3 === null) ||
    (price.scale_value_4 === null && price.threshold_4 !== null) ||
    (price.scale_value_4 !== null && price.threshold_4 === null) ||
    (price.scale_value_5 === null && price.threshold_5 !== null) ||
    (price.scale_value_5 !== null && price.threshold_5 === null)
  ) {
    errorMessage =
      errorMessage +
      t('Backoffice.PriceManagement.ValidationErrors.InvalidParameter.InvalidScalePrice') +
      ' '
  }
  if (errorMessage !== '') {
    return errorMessage.slice(0, -2)
  }
}
