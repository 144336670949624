import React, { useState, useEffect } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import * as moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CDatePicker,
  CFormInput,
  CModalFooter,
  CButton,
  CFormCheck,
} from '@coreui/react-pro'
import {
  addHoliday,
  deleteHoliday,
  updateHoliday,
  getHolidays,
} from 'src/services/requests/calendar'
import { Spinner } from 'src/components/Spinner'
import { useTranslation } from 'react-i18next'
import { cilPlus } from '@coreui/icons-pro'
import CIcon from '@coreui/icons-react'
import { transformDate } from './lib/backoffice-lib'
moment.locale('en', {
  week: {
    dow: 1, // 1 for Monday, 0 for Sunday
    doy: 1,
  },
})
const localizer = momentLocalizer(moment)

const BackOfficeCalendar = () => {
  const ACTIONS = {
    ADD: 'add',
    EDIT: 'edit',
    NEW: 'new',
  }

  const transformEvents = (_events) => {
    return _events.map((event) => ({
      id: event.id,
      title: event.holiday_name,
      start: moment(event.holiday_date).startOf('day').toDate(),
      end: moment(event.holiday_date).endOf('day').toDate(),
      allDay: true,
      isRecurring: event.is_recurring ? true : false,
    }))
  }

  const [events, setEvents] = useState([])
  const [modal, setModal] = useState(false)
  const [eventTitle, setEventTitle] = useState('')
  const [eventId, setEventId] = useState(null)
  const [selectedDate, setSelectedDate] = useState(transformDate(new Date()))
  const [mode, setMode] = useState(ACTIONS.ADD)
  const [rerender, setRerender] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isRecurring, setIsRecurring] = useState(false)
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear())
  const [currentDate, setCurrentDate] = useState(new Date())
  const { t } = useTranslation()

  function getStartAndEndDates(year) {
    const startDate = new Date(Date.UTC(year, 0, 1, 0, 0, 0, 0))
    const endDate = new Date(Date.UTC(year, 11, 31, 0, 0, 0, 0))

    return {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    }
  }

  useEffect(() => {
    setIsLoading(true)
    const { startDate, endDate } = getStartAndEndDates(currentYear)
    getHolidays(startDate, endDate)
      .then((res) => {
        setEvents(transformEvents(res.data))
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [rerender, currentYear])

  const onClose = () => {
    setModal(false)
    setEventTitle('')
    setSelectedDate(null)
    setEventId(null)
    setMode(ACTIONS.ADD)
    setIsRecurring(false)
  }

  const isSameDay = (date) => {
    return events.some((event) => moment(event.start).isSame(date, 'day'))
  }

  const handleSelectSlot = (data) => {
    const date = moment(data.start).startOf('day').toDate()
    if (isSameDay(date)) {
      return
    }
    setSelectedDate(transformDate(data.start))
    setModal(true)
  }

  const handleSelectEvent = (event) => {
    setEventId(event.id)
    setIsRecurring(event.isRecurring)
    setEventTitle(event.title)
    setSelectedDate(new Date(event.end))
    setMode(ACTIONS.EDIT)
    setModal(true)
  }

  const dayPropGetter = (date) => {
    if (isSameDay(date)) {
      return {
        className: 'calendar-background',
      }
    }
    return {}
  }

  const eventPropGetter = (event) => {
    return {
      className: 'calendar-event',
    }
  }

  const messages = {
    today: t('BackOfficeCalendar.Today'),
    previous: t('BackOfficeCalendar.Previous'),
    next: t('BackOfficeCalendar.Next'),
  }

  const handleNavigate = (newDate) => {
    setCurrentDate(newDate)
    const newYear = newDate.getFullYear()
    if (newYear !== currentYear) {
      setCurrentYear(newYear)
    }
  }

  return (
    <>
      <Spinner
        message={t('Backoffice.BackOfficeCalendar.Label.FetchCalendar')}
        pageloading={isLoading}
      >
        <div className="px-2">
          <div className="d-flex align-items-center justify-content-end my-2">
            <CButton
              onClick={() => {
                setMode(ACTIONS.NEW)
                setModal(true)
              }}
            >
              <CIcon icon={cilPlus} /> {'  '} {t('BackOfficeCalendar.AddHoliday')}
            </CButton>
          </div>
          <Calendar
            localizer={localizer}
            events={events}
            defaultView="month"
            startAccessor="start"
            endAccessor="end"
            style={{ height: '80vh' }}
            selectable
            onSelectSlot={handleSelectSlot}
            onSelectEvent={handleSelectEvent}
            showMultiDayTimes={true}
            views={['month']}
            dayPropGetter={dayPropGetter}
            eventPropGetter={eventPropGetter}
            messages={messages}
            onNavigate={handleNavigate}
            date={currentDate}
          />

          {modal && (
            <CalendarModal
              modal={modal}
              onClose={onClose}
              eventTitle={eventTitle}
              setEventTitle={setEventTitle}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              eventId={eventId}
              mode={mode}
              setRerender={setRerender}
              ACTIONS={ACTIONS}
              t={t}
              transformDate={transformDate}
              isSameDay={isSameDay}
              isRecurring={isRecurring}
              setIsRecurring={setIsRecurring}
            />
          )}
        </div>
      </Spinner>
    </>
  )
}

const CalendarModal = ({
  modal,
  onClose,
  eventTitle,
  setEventTitle,
  selectedDate,
  setSelectedDate,
  eventId,
  mode,
  setRerender,
  ACTIONS,
  transformDate,
  t,
  isSameDay,
  isRecurring,
  setIsRecurring,
}) => {
  const createBody = (title, date) => {
    return {
      holiday_name: title,
      holiday_date: moment.utc(date).toDate(),
      is_recurring: isRecurring,
    }
  }

  const handleEvent = () => {
    switch (mode) {
      case ACTIONS.ADD:
      case ACTIONS.NEW:
        addHoliday(createBody(eventTitle, selectedDate))
          .then((res) => {
            setRerender((prev) => !prev)
            onClose()
          })
          .catch((err) => {
            console.log(err)
          })
        break
      case ACTIONS.EDIT:
        updateHoliday(eventId, createBody(eventTitle, selectedDate))
          .then((res) => {
            setRerender((prev) => !prev)
            onClose()
          })
          .catch((err) => {
            console.log(err)
          })
        break
      default:
        break
    }
  }

  const handleClose = () => {
    if (mode === ACTIONS.EDIT) {
      deleteHoliday(eventId).then(() => {
        setRerender((prev) => !prev)
        onClose()
      })
    } else onClose()
  }

  return (
    <CModal
      className="show d-block spartan-font"
      backdrop="static"
      size="lg"
      visible={modal}
      onClose={onClose}
      alignment="center"
    >
      <CModalHeader>
        <CModalTitle id="calenderModalTitle">{t('BackOfficeCalendar.HolidayTitle')}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CFormInput
          name="title"
          placeholder={eventTitle ? eventTitle : t('BackOfficeCalendar.AddHoliday')}
          value={eventTitle}
          onChange={(e) => setEventTitle(e.target.value)}
          className="mb-3"
        />

        <CDatePicker
          name="date"
          date={selectedDate ? new Date(selectedDate) : new Date()}
          className="calendar-outline"
          locale="de-DE"
          disabled={mode === ACTIONS.EDIT || mode === ACTIONS.ADD}
          onDateChange={(e) => {
            setSelectedDate(transformDate(new Date(new Date(e).setHours(0, 0, 0, 0))))
          }}
        />

        <CFormCheck
          type="checkbox"
          id="isRecurring"
          label={t('BackOfficeCalendar.Recurring')}
          className="mt-3"
          checked={isRecurring}
          onChange={() => {
            setIsRecurring((prev) => !prev)
          }}
        />
      </CModalBody>
      <CModalFooter>
        <CButton variant="outline" onClick={handleClose}>
          {mode === ACTIONS.EDIT
            ? t('Backoffice.drawer.delete.confirmButton')
            : t('projectmaterialview.button.label.cancel')}
        </CButton>
        <CButton
          className="btn btn-primary"
          onClick={handleEvent}
          disabled={!eventTitle || (isSameDay(selectedDate) && mode !== ACTIONS.EDIT)}
        >
          {mode === ACTIONS.EDIT ? t('BackOfficeCalendar.Update') : t('BackOfficeCalendar.Save')}
        </CButton>
      </CModalFooter>
    </CModal>
  )
}

export default BackOfficeCalendar
