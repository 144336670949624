import { FieldArray, useFormikContext } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FormAddAnotherButton } from './FormAddAnotherButton'
import { FormDeleteFieldButton } from './FormDeleteFieldButton'
import { FormSelect } from './FormSelect'

export default function FormSelectMultipleBranches({
  readOnly,
  allBranches,
  setInitialvaluesChanged,
}) {
  let branch_count = 0
  const { t } = useTranslation()
  const {
    values: { partners, branches },
    setFieldValue,
  } = useFormikContext()

  const getBranchOptions = () => {
    let branches = []
    branches = allBranches.map((branch) => {
      return { label: branch?.name, value: branch?.id }
    })
    return branches
  }

  return (
    <>
      <FieldArray
        name={'branches'}
        render={({ push, remove }) => (
          <>
            {!!branches.length &&
              branches.map((branch, index) => {
                const hasBranchOptions = allBranches.map((br) => br.id).includes(branch)
                branch_count =
                  branch === ''
                    ? branch_count + 1
                    : hasBranchOptions
                    ? branch_count + 1
                    : branch_count
                return (
                  <>
                    {((!readOnly && branch === '') || hasBranchOptions) && (
                      <div key={index} className="w-2 h-2 position-relative">
                        <p className="spartan-font">
                          {partners.length === 1
                            ? t('Backoffice.branches.table.columns.branch')
                            : `${t('Backoffice.branches.table.columns.branch')} (${branch_count})`}
                        </p>
                        <FormSelect
                          className="my-2 form-control-backoffice"
                          placeholder={t('Backoffice.branches.table.columns.branch')}
                          name={`branches.${index}`}
                          id={`branches.${index}`}
                          options={getBranchOptions()}
                          disabled={readOnly}
                        />
                        {!readOnly && (
                          <FormDeleteFieldButton
                            index={index}
                            handleClick={() => {
                              remove(index)
                              const updatedBranches = [...branches]
                              updatedBranches.splice(index, 1)
                              setFieldValue('branches', updatedBranches)
                              setInitialvaluesChanged(true)
                            }}
                          />
                        )}
                      </div>
                    )}
                  </>
                )
              })}
            {!readOnly && branches.at(-1) !== '' && (
              <FormAddAnotherButton push={push} values={''} />
            )}
          </>
        )}
      />
    </>
  )
}
