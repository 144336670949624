import React from 'react'
import { CSpinner } from '@coreui/react-pro'

export const Spinner = ({ message = '', ...rest }) => {
  const { pageloading } = rest
  const { children } = rest

  return (
    <>
      {pageloading === false ? (
        children
      ) : (
        <div className="spinner--wrapper">
          <div className="d-flex justify-content-center">
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: '60px' }}
            >
              <CSpinner color="warning" variant="grow" />
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <div style={{ height: '40px' }}>{message}</div>
          </div>
        </div>
      )}
    </>
  )
}
