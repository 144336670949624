import * as Yup from 'yup'

export const updateBuildingContractorValidation = (t) =>
  Yup.object({
    type: Yup.string().required(t('Yup.validation.error.required')),
    name: Yup.string().required(t('Yup.validation.error.required')),
    country: Yup.string()
      .required(t('Yup.validation.error.required'))
      .min(2, t('Yup.validation.error.country.length'))
      .max(2, t('Yup.validation.error.country.length')),
    vatNum: Yup.string().required(t('Yup.validation.error.required')),
    HGCPartnerId: Yup.string().required(t('Yup.validation.error.required')),
    domain: Yup.string().min(3, t('Yup.validation.error.domain.minLength')),
    purpose: Yup.string(),
    billingEmail: Yup.string()
      .email(t('Yup.validation.error.invalid.email'))
      .required(t('Yup.validation.error.required')),
    street: Yup.string().required(t('Yup.validation.error.required')),
    houseNumber: Yup.string().nullable(),
    city: Yup.string().required(t('Yup.validation.error.required')),
    postalCode: Yup.string().required(t('Yup.validation.error.required')),
    addressCountry: Yup.string()
      .min(2, t('Yup.validation.error.country.length'))
      .max(2, t('Yup.validation.error.country.length'))
      .required(t('Yup.validation.error.required')),
    lat: Yup.number(),
    lon: Yup.number(),
    contactEmail: Yup.string()
      .email(t('Yup.validation.error.invalid.email'))
      .required(t('Yup.validation.error.required')),
    videoCall: Yup.boolean().required(t('Yup.validation.error.required')),
    paymentTerms: Yup.number()
      .min(0, t('Yup.Validation.Error.PaymentTerms.Min'))
      .required(t('Yup.validation.error.required')),
  })
