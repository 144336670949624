import httpsV2 from '../../config/httpsV2'

export const getHolidays = (startDate, endDate) => {
  if (startDate && endDate) {
    return httpsV2.get('/middleware/holiday?startDate=' + startDate + '&endDate=' + endDate)
  }
  return httpsV2.get('/middleware/holiday')
}

export const addHoliday = (body) => {
  return httpsV2.post('/middleware/holiday', body)
}

export const updateHoliday = (id, body) => {
  return httpsV2.patch('/middleware/holiday/' + id, body)
}

export const deleteHoliday = (id) => {
  return httpsV2.delete('/middleware/holiday/' + id)
}
