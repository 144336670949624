import React, { useEffect, useState, useContext } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { AuthContext } from '../contexts/authContext'

import Notifications from 'src/components/shared/notifications/NotificationsToaster'

export const IdleTimerContext = React.createContext({})
export const IdleTimerContextProvider = IdleTimerContext.Provider
export const delayTimeoutInMs = process.env.REACT_APP_IDLE_LOGOUT_TIMEOUT_SECONDS * 1000

const IdleTimerContextWrapper = (props) => {
  const auth = useContext(AuthContext)
  const [isIdle, setIsIdle] = useState(false)
  const [showWarning, setShowWarning] = useState(false)

  const onIdle = () => {
    setIsIdle(true)
  }

  const onActive = () => {
    setIsIdle(false)
    setShowWarning(false)
  }

  useEffect(() => {
    if (isIdle && auth.authStatus === 'SignedIn') {
      auth.signOut()
      setShowWarning(true)
    }
  }, [isIdle])

  useIdleTimer({
    onIdle,
    onActive,
    timeout: delayTimeoutInMs,
    promptTimeout: 0,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: 'idle-timer',
    syncTimers: 0,
    leaderElection: false,
  })

  return (
    <>
      <IdleTimerContextProvider value={isIdle}>{props.children}</IdleTimerContextProvider>{' '}
      {showWarning ? (
        <Notifications
          type="warn"
          message={`You 've been signed out due to inactivity. Please login again.`}
        />
      ) : null}
    </>
  )
}

export default IdleTimerContextWrapper
