import { CButton, CImage } from '@coreui/react-pro'
import softDelete from '../../../assets/images/cubotoo/soft-delete.svg'
import cn from 'classnames'

export const FormDeleteFieldButton = ({ handleClick, index, className }) => {
  return (
    <div
      style={{ transform: 'translateX(100%)', marginTop: '40px' }}
      className={cn('position-absolute top-0 end-0', className)}
    >
      <CButton variant="ghost" className="p-0 mt-1 ml-2" onClick={() => handleClick(index)}>
        <CImage src={softDelete} />
      </CButton>
    </div>
  )
}
