import {
  CCloseButton,
  COffcanvas,
  COffcanvasBody,
  COffcanvasHeader,
  COffcanvasTitle,
} from '@coreui/react-pro'
import React, { useEffect, useState } from 'react'
import { DrawerLoadingOverlay } from './DrawerLoadingOverlay'

export const EditDrawer = ({
  offcanvasVisible,
  rowsSelected,
  setOffcanvasVisible,
  editMode,
  children,
  existingStepNumber,
  heading,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [unsavedChangesOffcanvasVisible, setUnsavedChangesOffcanvasVisible] = useState(false)
  const [initialValuesChanged, setInitialvaluesChanged] = useState(false)

  useEffect(() => {
    if (editMode) rowsSelected.length === 0 && setOffcanvasVisible(false)
  })

  const rowName = editMode
    ? rowsSelected[0]?.name || rowsSelected[0]?.first_name + ' ' + rowsSelected[0]?.last_name
    : 'NEW'

  const cancelEditing = () => {
    if (!editMode) {
      setOffcanvasVisible(false)
    } else {
      if (initialValuesChanged) return setUnsavedChangesOffcanvasVisible(true)
      setOffcanvasVisible(false)
    }
  }

  return (
    <COffcanvas
      placement="end"
      visible={offcanvasVisible}
      backdrop={false}
      onHide={() => {
        setOffcanvasVisible(false)
        setInitialvaluesChanged(false)
      }}
      className="edit-drawer"
    >
      {offcanvasVisible && (
        <>
          <COffcanvasHeader>
            <COffcanvasTitle component="div">
              <p className="edit-drawer__header-type m-0 text-capitalize">{heading}:</p>
              <h4>{rowName}</h4>
            </COffcanvasTitle>
            <CCloseButton className="text-reset" onClick={cancelEditing} />
          </COffcanvasHeader>
          <COffcanvasBody className="drawer__offcanvas-sticky">
            {React.cloneElement(children, {
              setIsLoading,
              unsavedChangesOffcanvasVisible,
              setUnsavedChangesOffcanvasVisible,
              initialValuesChanged,
              setInitialvaluesChanged,
              existingStepNumber,
            })}
          </COffcanvasBody>
          <DrawerLoadingOverlay isLoading={isLoading} />
        </>
      )}
    </COffcanvas>
  )
}
