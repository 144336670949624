import React, { useState, useEffect, useContext } from 'react'
import BTable from 'src/components/table/Table'
import { useTranslation } from 'react-i18next'
import { getMfErpIntegrations } from 'src/services/requests/erpIntegration'
import { EditDrawer } from 'src/components/table/EditDrawer'
import { OverviewDrawer } from 'src/components/table/OverviewDrawer'
import FormErpIntegration from './forms/FormErpIntegration'
import EditErpIntegrationForm from './EditErpIntegrationForm'
import { getAllManufacturers } from 'src/services/requests/partners'
import { Spinner } from 'src/components/Spinner'
import { mfErpIntegrationColumnDefs } from './lib/backoffice-lib'
import { AuthContext, ROLE } from '../../../contexts/authContext'
import {
  updateMfErpIntegration,
  deleteMfErpIntegration,
  deactivateMfErpIntegration,
} from 'src/services/requests/erpIntegration'
import { DeactivateDrawer } from 'src/components/table/DeactivateDrawer'

export default function BackOfficeErpIntegration() {
  const [data, setData] = useState([])
  const [rowsSelected, setRowsSelected] = useState([])
  const [editOffcanvasVisible, setEditOffcanvasVisible] = useState(false)
  const [overviewOffcanvasVisible, setOverviewOffcanvasVisible] = useState(false)
  const [existingStepNumber, setExistingStepNumber] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [offcanvasVisible, setOffcanvasVisible] = useState(false)
  const [drawerMode, setDrawerMode] = useState('deactivate')
  const [mfPartners, setMfPartners] = useState([])
  const { t } = useTranslation()

  const authContext = useContext(AuthContext)

  // re-factor data object
  const reFactorData = (response, partners) => {
    const updatedData = response?.map((row) => {
      return {
        name: partners?.find((partner) => partner.id === row.partner_id)?.name || '',
        ...row,
      }
    })
    return updatedData
  }

  // fetch all mf erp integrations
  const fetchData = async () => {
    setIsLoading(true)

    try {
      const partnersResponse = await getAllManufacturers()
      const partners = partnersResponse.data
      setMfPartners(partners)

      const response = await getMfErpIntegrations()
      const updatedData = await reFactorData(response.data, partners)
      setData(updatedData)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  // get pre-saved columns from local-storage
  const presavedColumns =
    localStorage.getItem(`${authContext.role}-mfErpIntegration`) &&
    JSON.parse(localStorage.getItem(`${authContext.role}-mfErpIntegration`))

  // delete selected rows
  const deleteRow = async (rowIds) => {
    const deleteRowsRequests = await rowIds.map(
      async (rowId) => await deleteMfErpIntegration(rowId),
    )

    Promise.all(deleteRowsRequests).then(() => {
      fetchData()
    })
  }

  // deactivate selected rows
  const deactivateRows = async (selectedRows) => {
    const deactivateRowsRequests = selectedRows.map(async (rowId) => {
      await deactivateMfErpIntegration(rowId)
    })

    await Promise.all(deactivateRowsRequests)
    fetchData()
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {}, [data])

  return (
    <>
      <Spinner
        message={t('Backoffice.BackOfficeMfErpIntegration.Label.FetchErpIntegrations')}
        pageloading={isLoading}
      >
        <BTable
          buttonCaption={t('Backoffice.AddMfErpIntegrationButton.Label')}
          identifier="mfErpIntegration"
          data={data}
          buttonCallback={() => setEditOffcanvasVisible(true)}
          columnDefs={presavedColumns ? presavedColumns : mfErpIntegrationColumnDefs(t)}
          rowsSelected={rowsSelected}
          setRowsSelected={setRowsSelected}
          setEditOffcanvasVisible={setEditOffcanvasVisible}
          setOffcanvasVisible={setOffcanvasVisible}
          setOverviewOffcanvasVisible={setOverviewOffcanvasVisible}
          fetchData={fetchData}
          EditForm={EditErpIntegrationForm}
          setExistingStepNumber={setExistingStepNumber}
          deleteRows={deleteRow}
          deactivateRows={deactivateRows}
          setDrawerMode={setDrawerMode}
          t={t}
        />
        <EditDrawer
          rowsSelected={rowsSelected}
          offcanvasVisible={editOffcanvasVisible}
          setOffcanvasVisible={setEditOffcanvasVisible}
          fetchData={fetchData}
          editMode={rowsSelected.length > 0}
          heading={t('Backoffice.layout.subpage.mfErpIntegration')}
          t={t}
        >
          <FormErpIntegration
            rowsSelected={rowsSelected ?? undefined}
            editMode={rowsSelected.length > 0}
            rowData={rowsSelected[0] ?? undefined}
            fetchData={fetchData}
            setOffcanvasVisible={setEditOffcanvasVisible}
            setEditOffcanvasVisible={setEditOffcanvasVisible}
            setOverviewOffcanvasVisible={setOverviewOffcanvasVisible}
            readOnly={false}
            setExistingStepNumber={setExistingStepNumber}
            deleteRows={deleteRow}
            mfPartners={mfPartners}
            t={t}
          />
        </EditDrawer>
        <DeactivateDrawer
          offcanvasVisible={offcanvasVisible}
          setOffcanvasVisible={setOffcanvasVisible}
          deleteRows={deleteRow}
          deactivateRows={deactivateRows}
          rowsSelected={rowsSelected}
          identifier={'mfErpIntegration'}
          drawerMode={drawerMode}
        />
        <OverviewDrawer
          offcanvasVisible={overviewOffcanvasVisible}
          setOffcanvasVisible={setOverviewOffcanvasVisible}
          rowsSelected={rowsSelected}
          deleteRows={deleteRow}
          setEditOffcanvasVisible={setEditOffcanvasVisible}
          identifier={'mfErpIntegration'}
          heading={t('Backoffice.layout.subpage.mfErpIntegration')}
        >
          <FormErpIntegration
            rowsSelected={rowsSelected ?? undefined}
            editMode={rowsSelected.length > 0}
            rowData={rowsSelected[0] ?? undefined}
            fetchData={fetchData}
            setOffcanvasVisible={setOverviewOffcanvasVisible}
            setEditOffcanvasVisible={setEditOffcanvasVisible}
            setOverviewOffcanvasVisible={setOverviewOffcanvasVisible}
            readOnly={true}
            setExistingStepNumber={setExistingStepNumber}
            mfPartners={mfPartners}
            t={t}
          />
        </OverviewDrawer>
      </Spinner>
    </>
  )
}
