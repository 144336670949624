import { FieldArray, useFormikContext } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FormAddAnotherButton } from './FormAddAnotherButton'
import { FormDeleteFieldButton } from './FormDeleteFieldButton'
import { FormSelect } from './FormSelect'
import { SearchableSelect } from './SearchableSelect'

export default function FormSelectMultipleBranches({
  readOnly,
  allBranches,
  contactIndex,
  partnerSelectionDisabled,
}) {
  const { t } = useTranslation()
  const {
    values: { contact_branches },
  } = useFormikContext()
  let branch = contact_branches[contactIndex] ?? ''
  if (!branch && partnerSelectionDisabled) {
    branch = contact_branches[0] ?? ''
    branch ? contact_branches.push(branch) : null
  }
  const hasBranchOptions = allBranches.map((br) => br.id).includes(branch)

  const getBranchOptions = () => {
    let branches = []
    branches = allBranches.map((branch) => {
      return { label: branch?.name, value: branch?.id }
    })
    return branches
  }

  return (
    <>
      {((!readOnly && branch === '') || hasBranchOptions) && (
        <div className="w-2 h-2 position-relative">
          <p className="spartan-font">{t('Backoffice.branches.table.columns.branch')}</p>
          <SearchableSelect
            className="my-2 form-control-backoffice"
            placeholder={t('Backoffice.branches.table.columns.branch')}
            name={`contact_branches.${contactIndex}`}
            id={`contact_branches.${contactIndex}`}
            options={getBranchOptions()}
            disabled={readOnly}
          />
        </div>
      )}
    </>
  )
}
