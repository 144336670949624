import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getCategories } from '../services/requests/strapi/categories'

export const CategoriesContext = React.createContext({})
export const CategoriesContextProvider = CategoriesContext.Provider

const CategoriesContextWrapper = (props) => {
  const [categories, setCategories] = useState([])
  const { i18n } = useTranslation()
  const lang = i18n.language

  const fetchData = () => {
    getCategories(lang).then((response) => {
      setCategories(response.data)
    })
  }

  useEffect(() => {
    fetchData()
  }, [lang])

  return (
    <CategoriesContextProvider
      value={{
        categories,
      }}
    >
      {props.children}
    </CategoriesContextProvider>
  )
}

export default CategoriesContextWrapper
