import React from 'react'
import 'src/views/pages/products/components/styles.scss'
import { CRow, CCol } from '@coreui/react-pro'

import PartnerWidget from './PartnerWidget'

const Row = ({ data }) => {
  return (
    <CRow>
      {data.map((item, index) => {
        return (
          <CCol key={index} className="d-xl-flex">
            {item}
          </CCol>
        )
      })}
    </CRow>
  )
}

const stepSize = (num, index, length) => {
  let remaining = length - index
  return remaining >= num ? num : remaining
}

export default function Grid({ data, columns = 3, branches, buildingContractors }) {
  let result = []
  for (let i = 0; i < data.length; i += columns) {
    //Get columns number of items from array
    let tmpData = data.slice(i, i + stepSize(columns, i, data.length))
    //Map each item to a Partner Widget
    tmpData = tmpData.map((item, index) => {
      return (
        <PartnerWidget
          key={index}
          partner={item}
          branches={branches}
          buildingContractors={buildingContractors}
        />
      )
    })
    //In case the tmpData array holds less than columns elements, fill it with divs
    while (tmpData.length < columns) {
      tmpData.push(<div />)
    }
    //Push a row, wich contains columns number of CCol to the result array
    result.push(<Row key={i} data={tmpData}></Row>)
  }

  //Return the result array containing Row elements
  return result
}
