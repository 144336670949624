import httpsV2 from '../../config/httpsV2'

export const getAllBranches = (data) => {
  return httpsV2.get('/middleware/partners?type=branch')
}

export const getBranchesByParentId = (id) => {
  return httpsV2.get('/middleware/partners/branches?parentId=' + id)
}

export const createBranch = (body) => {
  return httpsV2.post('/middleware/partners/branches', body)
}

export const updateBranch = (id, body) => {
  return httpsV2.patch('/middleware/partners/branches/' + id, body)
}

export const deleteBranch = (id) => {
  return httpsV2.delete('/middleware/partners/' + id)
}
