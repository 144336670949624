import { apiURL, apiToken } from 'src/i18n-backend'
export const getTableTranslation = async (lang = 'de') => {
  return (
    await fetch(`${apiURL}/translations-grid?locale=${lang}`, {
      headers: { Authorization: `Bearer ${apiToken}` },
    })
  )
    .json()
    .then(({ data }) => data.attributes.translations)
}
