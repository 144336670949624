import * as Yup from 'yup'
import { FormTextInput } from 'src/components/forms/FormTextInput'
import { useTranslation } from 'react-i18next'
import { Formik, Form } from 'formik'
import FormSelectMultipleAddresses from 'src/components/forms/selects/FormSelectMultipleAddresses'
import FormSelectMultipleContacts from 'src/components/forms/selects/FormSelectMultipleContacts'
import FormSelectMultiplePartners from 'src/components/forms/selects/FormSelectMultiplePartners'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from 'src/contexts/authContext'
import { getAllBuildingContractors } from 'src/services/requests/partners'
import { updateProject } from 'src/services/requests/projects'
import { EditDrawerHeading } from 'src/components/table/EditDrawerHeading'
import { EditDrawerFooter } from 'src/components/table/EditDrawerFooter'
import { CFormFeedback } from '@coreui/react-pro'

export const EditProjectForm = ({
  rowData,
  fetchData,
  setEditOffcanvasVisible,
  setDeactivateOffcanvasVisible,
  setIsLoading,
  unsavedChangesOffcanvasVisible,
  setUnsavedChangesOffcanvasVisible,
  initialValuesChanged,
  setInitialvaluesChanged,
  setOverviewOffcanvasVisible,
}) => {
  const [partnerOptions, setPartnerOptions] = useState([])
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const authContext = useContext(AuthContext)
  const { t } = useTranslation()

  const handleSubmit = async (values) => {
    setIsLoading(true)
    const removeEmptyStrings = (partners) => partners.filter((partner) => partner !== '')
    const partners = removeEmptyStrings(values.partners)
    const body = {
      name: values.projectName,
      addresses: values.addresses.map((address) => {
        return {
          street: address.street,
          postal_code: address.postalCode,
          house_nr: address.houseNumber,
          city: address.city,
          country: address.country,
          lon: Number(address.lon) || undefined,
          lat: Number(address.lat) || undefined,
        }
      }),
      contacts: values.contacts.map((contact) => {
        return {
          firstname: contact.firstName,
          lastname: contact.lastName,
          email: contact.email,
          phone: contact.phoneNumber,
          description: contact.description || undefined,
        }
      }),

      partner_ids: [...partners] || undefined,
    }
    try {
      await updateProject(rowData.id, JSON.stringify(body))
      await fetchData()
      setEditOffcanvasVisible(false)
      setOverviewOffcanvasVisible(true)
    } catch (error) {
      setErrorMsg(error.message.data.error)
      setError(true)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    const loadEffect = async () => {
      const res = await getAllBuildingContractors()
      const data = res.data

      const selectOptions = data.map((partner) => {
        return { label: partner.name, value: partner.id }
      })

      setPartnerOptions(selectOptions)
    }
    loadEffect()
  }, [])

  if (!rowData) return
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        partners: [...rowData.partner_ids],

        projectName: rowData.name,

        addresses: [
          ...rowData.addresses.map((address) => {
            return {
              street: address.street,
              postalCode: address.postal_code,
              houseNumber: address.house_nr,
              city: address.city,
              country: address.country,
              lat: address.lat || '',
              lon: address.lon || '',
            }
          }),
        ],

        contacts: [
          ...rowData.contacts.map((contact) => {
            return {
              firstName: contact.firstname,
              lastName: contact.lastname,
              email: contact.email,
              phoneNumber: contact.phone,
              contactType: 'public',
              description: contact.description || '',
            }
          }),
        ],
      }}
      onSubmit={(values) => handleSubmit(values)}
      validationSchema={Yup.object().shape({
        projectName: Yup.string().required(t('Yup.validation.error.required')),
        addresses: Yup.array().of(
          Yup.object().shape({
            country: Yup.string()
              .required(t('Yup.validation.error.required'))
              .min(2, t('Yup.validation.error.country.length'))
              .max(2, t('Yup.validation.error.country.length')),
            street: Yup.string().required(t('Yup.validation.error.required')),
            houseNumber: Yup.string(),
            city: Yup.string().required(t('Yup.validation.error.required')),
            postalCode: Yup.string().required(t('Yup.validation.error.required')),
            latitude: Yup.number().typeError(t('Yup.validation.error.invalid.lat')),
            longitude: Yup.number().typeError(t('Yup.validation.error.invalid.lon')),
          }),
        ),
        contacts: Yup.array().of(
          Yup.object().shape({
            firstName: Yup.string().required(t('Yup.validation.error.required')),
            lastName: Yup.string().required(t('Yup.validation.error.required')),
            email: Yup.string()
              .email(t('Yup.validation.error.invalid.email'))
              .required(t('Yup.validation.error.required')),
            phoneNumber: Yup.string().required(t('Yup.validation.error.required')),
            description: Yup.string(),
          }),
        ),
        partners: Yup.array().of(Yup.string()),
      })}
    >
      {(props) => (
        <Form onChange={() => setInitialvaluesChanged(true)}>
          <div className="edit-drawer__body-sideicons">
            <FormTextInput
              className="mb-4"
              placeholder={t('Backoffice.Wizard.AddProject.label.projectName')}
              type="text"
              id="projectName"
              name="projectName"
            />
            <EditDrawerHeading
              text={t('Backoffice.Wizard.AddProject.steps.first')}
              amount={props.values.addresses.length}
            />
            <FormSelectMultipleAddresses />

            <EditDrawerHeading
              text={t('Backoffice.Wizard.AddProject.steps.second')}
              amount={props.values.contacts.length}
            />
            <FormSelectMultipleContacts />

            <EditDrawerHeading
              text={t('Backoffice.Wizard.AddProject.steps.third')}
              amount={props.values.partners.length}
            />
            <FormSelectMultiplePartners partnerOptions={partnerOptions} />
            {error && (
              <CFormFeedback className="text-start" invalid>
                {errorMsg}
              </CFormFeedback>
            )}
          </div>
          <EditDrawerFooter
            initialValuesChanged={initialValuesChanged}
            setEditOffcanvasVisible={setEditOffcanvasVisible}
            setDeactivateOffcanvasVisible={setDeactivateOffcanvasVisible}
            unsavedChangesOffcanvasVisible={unsavedChangesOffcanvasVisible}
            setUnsavedChangesOffcanvasVisible={setUnsavedChangesOffcanvasVisible}
          />
        </Form>
      )}
    </Formik>
  )
}
