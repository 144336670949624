import React, { Suspense, useContext, useEffect, useState } from 'react'
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom'
import BackOfficeBranches from 'src/views/pages/backoffice/BackOfficeBranches'
import BackOfficePartners from 'src/views/pages/backoffice/BackOfficePartners'
import BackOfficeProjects from 'src/views/pages/backoffice/BackOfficeProjects'
import BackOfficeUsers from 'src/views/pages/backoffice/BackOfficeUsers'
import ProductAndPriceManagement from 'src/views/pages/products/ProductAndPriceManagement'
import BackOfficeErpIntegration from 'src/views/pages/backoffice/BackOfficeErpIntegration'
import BackOfficeCalendar from 'src/views/pages/backoffice/BackOfficeCalendar'

import {
  AuthProvider,
  AuthIsSignedIn,
  AuthIsNotSignedIn,
  ROLE,
  excludeRoles,
} from '../contexts/authContext'
import { ProtectedRoute } from './ProtectedRoutes/ProtectedRoute'
import BackOfficeLayout from 'src/layout/BackOfficeLayout/BackOfficeLayout'
import IdleTimerContextWrapper from '../contexts/idleTimerContext'
import Plausible from 'plausible-tracker'
import { UserDataProvider } from 'src/contexts/userDataContext'
import { useNavigatePartnerTypePage } from 'src/hooks/useNavigatePartnerTypePage'
import { ProtectedPartnerRoute } from './ProtectedRoutes/ProtectedPartnerTypeRoute'
import { ProjectMaterialViewContextWrapper } from 'src/contexts/projectMaterialViewContext'
import { AuthContext } from '../contexts/authContext'
import ProductUpload from 'src/views/pages/products/ProductUpload'
import ScrollToTop from './ScrollToTop'
import MetaDecorator from 'src/MetaDecorator'

// Containers
const SignInLayout = React.lazy(() => import('../layout/SignInLayout'))
const MainLayout = React.lazy(() => import('../layout/MainLayout'))

// Pages
const ProjectMaterialView = React.lazy(() =>
  import('../views/pages/projectmaterialview/ProjectMaterialView'),
)
const ProductList = React.lazy(() => import('../views/pages/productlist/ProductList'))
const ProductView = React.lazy(() => import('../views/pages/productview/ProductView'))
const ProjectMaterialListOverview = React.lazy(() =>
  import('../views/pages/projectmateriallistoverview/ProjectMaterialListOverview'),
)
const Invoices = React.lazy(() => import('../views/pages/invoices/invoices'))
const OptOut = React.lazy(() => import('../views/pages/mailnotifications/OptOut'))
const LandingPage = React.lazy(() => import('../views/pages/landing/CubotooLandingPage'))
const Page404 = React.lazy(() => import('../views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('../views/pages/page500/Page500'))

const VideoConferencePage = React.lazy(() =>
  import('../views/pages/videoconference/VideoConferencePage'),
)

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
)

export const SignInRoute = () => (
  <BrowserRouter>
    <ScrollToTop />
    <Suspense fallback={loading}>
      <Routes>
        <Route path="/" element={<SignInLayout />}>
          <Route index element={<LandingPage />} />
          <Route exact path="/cubotoo" name="Landing Page" element={<LandingPage />} />
          <Route exact path="/registration" name="Registration" element={<LandingPage />} />
          <Route exact path="/login" name="Login" element={<LandingPage />} />
          <Route
            exact
            path="/:language/categories"
            name="Category List"
            element={<ProductList />}
          />
          <Route
            exact
            path="/:language/categories/:id"
            name="Category Details"
            element={<ProductList />}
          />
          <Route
            exact
            path="/:language/categories/:sub1/:id"
            name="Category Details"
            element={<ProductList />}
          />
          <Route
            exact
            path="/:language/categories/:sub1/:sub2/:id"
            name="Category Details"
            element={<ProductList />}
          />
          <Route
            exact
            path="/:language/kategorien"
            name="Category List"
            element={<ProductList />}
          />
          <Route
            exact
            path="/:language/kategorien/:id"
            name="Category Details"
            element={<ProductList />}
          />
          <Route
            exact
            path="/:language/kategorien/:sub1/:id"
            name="Category Details"
            element={<ProductList />}
          />
          <Route
            exact
            path="/:language/kategorien/:sub1/:sub2/:id"
            name="Category Details"
            element={<ProductList />}
          />
          <Route exact path="/:language/categorie" name="Category List" element={<ProductList />} />
          <Route
            exact
            path="/:language/categorie/:id"
            name="Category Details"
            element={<ProductList />}
          />
          <Route
            exact
            path="/:language/categorie/:sub1/:id"
            name="Category Details"
            element={<ProductList />}
          />
          <Route
            exact
            path="/:language/categorie/:sub1/:sub2/:id"
            name="Category Details"
            element={<ProductList />}
          />
          <Route
            exact
            path="/:language/catégories"
            name="Category List"
            element={<ProductList />}
          />
          <Route
            exact
            path="/:language/catégories/:id"
            name="Category Details"
            element={<ProductList />}
          />
          <Route
            exact
            path="/:language/catégories/:sub1/:id"
            name="Category Details"
            element={<ProductList />}
          />
          <Route
            exact
            path="/:language/catégories/:sub1/:sub2/:id"
            name="Category Details"
            element={<ProductList />}
          />
          <Route exact path="/products" name="Product List" element={<ProductList />} />
          <Route exact path="/productDetail/:id" name="Product Detail" element={<ProductView />} />
          <Route
            exact
            path="/opt-out/:pmvid/:viewerid"
            name="Project Material View"
            element={<OptOut />}
          />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />

          <Route path="*" element={<Navigate to="/login" />} />
        </Route>
      </Routes>
    </Suspense>
  </BrowserRouter>
)

export const MainRoute = () => {
  const auth = useContext(AuthContext)
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Suspense fallback={loading}>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route
              index
              element={
                auth.role === ROLE.MANUFACTURER_ADMIN || auth.role === ROLE.MANUFACTURER ? (
                  <Navigate to="/projectMaterialOverview" replace={true} />
                ) : auth.role === ROLE.BUYER ||
                  auth.role === ROLE.FOREMAN ||
                  auth.role === ROLE.LEAD_BUYER ||
                  auth.role === ROLE.BUILDING_CONTRACTOR_ADMIN ? (
                  <Navigate to="/products" replace={true} />
                ) : (
                  <Navigate to="/cubotoo" replace={true} />
                )
              }
            />
            <Route exact path="/cubotoo" name="Landing Page" element={<LandingPage />} />
            <Route
              exact
              path="/productDetail/:id"
              name="Product Detail"
              element={<ProductView />}
            />

            {/* <Route
              exact
              path="videoconference"
              name="Video Conference"
              element={<VideoConferencePage />}
            /> */}
            <Route
              exact
              path="/projectMaterialView/:pmvid"
              name="Project Material View"
              element={<ProjectMaterialView />}
            />
            <Route
              exact
              path="/opt-out/:pmvid/:viewerid"
              name="Project Material View"
              element={<OptOut />}
            />
            <Route exact path="/invoices" name="Invoices" element={<Invoices />} />
            <Route exact path="/products" name="Product List" element={<ProductList />} />
            <Route
              exact
              path="/categories/:language"
              name="Category List"
              element={<ProductList />}
            />
            <Route
              exact
              path="/:language/categories"
              name="Category List"
              element={<ProductList />}
            />
            <Route
              exact
              path="/:language/categories/:id"
              name="Category Details"
              element={<ProductList />}
            />
            <Route
              exact
              path="/:language/categories/:sub1/:id"
              name="Category Details"
              element={<ProductList />}
            />
            <Route
              exact
              path="/:language/categories/:sub1/:sub2/:id"
              name="Category Details"
              element={<ProductList />}
            />
            <Route
              exact
              path="/:language/kategorien"
              name="Category List"
              element={<ProductList />}
            />
            <Route
              exact
              path="/:language/kategorien/:id"
              name="Category Details"
              element={<ProductList />}
            />
            <Route
              exact
              path="/:language/kategorien/:sub1/:id"
              name="Category Details"
              element={<ProductList />}
            />
            <Route
              exact
              path="/:language/kategorien/:sub1/:sub2/:id"
              name="Category Details"
              element={<ProductList />}
            />
            <Route
              exact
              path="/:language/categorie"
              name="Category List"
              element={<ProductList />}
            />
            <Route
              exact
              path="/:language/categorie/:id"
              name="Category Details"
              element={<ProductList />}
            />
            <Route
              exact
              path="/:language/categorie/:sub1/:id"
              name="Category Details"
              element={<ProductList />}
            />
            <Route
              exact
              path="/:language/categorie/:sub1/:sub2/:id"
              name="Category Details"
              element={<ProductList />}
            />
            <Route
              exact
              path="/:language/catégories"
              name="Category List"
              element={<ProductList />}
            />
            <Route
              exact
              path="/:language/catégories/:id"
              name="Category Details"
              element={<ProductList />}
            />
            <Route
              exact
              path="/:language/catégories/:sub1/:id"
              name="Category Details"
              element={<ProductList />}
            />
            <Route
              exact
              path="/:language/catégories/:sub1/:sub2/:id"
              name="Category Details"
              element={<ProductList />}
            />
            <Route exact path="/products" name="Product List" element={<ProductList />} />
            <Route
              exact
              path="/projectMaterialOverview"
              name="Project Material List Overview"
              element={<ProjectMaterialListOverview />}
            ></Route>
            <Route
              exact
              path="/projectMaterialView/:pmvid"
              name="Project Material View"
              element={<ProjectMaterialView />}
            />
            <Route exact path="/invoices" name="Invoices" element={<Invoices />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />

            <Route path="*" element={<Navigate to="/" />} />
          </Route>
          <Route
            element={
              <ProtectedRoute
                allowedPermissions={[
                  ROLE.CUBOTOO_ADMIN,
                  ROLE.BUILDING_CONTRACTOR_ADMIN,
                  ROLE.MANUFACTURER_ADMIN,
                  ROLE.LEAD_BUYER,
                  ROLE.BUYER,
                ]}
              />
            }
          >
            <Route exact path="/backoffice" name="Backoffice" element={<BackOfficeLayout />}>
              <Route element={<ProtectedPartnerRoute />}>
                <Route
                  exact
                  path="partners/:partnerType"
                  name="Partner Management"
                  element={<BackOfficePartners />}
                />
              </Route>
              <Route
                exact
                path="partners"
                name="Partner Management"
                element={useNavigatePartnerTypePage()}
              />
              <Route
                exact
                path="projects"
                name="Projects Management"
                element={<BackOfficeProjects />}
              />
              <Route
                element={
                  <ProtectedRoute
                    allowedPermissions={[
                      ...excludeRoles([ROLE.MANUFACTURER_ADMIN, ROLE.BUYER, ROLE.LEAD_BUYER]),
                    ]}
                  />
                }
              >
                <Route
                  exact
                  path="branches"
                  name="Branches Management"
                  element={<BackOfficeBranches />}
                />
              </Route>
              <Route
                element={
                  <ProtectedRoute
                    allowedPermissions={[
                      ...excludeRoles([
                        ROLE.BUILDING_CONTRACTOR_ADMIN,
                        ROLE.BUYER,
                        ROLE.LEAD_BUYER,
                      ]),
                    ]}
                  />
                }
              >
                <Route
                  exact
                  path="products"
                  name="Product Management"
                  element={<ProductAndPriceManagement />}
                />
                <Route
                  exact
                  path="products/:prices"
                  name="Price Management"
                  element={<ProductAndPriceManagement />}
                />

                <Route exact path="upload" name="Product Upload" element={<ProductUpload />} />
              </Route>
              <Route
                element={
                  <ProtectedRoute
                    allowedPermissions={[
                      ROLE.BUILDING_CONTRACTOR_ADMIN,
                      ROLE.CUBOTOO_ADMIN,
                      ROLE.MANUFACTURER_ADMIN,
                    ]}
                  />
                }
              >
                <Route exact path="users" name="Users Management" element={<BackOfficeUsers />} />
              </Route>

              <Route element={<ProtectedRoute allowedPermissions={[ROLE.CUBOTOO_ADMIN]} />}>
                <Route
                  exact
                  path="mfErpIntegration"
                  name="MF Erp Integration"
                  element={<BackOfficeErpIntegration />}
                />
                <Route exact path="calendar" name="calendar" element={<BackOfficeCalendar />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export const RouteOptions = (props) => {
  const [logout, setLogout] = useState(null)

  const { enableAutoPageviews } = Plausible({
    domain: 'bamboo.dev.cubotoo.ch',
    trackLocalhost: true,
  })

  useEffect(() => {
    if (!logout) return
    Navigate('/')
    setTimeout(() => {
      setLogout(false)
    }, 1000)
  }, [logout])

  useEffect(() => {
    enableAutoPageviews()
  }, [])

  return (
    <>
      <AuthProvider>
        <IdleTimerContextWrapper>
          <AuthIsSignedIn>
            <ProjectMaterialViewContextWrapper>
              <UserDataProvider>
                <MetaDecorator />
                <MainRoute />
              </UserDataProvider>
            </ProjectMaterialViewContextWrapper>
          </AuthIsSignedIn>
          <AuthIsNotSignedIn>
            <MetaDecorator />
            <SignInRoute />
          </AuthIsNotSignedIn>
        </IdleTimerContextWrapper>
      </AuthProvider>
    </>
  )
}
