import * as Yup from 'yup'

export const updateManufacturerValidation = (t) =>
  Yup.object({
    type: Yup.string().required(t('Yup.validation.error.required')),
    name: Yup.string().required(t('Yup.validation.error.required')),
    country: Yup.string()
      .required(t('Yup.validation.error.required'))
      .min(2, t('Yup.validation.error.country.length'))
      .max(2, t('Yup.validation.error.country.length')),
    vatNum: Yup.string().required(t('Yup.validation.error.required')),
    HGCPartnerId: Yup.string().required(t('Yup.validation.error.required')),
    prefix: Yup.string()
      .min(3, t('Yup.Validation.Error.Prefix.Min'))
      .required(t('Yup.validation.error.required')),
    domain: Yup.string().min(3, t('Yup.validation.error.domain.minLength')),
    purpose: Yup.string(),
    billingEmail: Yup.string()
      .email(t('Yup.validation.error.invalid.email'))
      .required(t('Yup.validation.error.required')),
    street: Yup.string().required(t('Yup.validation.error.required')),
    houseNumber: Yup.string().nullable(),
    city: Yup.string().required(t('Yup.validation.error.required')),
    postalCode: Yup.string().required(t('Yup.validation.error.required')),
    addressCountry: Yup.string()
      .min(2, t('Yup.validation.error.country.length'))
      .max(2, t('Yup.validation.error.country.length'))
      .required(t('Yup.validation.error.required')),
    lat: Yup.number(),
    lon: Yup.number(),
    nextDayDelivery: Yup.boolean().required(t('Yup.validation.error.required')),
    productAvailabilityDisplay: Yup.string().required(t('Yup.validation.error.required')),
    videoCall: Yup.boolean().required(t('Yup.validation.error.required')),
    hidePrices: Yup.boolean().required(t('Yup.validation.error.required')),
    percentage: Yup.number()
      .min(0, t('Yup.Validation.Error.Percentage.Min'))
      .max(100, t('Yup.Validation.Error.Percentage.Max'))
      .required(t('Yup.validation.error.required')),
    paymentTerms: Yup.number()
      .min(0, t('Yup.Validation.Error.PaymentTerms.Min'))
      .required(t('Yup.validation.error.required')),
    settlementFeeType: Yup.string().required(t('Yup.validation.error.required')),
    settlementFeePercentage: Yup.number()
      .min(0, t('Yup.Validation.Error.Percentage.Min'))
      .max(100, t('Yup.Validation.Error.Percentage.Max'))
      .required(t('Yup.validation.error.required')),
    contactEmail: Yup.string()
      .email(t('Yup.validation.error.invalid.email'))
      .required(t('Yup.validation.error.required')),
    languages: Yup.array()
      .of(Yup.mixed().oneOf(['de', 'en', 'fr', 'it']))
      .min(1, t('Yup.validation.error.required')),
    francoLimit: Yup.number().nullable(),
    leadTime: Yup.number().nullable().integer(),
  }).test('francoLimit-leadTime', function (value) {
    const { francoLimit, leadTime } = value

    const isFrancoLimitEmpty = francoLimit === null || francoLimit === undefined
    const isLeadTimeEmpty = leadTime === null || leadTime === undefined

    if (isFrancoLimitEmpty && isLeadTimeEmpty) {
      return true
    }

    if (francoLimit && leadTime) {
      return true
    }

    if (francoLimit && isLeadTimeEmpty) {
      return this.createError({
        path: 'leadTime',
        message: t('Yup.validation.error.required'),
      })
    } else if (isFrancoLimitEmpty && leadTime) {
      return this.createError({
        path: 'francoLimit',
        message: t('Yup.validation.error.required'),
      })
    }
  })
