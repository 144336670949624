import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { BButton } from '../ui/BButton'
import { cilTrash } from '@coreui/icons'
import deactivate from '../../assets/images/cubotoo/deactivate.svg'
import { cisWarning } from '@coreui/icons-pro'
import { BackofficeContext } from 'src/contexts/backofficeContext'
import { Conditional } from '../Conditional'
import { CButton, CImage } from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilPlus } from '@coreui/icons'
import { AuthContext, PERMISSIONS } from 'src/contexts/authContext'

export const ButtonCellRenderer = (props) => {
  const cellValue = props.valueFormatted ? props.valueFormatted : props.value
  return (
    <span>
      <BButton onClick={() => {}}>{cellValue}</BButton>
    </span>
  )
}

export const TrashCellRenderer = (props) => {
  const authContext = useContext(AuthContext)
  const { t } = useTranslation()
  const setOffcanvasVisible = props.context.setOffcanvasVisible
  const setDrawerMode = props.context.setDrawerMode
  const identifier = props.context.identifier

  // eslint-disable-next-line no-prototype-builtins
  const keyToCheck = props.data.hasOwnProperty('status') ? 'status' : 'active'

  return (
    <span>
      {props.data[keyToCheck] === 'active' && (
        <CButton
          color="link"
          className="btn btn-transparent btn-sm me-3 btn-table__option btn-table__option--deactivate-action"
          onClick={() => {
            setDrawerMode('deactivate')
            setOffcanvasVisible(true)
          }}
        >
          <div className="d-inline">
            <CImage src={deactivate} className="p-2 deactivate__icn" />
          </div>
          {t('Backoffice.toolbar.deactivate.label')}
        </CButton>
      )}
      {Object.keys(PERMISSIONS[authContext.role][identifier])?.includes('DELETE') && (
        <CButton
          color="link"
          className="btn btn-transparent btn-sm me-3 btn-table__option btn-table__option--deactivate-action"
          onClick={() => {
            setDrawerMode('delete')
            setOffcanvasVisible(true)
          }}
        >
          <div className="d-inline">
            <CImage src={deactivate} className="p-2 deactivate__icn" />
          </div>
          {t('Backoffice.drawer.delete.confirmButton')}
        </CButton>
      )}
    </span>
  )
}

export const TrashOrActivateCellRenderer = (props) => {
  const { handleSelectedEntity, handleDeletedEntity } = useContext(BackofficeContext)
  return (
    <span>
      <Conditional condition={props.data.status === 'active'}>
        <BButton
          useicon={true}
          icontype={cilTrash}
          onClick={() => {
            handleDeletedEntity(props.data)
          }}
        ></BButton>
      </Conditional>
      <Conditional condition={props.data.status !== 'active'}>
        <BButton
          useicon={true}
          icontype={cisWarning}
          onClick={() => {
            handleSelectedEntity(props.data)
          }}
        ></BButton>
      </Conditional>
    </span>
  )
}

export const BadgeCellRenderer = (props) => {
  const cellValue = props.valueFormatted ?? props.value
  // can be transformed into a switch-case statement if more variants occur
  const style = cellValue?.toLocaleLowerCase() == 'inactive' ? 'inactive' : 'standard'

  return cellValue ? (
    <div className="d-flex justify-content-center align-items-center h-100 w-100">
      <span className={`badge badge--${style} ag-row`} size="sm">
        {cellValue}
      </span>
    </div>
  ) : null
}

export const NestedRowsRenderer = (props) => {
  if (props.value.length === 1) return <SingleRowRenderer {...props} />
  else return <MultipleRowsRenderer {...props} />
}

export const SingleRowRenderer = (props) => {
  const setEditOffcanvasVisible = props.context.setEditOffcanvasVisible
  const setExistingStepNumber = props.context.setExistingStepNumber

  return (
    <div className="d-flex align-items-center justify-content-end h-100 w-100">
      <p className="m-0 me-4 w-100">{props.title}</p>
      <CButton
        onClick={() => {
          setExistingStepNumber(props.stepNumber)
          setEditOffcanvasVisible(true)
        }}
        size="sm"
        color="transparent"
        className="rounded nested-rows-renderer__button p-1"
      >
        <CIcon icon={cilPlus} />
      </CButton>
    </div>
  )
}

export const MultipleRowsRenderer = (props) => {
  const { t } = useTranslation()
  const setOverviewOffcanvasVisible = props.context.setOverviewOffcanvasVisible
  const setExistingStepNumber = props.context.setExistingStepNumber
  return (
    <div className="d-flex align-items-center h-100 w-100">
      <CButton
        color="link"
        className="nested-rows-renderer__multiple pe-5"
        onClick={() => {
          setExistingStepNumber(props.stepNumber)
          setOverviewOffcanvasVisible(true)
        }}
      >
        {props.value.length} {t(props.type)}
      </CButton>
    </div>
  )
}

export const OverviewRowRenderer = (props) => {
  const setOverviewOffcanvasVisible = props.context.setOverviewOffcanvasVisible
  const setExistingStepNumber = props.context.setExistingStepNumber

  return (
    <CButton
      className="nested-rows-renderer__multiple pe-5"
      onClick={() => {
        setExistingStepNumber(0)
        setOverviewOffcanvasVisible(true)
      }}
      color="link"
    >
      {props?.data.name || props?.data.last_name}
    </CButton>
  )
}

export const cellRendererParamsAddresses = (params) => {
  return {
    title: `${params.value[0].street} ${params.value[0].house_nr}, ${params.value[0].postal_code} ${params.value[0].city}`,
    type: 'Backoffice.projects.table.columns.addresses',
    stepNumber: 0,
  }
}

export const cellRendererParamsContacts = (params) => {
  return {
    title: `${params?.value[0]?.firstname ? params?.value[0]?.firstname : ''} ${
      params?.value[0]?.lastname ? params?.value[0]?.lastname : ''
    } ${
      params?.value[0]?.phone && !params?.value[0]?.firstname && !params?.value[0]?.lastname
        ? params?.value[0]?.phone
        : ''
    }`,
    type: 'Backoffice.projects.table.columns.contacts',
    stepNumber: 1,
  }
}

export const cellRendererParamsPartnerIDs = (params) => {
  return {
    title: params.value[0],
    type:
      params.value.length === 1
        ? 'Backoffice.projects.table.columns.partner'
        : 'Backoffice.projects.table.columns.partners',
    stepNumber: 2,
  }
}

export const languageGetter = (item) => {
  return item.data.language?.toUpperCase()
}

export const dobGetter = (item) => {
  return item.data.date_of_birth?.split('T')[0]
}

export const rolesGetter = (item) => {
  if (item.data.partner_roles && item.data.partner_roles.length > 0) {
    return item.data.partner_roles[0]?.role
  }
  return 'cubotoo_admin'
}

export const CheckBoxCellRenderer = ({ value }) => {
  return <input className="checkbox" type="checkbox" checked={value} readOnly />
}

export const HyperLinkCellRenderer = ({ value }) => {
  return (
    <a href={value} target="_blank" rel="noopener noreferrer">
      {' '}
      {value}
    </a>
  )
}
