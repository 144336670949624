import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from './i18n-backend'

if (!localStorage.getItem('i18nextLng')) {
  localStorage.setItem('i18nextLng', 'de')
} else {
  let item = localStorage.getItem('i18nextLng')
  if (item?.includes('-') || item?.includes('_')) {
    localStorage.setItem('i18nextLng', 'de')
  }
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'fr', 'it', 'de'],
    fallbackLng: 'de',
    debug: true,
    ns: ['translation'],
    load: 'languageOnly',
    lowerCaseLng: true,
  })

export default i18n
