import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { AuthContext, ROLE } from '../../../contexts/authContext'
import {
  CCol,
  CRow,
  CTabPane,
  CTabContent,
  CNavItem,
  CNavLink,
  CNav,
  CAvatar,
} from '@coreui/react-pro'
import { GlobalVariablesContext } from 'src/contexts/globalVariablesContext'
import {
  getInitialsPartnerName,
  generateColor,
} from 'src/views/pages/products/components/PartnerWidget'

const OptionsPopover = ({ openedUserPopover, setIsOpenedUserPopover, authInfo }) => {
  const { t } = useTranslation()
  const auth = useContext(AuthContext)
  const { setChangePasswordModalVisible } = useContext(GlobalVariablesContext)
  const [activeKey, setActiveKey] = useState(1)

  function signOutClicked() {
    auth.signOut()
  }

  const openChangePasswordModal = () => {
    setChangePasswordModalVisible(true)
    setIsOpenedUserPopover(!openedUserPopover)
  }

  const toggleUserPopover = () => {
    setIsOpenedUserPopover(false)
  }

  return (
    <section className={`user-popover ${openedUserPopover ? 'is-opened' : ''}`}>
      <section className="user-popover--head">
        <CRow className="justify-content-between">
          <CCol className="col-md-auto d-flex">
            <div>
              {authInfo.first_name && authInfo.last_name ? (
                <CAvatar
                  sx={{ width: '32px', height: '32px' }}
                  style={{
                    background: generateColor(
                      getInitialsPartnerName(`${authInfo.first_name} ${authInfo.last_name}`),
                    ),
                    marginRight: '5px',
                  }}
                  textColor="white"
                >
                  {getInitialsPartnerName(`${authInfo.first_name} ${authInfo.last_name}`)}
                </CAvatar>
              ) : (
                <div className="user-loggedIn-icon">C</div>
              )}
            </div>
            <div>
              {authInfo.first_name && authInfo.last_name ? (
                <>
                  <span className="given-name color-white">{authInfo.first_name}</span>
                  <span className="family-name color-white">{authInfo.last_name}</span>
                </>
              ) : (
                <span>{t('MainMenu.CubotooUser')}</span>
              )}

              {authInfo.email && (
                <>
                  <p className="user--email color-white">
                    {authInfo.email}
                    <span
                      className="copy-icon"
                      onClick={() => navigator.clipboard.writeText(authInfo.email)}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="20" fill="none">
                        <path stroke="#F6F6F6" d="M11.697 3.822V.68H1.277v14.142h3.607" />
                        <path stroke="#F6F6F6" d="M6.988 6.297h9.42V19.44h-9.42z" />
                      </svg>
                    </span>
                  </p>
                  <p className="user--email color-white">{t(`Role.${auth.role}`)}</p>
                </>
              )}
            </div>
          </CCol>
          {/* {auth.role && (
            <CCol className="col-md-auto">
              <span className="user--role color-white">{auth.role}</span>
            </CCol>
          )} */}
        </CRow>
      </section>
      <section className="user-popover--content">
        <div className="user-tabs">
          <CNav role="tablist">
            <CNavItem>
              <CNavLink active={activeKey === 1} onClick={() => setActiveKey(1)}>
                {auth.role == ROLE.MANUFACTURER_ADMIN || auth.role == ROLE.MANUFACTURER
                  ? t('MainMenu.Sales')
                  : t('MainMenu.Purchases')}
              </CNavLink>
            </CNavItem>
            {auth.role == ROLE.CUBOTOO_ADMIN ||
            auth.role == ROLE.MANUFACTURER_ADMIN ||
            auth.role == ROLE.BUILDING_CONTRACTOR_ADMIN ||
            auth.role == ROLE.MANUFACTURER ? (
              <CNavItem>
                <CNavLink active={activeKey === 2} onClick={() => setActiveKey(2)}>
                  {t('MainMenu.MaintainData')}
                </CNavLink>
              </CNavItem>
            ) : (
              ''
            )}
            <CNavItem>
              <CNavLink active={activeKey === 3} onClick={() => setActiveKey(3)}>
                {t('MainMenu.MyAccount')}
              </CNavLink>
            </CNavItem>
          </CNav>
          <CTabContent>
            <CTabPane role="tabpanel" aria-labelledby="purchases-tab" visible={activeKey === 1}>
              <div className="d-flex flex-column">
                {auth.role !== ROLE.MANUFACTURER ? (
                  <Link
                    className="text-decoration-none"
                    to="/projectMaterialOverview"
                    onClick={toggleUserPopover}
                  >
                    {t('MainMenu.Overview')}
                  </Link>
                ) : (
                  ''
                )}
                <Link className="text-decoration-none" to="/invoices" onClick={toggleUserPopover}>
                  {t('MainMenu.Invoices')}
                </Link>
                {/* <Link
                  className="text-decoration-none"
                  to="/videoconference"
                  onClick={toggleUserPopover}
                >
                  {t('Header.Navigation.Popover.Link.VideoConference')}
                </Link> */}
                <Link className="text-decoration-none" to="/products" onClick={toggleUserPopover}>
                  {t('Header.Navigation.Popover.Link.Products')}
                </Link>
              </div>
            </CTabPane>
            {auth.role == ROLE.CUBOTOO_ADMIN ||
            auth.role == ROLE.MANUFACTURER_ADMIN ||
            auth.role == ROLE.BUILDING_CONTRACTOR_ADMIN ||
            auth.role == ROLE.MANUFACTURER ? (
              <CTabPane
                role="tabpanel"
                aria-labelledby="maintain-data-tab"
                visible={activeKey === 2}
              >
                <div className="d-flex flex-column">
                  <Link
                    className="text-decoration-none"
                    to="/backoffice/partners"
                    onClick={toggleUserPopover}
                  >
                    {t('Backoffice.layout.subpage.partners')}
                  </Link>
                  {![ROLE.MANUFACTURER_ADMIN, ROLE.MANUFACTURER].includes(auth.role) && (
                    <Link
                      className="text-decoration-none"
                      to="/backoffice/branches"
                      onClick={toggleUserPopover}
                    >
                      {t('Backoffice.layout.subpage.branches')}
                    </Link>
                  )}
                  <Link
                    className="text-decoration-none"
                    to="/backoffice/projects"
                    onClick={toggleUserPopover}
                  >
                    {t('Backoffice.layout.subpage.projects')}
                  </Link>
                  <Link
                    className="text-decoration-none"
                    to="/backoffice/users"
                    onClick={toggleUserPopover}
                  >
                    {t('Backoffice.partners.table.columns.users')}
                  </Link>
                  {[ROLE.CUBOTOO_ADMIN, ROLE.MANUFACTURER_ADMIN, ROLE.MANUFACTURER].includes(
                    auth.role,
                  ) && (
                    <>
                      <Link
                        className="text-decoration-none"
                        to="/backoffice/products"
                        onClick={toggleUserPopover}
                      >
                        {t('Header.Navigation.Popover.Link.Products')}
                      </Link>
                      <Link
                        className="text-decoration-none"
                        to="/backoffice/products/prices"
                        onClick={toggleUserPopover}
                      >
                        {t('Header.Navigation.Popover.Link.Prices')}
                      </Link>
                    </>
                  )}
                  {[ROLE.CUBOTOO_ADMIN].includes(auth.role) && (
                    <>
                      <Link
                        className="text-decoration-none"
                        to="/backoffice/mfErpIntegration"
                        onClick={toggleUserPopover}
                      >
                        {t('Backoffice.layout.subpage.mfErpIntegration')}
                      </Link>
                      <Link
                        className="text-decoration-none"
                        to="/backoffice/calendar"
                        onClick={toggleUserPopover}
                      >
                        {t('Backoffice.layout.subpage.calendar')}
                      </Link>
                    </>
                  )}
                </div>
              </CTabPane>
            ) : (
              ''
            )}
            <CTabPane role="tabpanel" aria-labelledby="account-tab" visible={activeKey === 3}>
              <div className="d-flex flex-column">
                <span onClick={() => openChangePasswordModal()}>
                  {t('Header.Navigation.Popover.Link.ChangePassword')}
                </span>
                {/* <Link className="text-decoration-none" to="/settings" onClick={toggleUserPopover}>
                  {t('Header.Navigation.Popover.Link.Settings')}
                </Link> */}
              </div>
            </CTabPane>
          </CTabContent>
        </div>
      </section>
      <SignoutFooter t={t} signOutClicked={signOutClicked} />
    </section>
  )
}

export default OptionsPopover

export const SignoutFooter = ({ signOutClicked, t }) => {
  const location = useLocation()
  const navigate = useNavigate()

  return (
    <section className="user-popover--footer d-flex flex-row-reverse justify-content-between align-items-center">
      <div className="popover-sign-out">
        <div id="Signout" onClick={signOutClicked}>
          <span>{t('Header.Navigation.Popover.Button.SIGNOUT')}</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="21" fill="none">
            <path
              fill="#000"
              fillRule="evenodd"
              d="M5.012 10.945a.714.714 0 0 0 .715.715h8.275l-3.067 3.065a.715.715 0 0 0 1.012 1.012l4.285-4.286a.713.713 0 0 0 0-1.011l-4.285-4.286a.715.715 0 0 0-1.012 1.011l3.067 3.066H5.727a.714.714 0 0 0-.715.714Zm-3.571 10a.714.714 0 0 1-.714-.714V1.66a.714.714 0 0 1 1.428 0V20.23a.714.714 0 0 1-.714.714Z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </div>
      {location.pathname.includes('backoffice') && (
        <div
          className="popover-sign-out cursor-pointer text-center"
          onClick={() => {
            navigate('/cubotoo')
          }}
        >
          <span className="m-0">{t('Header.Navigation.Popover.Button.Marketplace')}</span>
        </div>
      )}
    </section>
  )
}
