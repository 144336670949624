const common = (values) => {
  return {
    name: values.name,
    country: values.country,
    domain: values.domain || '',
  }
}

const partnerConfigCommon = (values) => {
  return {
    video_call: values.videoCall === 'true',
    contact_email: values.contactEmail,
    payment_terms: values.paymentTerms,
    hgc_partner_id: values.HGCPartnerId,
    hgc_partner_credit_id: values.HGCPartnerCreditId,
    invoice_email: values.invoice_email,
    credit_email: values.credit_email,
    receive_credit_emails: values.receive_credit_emails === 'true' ? true : false,
    receive_accumulate_fee_invoices:
      values.receive_accumulate_fee_invoices === 'true' ? true : false,
  }
}

const partnerAddress = (values) => {
  return {
    street: values.street,
    postal_code: values.postalCode,
    house_nr: values.houseNumber || null,
    city: values.city,
    country: values.addressCountry,
    lon: Number(values.lon) || undefined,
    lat: Number(values.lat) || undefined,
  }
}

export const createManufacturerBody = (values) => {
  return {
    type: 'manufacturer',
    prefix: values.prefix,
    languages: values.languages,
    status: values.status || 'active',
    ...common(values),
    legal_entity: {
      vat_num: values.vatNum,
      purpose: values.purpose || '',
      uid: '',
    },
    partner_config: {
      ...partnerConfigCommon(values),
      next_day_delivery: values.nextDayDelivery === 'true',
      product_availability_display: values.productAvailabilityDisplay,
      percentage: values.percentage,
      settlement_fee_type: values.settlementFeeType,
      settlement_fee_percentage: values.settlementFeePercentage,
      hide_prices: values.hidePrices === 'true',
      franco_limit: values.francoLimit || null,
      lead_time: values.leadTime || null,
    },
    address: {
      ...partnerAddress(values),
    },
  }
}

export const createBuildingContractorBody = (values) => {
  return {
    type: 'building_contractor',
    status: values.status || 'active',
    ...common(values),
    legal_entity: {
      vat_num: values.vatNum,
      purpose: values.purpose,
      uid: '',
    },
    address: {
      ...partnerAddress(values),
    },
    partner_config: {
      ...partnerConfigCommon(values),
    },
  }
}
