import React, { useState, useContext } from 'react'
import { w3cwebsocket as W3CWebSocket } from 'websocket'
import { LogContext } from './logContext'

export const WebSocketContext = React.createContext({})
export const WebSocketContextProvider = WebSocketContext.Provider
export const WS_SERVER_URL = process.env.REACT_APP_WS_SERVER_URL

const client = WS_SERVER_URL ? new W3CWebSocket(WS_SERVER_URL) : null

const WebSocketContextWrapper = (props) => {
  const [connected, setConnected] = useState(false)
  const log = useContext(LogContext)

  if (client) {
    client.onopen = () => {
      log.info('WebSocket Client Connected')
      setConnected(true)
    }

    client.onmessage = (message) => {
      log.info(`websocket received message ${message.data}`)
    }

    client.onopen = () => {
      log.info('WebSocket Client Connected')
    }
  }

  return <WebSocketContextProvider value={connected}>{props.children}</WebSocketContextProvider>
}

export default WebSocketContextWrapper
