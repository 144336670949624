import httpsV2 from '../../config/httpsV2'

// Get Project Material View Product Deliveries
export const getProjectMaterialViewProductDeliveries = (productId) => {
  return httpsV2.get(`/public/pmv/product/${productId}/delivery`)
}

// Create Project Material View Product Delivery
export const createProjectMaterialViewProductDelivery = (productId, data) => {
  return httpsV2.post(`/public/pmv/product/${productId}/delivery`, data)
}

// Remove Project Material View Product Delivery
export const removeProjectMaterialViewProductDelivery = (productId, deliveryId) => {
  return httpsV2.delete(`/public/pmv/product/${productId}/delivery/${deliveryId}`)
}

// Update Project Material View Product Delivery
export const updateProjectMaterialViewProductDelivery = (productId, deliveryId, data) => {
  return httpsV2.patch(`/public/pmv/product/${productId}/delivery/${deliveryId}`, data)
}
