import { CButton, CImage } from '@coreui/react-pro'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import deactivate from '../../assets/images/cubotoo/deactivate.svg'
import { UnsavedChangesDrawer } from './UnsavedChangesDrawer'
import { AuthContext, PERMISSIONS } from 'src/contexts/authContext'

export const EditDrawerFooter = ({
  initialValuesChanged,
  setEditOffcanvasVisible,
  setDeactivateOffcanvasVisible,
  unsavedChangesOffcanvasVisible,
  setUnsavedChangesOffcanvasVisible,
  isValid,
  identifier,
}) => {
  const { t } = useTranslation()
  const authContext = useContext(AuthContext)
  const permission_to_deactivate = Object.keys(PERMISSIONS[authContext.role][identifier])?.includes(
    'DEACTIVATE',
  )

  const cancelEditing = () => {
    if (initialValuesChanged) return setUnsavedChangesOffcanvasVisible(true)
    setEditOffcanvasVisible(false)
  }

  const disableEditButton = !isValid || !initialValuesChanged

  return (
    <>
      <UnsavedChangesDrawer
        unsavedChangesOffcanvasVisible={unsavedChangesOffcanvasVisible}
        setUnsavedChangesOffcanvasVisible={setUnsavedChangesOffcanvasVisible}
        setEditOffcanvasVisible={setEditOffcanvasVisible}
      />
      <div
        className={`d-flex align-items-center justify-content-between ${
          permission_to_deactivate ? '' : 'flex-row-reverse'
        } drawer__footer`}
      >
        {permission_to_deactivate && (
          <CButton
            className="btn-table__option btn-table__option--deactivate-action text-uppercase"
            size="sm"
            color="transparent"
            onClick={() => {
              setEditOffcanvasVisible(false)
              setDeactivateOffcanvasVisible(true)
            }}
          >
            <div className="d-inline">
              <CImage src={deactivate} className="p-2 text-uppercase deactivate__icn" />
            </div>
            {t('Backoffice.toolbar.deactivate.label')}
          </CButton>
        )}
        <div className="drawer__footer-btn">
          <CButton color="ghost" className="text-uppercase px-4" onClick={cancelEditing}>
            {t('Backoffice.drawer.cancelEditing')}
          </CButton>
          <CButton type="submit" className="text-uppercase px-3" disabled={disableEditButton}>
            {t('Backoffice.drawer.finsihEditing')}
          </CButton>
        </div>
      </div>
    </>
  )
}
