import EventEmitter from 'mitt'
import i18n from './i18n'

export const apiURL = process.env.REACT_APP_STRAPI_API_URL
export const apiToken = process.env.REACT_APP_STRAPI_API_TOKEN
export const toggleStrapiEventEmitter = new EventEmitter()

let showKeys = false

const toggleKeys = () => {
  showKeys = true
  i18n.reloadResources()
}
toggleStrapiEventEmitter.on('toggle-strapi', toggleKeys)

export async function getAPILocales() {
  return (
    await fetch(`${apiURL}/i18n/locales`, {
      headers: { Authorization: `Bearer ${apiToken}` },
    })
  ).json()
}

async function getAPITranslations(lang) {
  if (lang?.toLowerCase() === 'en-us') {
    lang = 'en'
  }

  let translations = (
    await fetch(`${apiURL}/translation?locale=${lang}&populate=*`, {
      headers: { Authorization: `Bearer ${apiToken}` },
    })
  ).json()

  translations = await translations
  translations = translations?.data?.attributes?.translations

  if (showKeys) {
    let keys = {}
    Object.keys(translations).forEach((key) => {
      keys[key] = key
    })
    translations = keys
  }

  return translations
}

async function getTranslations(lang, ns, callback) {
  const translations = await getAPITranslations(lang)
  callback(null, translations)
}

class Backend {
  read(lang, ns, callback) {
    getTranslations(lang, ns, callback)
  }
}
Backend.type = 'backend'

export default Backend
