import { CNav, CNavItem } from '@coreui/react-pro'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import RouterNavLink from 'src/components/RouterNavLink'
import { TABTYPE, useBackofficeParams } from 'src/hooks/useBackofficeParams'

export const NavLinks = [TABTYPE.MANUFACTURERS, TABTYPE.BUILDINGCONTRACTORS]

const PartnerTypesTabs = () => {
  const { selectedTab, partnerType } = useBackofficeParams()
  const [activeTab, setActiveTab] = useState(partnerType)
  const { t } = useTranslation()

  return (
    <CNav variant="underline">
      {NavLinks.map((item) => (
        <CNavItem key={item}>
          <RouterNavLink
            to={`/backoffice/${selectedTab}/${item.toLowerCase()}`}
            active={item === activeTab}
            onClick={() => setActiveTab(item)}
          >
            {t(`Backoffice.layout.subpage.${item}`)}
          </RouterNavLink>
        </CNavItem>
      ))}
    </CNav>
  )
}

export default PartnerTypesTabs
