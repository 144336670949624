import React, { useState, useEffect, useContext } from 'react'
import BTable from 'src/components/table/Table'
import { deletePerson, getAllPersons, updatePerson } from 'src/services/requests/users'
import Notifications from '../../../components/shared/notifications/NotificationsToaster'
import { getAllPartners } from 'src/services/requests/partners'
import { BackofficeContext } from 'src/contexts/backofficeContext'
import { Conditional } from 'src/components/Conditional'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '../../../contexts/authContext'
import { Spinner } from 'src/components/Spinner'
import { usersColumnDefs } from './lib/backoffice-lib'
import { EditUserForm } from './EditUserForm'
import { DeactivateDrawer } from 'src/components/table/DeactivateDrawer'
import { EditDrawer } from 'src/components/table/EditDrawer'
import { OverviewDrawer } from 'src/components/table/OverviewDrawer'
import { UserForm } from './forms/FormUser'
import { GlobalVariablesContext } from 'src/contexts/globalVariablesContext'

export default function BackOfficeUsers() {
  const [data, setData] = useState([])
  const [partners, setPartners] = useState([])
  const authContext = useContext(AuthContext)
  const [widgetViewWVisible, setWidgetViewVisible] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [rowsSelected, setRowsSelected] = useState([])
  const [offcanvasVisible, setOffcanvasVisible] = useState(false)
  const [drawerMode, setDrawerMode] = useState('deactivate')
  const [editOffcanvasVisible, setEditOffcanvasVisible] = useState(false)
  const [overviewOffcanvasVisible, setOverviewOffcanvasVisible] = useState(false)
  const [existingStepNumber, setExistingStepNumber] = useState(0)
  const { error } = useContext(GlobalVariablesContext)
  const [newPartnerId, setNewPartnerId] = useState()

  const { t, i18n } = useTranslation()
  const selectedLanguage = i18n.language

  const presavedColumns =
    localStorage.getItem(`${authContext.role}-users-columns`) &&
    JSON.parse(localStorage.getItem(`${authContext.role}-users-columns`))

  const fetchData = () => {
    getAllPersons().then((response) => {
      setData(response.data)
      setIsLoading(false)
    })
    getAllPartners().then((response) => {
      setPartners(
        response.data.map((partner) => {
          if (partner.status === 'inactive') {
            return { ...partner, name: `${partner.name} (inactive)` }
          } else {
            return partner
          }
        }),
      )
    })
  }

  useEffect(() => {
    const loadEffect = async () => {
      fetchData()
    }
    loadEffect()
  }, [selectedLanguage])

  const deleteRows = async (rowIds) => {
    const deleteRowsRequests = await rowIds.map(async (rowId) => await deletePerson(rowId))

    Promise.all(deleteRowsRequests).then(() => {
      fetchData()
    })
  }

  const deactivateRows = async (selectedRows) => {
    const deactivateRowsRequests = selectedRows.map(async (rowId) => {
      await updatePerson(rowId, { active: 'inactive' })
    })

    await Promise.all(deactivateRowsRequests)
    fetchData()
  }

  return (
    <>
      <Conditional condition={widgetViewWVisible}>
        <Spinner message={t('Backoffice.BackOfficeUsers.Label.FetchUsers')} pageloading={isLoading}>
          {error ? <Notifications type="error" message={error} /> : null}
          <BTable
            buttonCaption={t('Backoffice.AddUserButton.Label')}
            identifier={'users'}
            data={data}
            columnDefs={presavedColumns ? presavedColumns : usersColumnDefs(t)}
            buttonCallback={() => setEditOffcanvasVisible(true)}
            fetchData={fetchData}
            EditForm={EditUserForm}
            rowsSelected={rowsSelected}
            setRowsSelected={setRowsSelected}
            deleteRows={deleteRows}
            deactivateRows={deactivateRows}
            setOffcanvasVisible={setOffcanvasVisible}
            setEditOffcanvasVisible={setEditOffcanvasVisible}
            setOverviewOffcanvasVisible={setOverviewOffcanvasVisible}
            setExistingStepNumber={setExistingStepNumber}
            setDrawerMode={setDrawerMode}
            t={t}
            newPartnerId={newPartnerId}
            setNewPartnerId={setNewPartnerId}
          />
          <EditDrawer
            rowsSelected={rowsSelected}
            offcanvasVisible={editOffcanvasVisible}
            setOffcanvasVisible={setEditOffcanvasVisible}
            fetchData={fetchData}
            existingStepNumber={existingStepNumber}
            heading={t('Backoffice.layout.subpage.users')}
          >
            <UserForm
              rowsSelected={rowsSelected ?? undefined}
              rowData={rowsSelected[0] ?? undefined}
              editMode={rowsSelected.length > 0}
              fetchData={fetchData}
              setOffcanvasVisible={setEditOffcanvasVisible}
              setDeactivateOffcanvasVisible={setOffcanvasVisible}
              setOverviewOffcanvasVisible={setOverviewOffcanvasVisible}
              setIsLoading={setIsLoading}
              partners={partners}
              readOnly={false}
              deleteRows={deleteRows}
              setExistingStepNumber={setExistingStepNumber}
              setNewPartnerId={setNewPartnerId}
            />
          </EditDrawer>
          <DeactivateDrawer
            offcanvasVisible={offcanvasVisible}
            setOffcanvasVisible={setOffcanvasVisible}
            deleteRows={deleteRows}
            deactivateRows={deactivateRows}
            rowsSelected={rowsSelected}
            identifier={'users'}
            drawerMode={drawerMode}
          />
          <OverviewDrawer
            offcanvasVisible={overviewOffcanvasVisible}
            setOffcanvasVisible={setOverviewOffcanvasVisible}
            setDeactivateOffcanvasVisible={setOffcanvasVisible}
            deleteRows={deleteRows}
            rowsSelected={rowsSelected}
            setEditOffcanvasVisible={setEditOffcanvasVisible}
            existingStepNumber={existingStepNumber}
            identifier={'users'}
            heading={t('Backoffice.layout.subpage.users')}
          >
            <UserForm
              rowsSelected={rowsSelected ?? undefined}
              rowData={rowsSelected[0] ?? undefined}
              editMode={rowsSelected.length > 0}
              fetchData={fetchData}
              setOffcanvasVisible={setEditOffcanvasVisible}
              setDeactivateOffcanvasVisible={setOffcanvasVisible}
              setOverviewOffcanvasVisible={setOverviewOffcanvasVisible}
              setIsLoading={setIsLoading}
              readOnly={true}
              partners={partners}
              setExistingStepNumber={setExistingStepNumber}
            />
          </OverviewDrawer>
        </Spinner>
      </Conditional>
    </>
  )
}
