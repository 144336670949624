import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { getTableTranslation } from 'src/services/requests/tableTranslations'
import { getAllCountries } from 'src/services/requests/countries'

export const BackofficeContext = React.createContext({})
export const BackofficeContextProvider = BackofficeContext.Provider

export const PRODUCT_TABTYPE = {
  PRODUCT_DATA: 'Product Data',
  PRODUCT_ATTRIBUTES: 'Product Attributes',
  PRODUCT_DIMENSIONS: 'Product Dimensions',
  PRODUCT_ORDERUNITS: 'Product Order Units',
  PRODUCT_DOCUMENTS: 'Product Documents',
  PRODUCT_SHIPPINGUNITS: 'Product Shipping Units',
}

const BackofficeContextWrapper = (props) => {
  const [selectedProductTypeTab, setSelectedProductTypeTab] = useState(PRODUCT_TABTYPE.PRODUCT_DATA)
  const [deletedEntity, setDeletedEntity] = useState({})
  const [selectedEntity, setSeletedEntity] = useState({})
  const [updatedEntity, setUpdatedEntity] = useState([])
  const [selectedPartner, setSelectedPartner] = useState([])
  const [countries, setCountries] = useState([])
  const [workbook, setWorkbook] = useState(null)
  const [file, setFile] = useState(null)
  const [fileName, setFileName] = useState(null)
  const [errorsInProducts, setErrorsInProducts] = useState(null)
  const [tableTranslation, setTableTranslation] = useState(null)
  const [selectedBC, setSelectedBC] = useState([])
  const [selectedProject, setSelectedProject] = useState([])

  const { i18n } = useTranslation()
  const lang = i18n.language

  useEffect(() => {
    const loadEffect = async () => {
      const tableLang = await getTableTranslation(lang)
      const countries = await getAllCountries(lang)
      setCountries(
        countries?.data.map((country) => {
          return {
            label: country.value,
            value: country.code,
            // code: country.code,
          }
        }),
      )
      setTableTranslation(tableLang)
    }
    loadEffect()
  }, [lang])

  const handleDeletedEntity = (entity) => {
    setDeletedEntity(Object.assign({}, entity))
  }
  const handleSelectedEntity = (entity) => {
    setSeletedEntity(Object.assign({}, entity))
  }

  const handleUpdatedEntity = (entity) => {
    setUpdatedEntity((updatedEntity) => {
      let flag = true
      for (let count = 0; count < updatedEntity.length; count++) {
        if (entity.id === updatedEntity[count].id) {
          updatedEntity[count] = entity
          flag = false
        }
      }
      if (flag) {
        updatedEntity.push(entity)
      }
      return updatedEntity
    })
  }
  const handleSelectedPartner = (entity) => {
    setSelectedPartner(Object.assign({}, entity))
  }
  const handleWorkbook = (entity) => {
    setWorkbook(entity)
  }

  const handleFile = (file, fileName, errorsInProducts) => {
    setFile(file)
    setFileName(fileName)
    setErrorsInProducts(errorsInProducts)
  }

  const handleSelectedProductTab = (entity) => {
    setSelectedProductTypeTab(Object.assign({}, entity))
  }

  return (
    <BackofficeContextProvider
      value={{
        deletedEntity,
        handleDeletedEntity,
        selectedEntity,
        handleSelectedEntity,
        updatedEntity,
        setUpdatedEntity,
        handleUpdatedEntity,
        handleSelectedPartner,
        selectedPartner,
        handleWorkbook,
        workbook,
        handleFile,
        file,
        fileName,
        errorsInProducts,
        tableTranslation,
        handleSelectedProductTab,
        selectedProductTypeTab,
        countries,
        selectedBC,
        setSelectedBC,
        selectedProject,
        setSelectedProject,
      }}
    >
      {props.children}
    </BackofficeContextProvider>
  )
}

export default BackofficeContextWrapper
