import React, { useState } from 'react'
import { CFormInput, CRow, CCol } from '@coreui/react-pro'
import TableSettingsModal from './table/TableSettingsModal'
import { ToolbarButtons } from './table/ToolbarButtons'

export default function BackOfficeToolbar({
  handleClick,
  handleChange,
  name,
  columns,
  setColumns,
  rowsSelected,
  invertSelected,
  selectAll,
  setEditOffcanvasVisible,
  setOffcanvasVisible,
  identifier,
  setOverviewOffcanvasVisible,
  setDrawerMode,
}) {
  const [tableSettingsVisible, setTableSettingsVisible] = useState(false)

  return (
    <>
      <CRow className="backoffice-top-row align-items-center justify-content-between">
        <CCol className="col-sm-12 col-lg-3">
          <CFormInput
            floatingLabel=""
            placeholder=""
            aria-label=""
            aria-describedby="basic-addon1"
            onChange={handleChange}
          />
        </CCol>
        <CCol className="col-sm-12 col-lg-auto">
          <ToolbarButtons
            rowsSelected={rowsSelected}
            name={name}
            setTableSettingsVisible={setTableSettingsVisible}
            handleClick={handleClick}
            setOffcanvasVisible={setOffcanvasVisible}
            setEditOffcanvasVisible={setEditOffcanvasVisible}
            setOverviewOffcanvasVisible={setOverviewOffcanvasVisible}
            invertSelected={invertSelected}
            selectAll={selectAll}
            identifier={identifier}
            setDrawerMode={setDrawerMode}
          />
        </CCol>
      </CRow>
      <TableSettingsModal
        alignment="center"
        visible={tableSettingsVisible}
        backdrop="static"
        onClose={() => setTableSettingsVisible(false)}
        columns={columns}
        setColumns={setColumns}
        identifier={identifier}
      />
    </>
  )
}
