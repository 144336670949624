import React, { useContext } from 'react'
import { cilPlus } from '@coreui/icons-pro'
import { CButton, CImage } from '@coreui/react-pro'
import edit from '../../assets/images/cubotoo/edit.svg'
import invertSelection from '../../assets/images/cubotoo/invert-selection.svg'
import selectAllImg from '../../assets/images/cubotoo/select-all.svg'
import deactivate from '../../assets/images/cubotoo/deactivate.svg'
import partnerOverview from '../../assets/images/cubotoo/partner-overview.svg'
import CIcon from '@coreui/icons-react'
import customizeColumns from '../../assets/images/cubotoo/customize-columns.svg'
import { useTranslation } from 'react-i18next'
import { AuthContext, PERMISSIONS } from 'src/contexts/authContext'

export const ToolbarButtons = ({
  rowsSelected,
  name,
  handleClick,
  setTableSettingsVisible,
  setOffcanvasVisible,
  setEditOffcanvasVisible,
  setOverviewOffcanvasVisible,
  invertSelected,
  selectAll,
  identifier,
  setDrawerMode,
}) => {
  const { t } = useTranslation()
  const authContext = useContext(AuthContext)

  const getIdentifier = (_identifier) => {
    if (_identifier.includes('product')) return 'products'
    return _identifier
  }

  return (
    <div className="py-2 text-end">
      <DisplayActionButtons
        rowsSelected={rowsSelected}
        setOffcanvasVisible={setOffcanvasVisible}
        setEditOffcanvasVisible={setEditOffcanvasVisible}
        invertSelected={invertSelected}
        selectAll={selectAll}
        setOverviewOffcanvasVisible={setOverviewOffcanvasVisible}
        identifier={identifier}
        setDrawerMode={setDrawerMode}
      />
      <CButton
        className="me-3 btn-table__option"
        size="sm"
        color="transparent"
        onClick={() => {
          setTableSettingsVisible(true)
        }}
      >
        <CImage src={customizeColumns} className="p-2" />
        {t('Backoffice.Toolbar.TableSettings.Label')}
      </CButton>
      {Object.keys(PERMISSIONS[authContext.role][getIdentifier(identifier)])?.includes('ADD') && (
        <CButton
          size="sm"
          color="primary"
          onClick={handleClick}
          disabled={rowsSelected && rowsSelected.length > 0}
          className="btn-table"
        >
          <CIcon icon={cilPlus} /> {name}
        </CButton>
      )}
    </div>
  )
}

export const TableActionButton = ({ caption, icon, onClick }) => {
  const { t } = useTranslation()
  const iconClasses =
    caption === t('Backoffice.toolbar.delete.label') ||
    caption === t('Backoffice.toolbar.deactivate.label')
      ? 'deactivate__icn'
      : ''
  const captionClasses =
    caption === t('Backoffice.toolbar.delete.label') ||
    caption === t('Backoffice.toolbar.deactivate.label')
      ? 'btn-table__option--deactivate-action'
      : 'btn-table__option--cell-action'
  return (
    <CButton
      className={`me-3 btn-table__option ${captionClasses}`}
      size="sm"
      color="transparent"
      onClick={onClick}
    >
      <div className="d-inline">
        <CImage src={icon} className={`p-2 ${iconClasses}`} />
      </div>
      {caption}
    </CButton>
  )
}

const SingleRowButtons = ({
  setEditOffcanvasVisible,
  setOffcanvasVisible,
  setOverviewOffcanvasVisible,
  identifier,
  setDrawerMode,
}) => {
  const { t } = useTranslation()
  const authContext = useContext(AuthContext)
  return (
    <>
      <TableActionButton
        caption={t('Backoffice.toolbar.overview.label')}
        icon={partnerOverview}
        onClick={() => {
          setOverviewOffcanvasVisible(true)
          setEditOffcanvasVisible(false)
        }}
      />
      {Object.keys(PERMISSIONS[authContext.role][identifier])?.includes('EDIT') && (
        <TableActionButton
          caption={t('Backoffice.toolbar.edit.label')}
          icon={edit}
          onClick={() => {
            setEditOffcanvasVisible(true)
            setOverviewOffcanvasVisible(false)
          }}
        />
      )}
      {Object.keys(PERMISSIONS[authContext.role][identifier])?.includes('DEACTIVATE') && (
        <TableActionButton
          caption={t('Backoffice.toolbar.deactivate.label')}
          icon={deactivate}
          onClick={() => {
            setDrawerMode('deactivate')
            setOffcanvasVisible(true)
          }}
        />
      )}
      {Object.keys(PERMISSIONS[authContext.role][identifier])?.includes('DELETE') && (
        <TableActionButton
          caption={t('Backoffice.toolbar.delete.label')}
          icon={deactivate}
          onClick={() => {
            setDrawerMode('delete')
            setOffcanvasVisible(true)
          }}
        />
      )}
    </>
  )
}

const MultipleRowsButtons = ({
  rowsSelected,
  setOffcanvasVisible,
  invertSelected,
  selectAll,
  identifier,
  setDrawerMode,
}) => {
  const { t } = useTranslation()
  const authContext = useContext(AuthContext)
  const selectedAmount = rowsSelected && rowsSelected.length
  return (
    <>
      <TableActionButton
        caption={t('Backoffice.toolbar.invertSelection.label')}
        icon={invertSelection}
        onClick={invertSelected}
      />
      <TableActionButton
        caption={t('Backoffice.toolbar.selectAll.label')}
        icon={selectAllImg}
        onClick={selectAll}
      />
      {Object.keys(PERMISSIONS[authContext.role][identifier])?.includes('DEACTIVATE') && (
        <TableActionButton
          caption={t('Backoffice.toolbar.deactivateMultiple.label').replace('X', selectedAmount)}
          icon={deactivate}
          onClick={() => {
            setDrawerMode('deactivate')
            setOffcanvasVisible(true)
          }}
        />
      )}
      {Object.keys(PERMISSIONS[authContext.role][identifier])?.includes('DELETE') && (
        <TableActionButton
          caption={t('Backoffice.toolbar.deleteMultiple.label', { value: selectedAmount })}
          icon={deactivate}
          onClick={() => {
            setDrawerMode('delete')
            setOffcanvasVisible(true)
          }}
        />
      )}
    </>
  )
}

const DisplayActionButtons = ({
  rowsSelected,
  setOffcanvasVisible,
  setEditOffcanvasVisible,
  setOverviewOffcanvasVisible,
  invertSelected,
  selectAll,
  identifier,
  setDrawerMode,
}) => {
  const isAnyRowSelected = rowsSelected && !!rowsSelected.length
  if (!isAnyRowSelected) return null

  const areMultipleSelected = rowsSelected && rowsSelected.length > 1
  if (areMultipleSelected)
    return (
      <MultipleRowsButtons
        rowsSelected={rowsSelected}
        setOffcanvasVisible={setOffcanvasVisible}
        invertSelected={invertSelected}
        selectAll={selectAll}
        identifier={identifier}
        setDrawerMode={setDrawerMode}
      />
    )

  return (
    <SingleRowButtons
      rowsSelected={rowsSelected}
      setEditOffcanvasVisible={setEditOffcanvasVisible}
      setOffcanvasVisible={setOffcanvasVisible}
      setOverviewOffcanvasVisible={setOverviewOffcanvasVisible}
      identifier={identifier}
      setDrawerMode={setDrawerMode}
    />
  )
}
