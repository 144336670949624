import axios from 'axios'

const SwaggerClient = {
  getProductsBySearchTerm: (searchTerm, callback) => {
    callback([]) //callback will get passed an array of producst
  },
  requestBasketList: (onSuccess) => {
    const basketListUrl = 'http://localhost:3333/basketList'
    axios.get(basketListUrl).then((response) => {
      onSuccess(response)
    })
  },
}

export const ApiGateway = () => {
  return SwaggerClient
}
