import { CButton, COffcanvas, COffcanvasBody } from '@coreui/react-pro'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const UnsavedChangesDrawer = ({
  setEditOffcanvasVisible,
  setUnsavedChangesOffcanvasVisible,
  unsavedChangesOffcanvasVisible,
}) => {
  const { t } = useTranslation()

  return (
    <COffcanvas
      placement="end"
      visible={unsavedChangesOffcanvasVisible}
      backdrop={false}
      onHide={() => setUnsavedChangesOffcanvasVisible(false)}
      className="deactivate-drawer"
    >
      <COffcanvasBody className="deactivate-drawer__body d-flex flex-column justify-content-center align-items-center">
        <h2>{t('Backoffice.drawer.unsavedChanges.header')}</h2>
        <p className="text-center">{t('Backoffice.drawer.unsavedChanges.paragraph')}</p>
        <CButton
          color="danger"
          onClick={() => {
            setEditOffcanvasVisible(false)
            setUnsavedChangesOffcanvasVisible(false)
          }}
          className="text-uppercase"
        >
          {t('Backoffice.drawer.unsavedChanges.confirmButton')}
        </CButton>
        <CButton
          color="ghost"
          onClick={() => setUnsavedChangesOffcanvasVisible(false)}
          className="text-uppercase"
        >
          {t('Backoffice.drawer.unsavedChanges.denyButton')}
        </CButton>
      </COffcanvasBody>
    </COffcanvas>
  )
}
