import React from 'react'
import { CToast, CToastBody } from '@coreui/react-pro'

const notificationTypeToLevel = (type) => {
  const levelMapping = {
    success: 'success',
    info: 'info',
    warn: 'warning',
    error: 'danger',
  }
  return levelMapping[type]
}

const Notification = (props) => {
  return (
    <CToast
      visible={true}
      color={notificationTypeToLevel(props.type)}
      className="d-flex text-white align-items-center m-2"
    >
      <div className="d-flex">
        <CToastBody>{props.message}</CToastBody>
      </div>
    </CToast>
  )
}

export default Notification
