import 'src/views/pages/products/components/styles.scss'
import React, { useState, useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  CContainer,
  CRow,
  CCol,
  CAvatar,
  CCallout,
  CModal,
  CModalHeader,
  CModalBody,
  CButton,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CFormSwitch,
} from '@coreui/react-pro'

import { BackofficeContext } from 'src/contexts/backofficeContext'
import UploadButton from 'src/views/pages/products/components/UploadButton'
import { workbookProductsValidator } from '../lib/validateWoorkbookProducts'
import CIcon from '@coreui/icons-react'
import { cidCloudUpload } from '@coreui/icons-pro'
import { useTranslation } from 'react-i18next'
import * as Excel from 'exceljs'
import './styles.scss'
import { getAllManufacturerPartners } from '../../../../services/requests/partners'
import { getBranchProjects } from '../../../../services/requests/projects'
import { workbookPriceValidator } from '../lib/validateWoorkbookPrices'
import { AuthContext, ROLE } from '../../../../contexts/authContext'

let quickStyles = {
  '--cui-modal-header-border-color': 'yellow',
  '--cui-modal-header-border-width': '4px',
}
const getInitialsPartnerName = (inputString) => {
  const inputString2 = inputString.trim().replace(/\s\s+/g, ' ')
  const words = inputString2.split(' ')

  if (words.length > 1) {
    const initials = words
      .slice(0, 2)
      .map((word) => word.charAt(0))
      .join('')
    return initials.toUpperCase()
  } else {
    return inputString.slice(0, 2).toUpperCase()
  }
}

function stringToHashCode(str) {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  return hash
}

function generateColor(initials) {
  const hash = stringToHashCode(initials)
  const hue = ((hash % 360) + 360) % 360 // Ensure positive hue value
  return `hsl(${hue}, 80%, 40%)` // Adjust saturation and lightness as desired
}

export default function PartnerWidget({ partner, branches, buildingContractors, ...rest }) {
  const navigate = useNavigate()
  const { prices } = useParams()
  const [uploadModalVisible, setUploadModalVisible] = useState(false)
  const [uploadPricesModalVisible, setUploadPricesModalVisible] = useState(false)
  const [error, setError] = useState(false)
  const {
    handleSelectedPartner,
    handleWorkbook,
    handleFile,
    selectedBC,
    setSelectedBC,
    selectedProject,
    setSelectedProject,
  } = useContext(BackofficeContext)
  const { t } = useTranslation()
  const [buildingContractor, setBuildingContractor] = useState({})
  const [selectedBcBranches, setSelectedBcBranches] = useState([])
  const [projects, setProjects] = useState([])
  const [projectPrice, setProjectPrice] = useState(false)
  const auth = useContext(AuthContext)
  const [dropdownActive, setDropdownActive] = useState(false)
  const [bcFilter, setBCFilter] = useState('')

  const selectPartner = async (buildingContractor) => {
    setDropdownActive(false)
    setSelectedBcBranches([])
    setSelectedBC({})
    setProjects([])
    setSelectedProject({})
    setBuildingContractor(buildingContractor)
    const _branches = branches.filter((e) => e.parent_id === buildingContractor.id)
    if (_branches.length > 0) {
      setSelectedBcBranches(_branches)
    }
  }

  const selectBranch = async (branch) => {
    setProjects([])
    setSelectedProject({})
    setSelectedBC(branch)
    getBranchProjects(branch.id)
      .then((response) => {
        setProjects(response.data)
      })
      .catch((exception) => {
        setError(exception?.response?.data?.error)
      })
  }

  const selectProject = async (project) => {
    setSelectedProject(project)
  }

  return (
    <>
      <CCallout {...rest} className="w-100" color="secondary">
        <CRow className="justify-content-between">
          <CCol className="col-xl-9 d-xl-flex user-section">
            <CAvatar
              size="lg"
              style={{ background: generateColor(getInitialsPartnerName(partner.name)) }}
              textColor="white"
              className="col col-xl-2"
            >
              {getInitialsPartnerName(partner.name)}
            </CAvatar>
            <div className="mt-3 col-xl-10 mt-xl-0 ms-xl-3">
              <h5>
                {partner.status === 'inactive'
                  ? `${partner.name} (${t('Backoffice.Wizard.AddUser.Label.userStatus.inactive')})`
                  : partner.name}
              </h5>
              {partner.domain && (
                <div className="mt-2 partners-info-domain">
                  {t('Backoffice.PartnerWidget.Label.Domain')}:<b> {partner.domain}</b>
                </div>
              )}
              {auth.role == ROLE.CUBOTOO_ADMIN && (
                <div className="mt-2 font-size-xs partners-info-id">
                  {t('Id')}:<b> {partner.id}</b>
                </div>
              )}
            </div>
          </CCol>
          <CCol className="col-xl-3">
            <div
              className="d-flex flex-grow-0 flex-column align-items-center justify-content-center mt-3 mt-xl-0 product-upload-button"
              disabled={partner.status === 'inactive'}
              onClick={() => {
                setSelectedBcBranches([])
                setSelectedBC({})
                setProjects([])
                setSelectedProject({})
                setBuildingContractor({})
                if (prices) {
                  handleSelectedPartner(partner)
                  setUploadPricesModalVisible(true)
                } else {
                  handleSelectedPartner(partner)
                  setUploadModalVisible(true)
                }
              }}
            >
              <CIcon size="xl" icon={cidCloudUpload} />
              <span className="text-center">
                {prices
                  ? t('Backoffice.ParnterWidget.Prices.Upload.label')
                  : t('Backoffice.ParnterWidget.Upload.label')}
              </span>
            </div>
          </CCol>
        </CRow>
      </CCallout>
      <CModal
        backdrop="static"
        alignment="center"
        visible={uploadPricesModalVisible}
        onClose={() => {
          setUploadPricesModalVisible(false)
        }}
        id="modal-upload-prices"
      >
        <CModalHeader style={quickStyles}>
          {t('Backoffice.PartnerWidget.Prices.Label.UploadInfo')} {partner.name}
        </CModalHeader>
        <CModalBody>
          <div className="modal-dropdown-lg mb-4">
            <div
              className="btn btn-primary dropdown-toggle"
              onClick={() => setDropdownActive(!dropdownActive)}
            >
              {buildingContractor?.name
                ? buildingContractor.name
                : `${t('ProjectMaterialListOverview.CreateEmptyBasketModal.ChoosePartner')}`}
            </div>
            <div className={`${dropdownActive ? 'show' : ''} dropdown-menu`}>
              <div className="search-input-wrapper">
                <input
                  type="text"
                  placeholder={t('FilterSection.Search')}
                  value={bcFilter}
                  onChange={(e) => setBCFilter(e.target.value)}
                />
              </div>
              {buildingContractors
                .filter((item) => {
                  if (!bcFilter) return true
                  if (
                    item.name.toLowerCase().includes(bcFilter.toLowerCase()) ||
                    item.name.toLowerCase().includes(bcFilter.toLowerCase())
                  ) {
                    return true
                  }
                })
                .map((e) => (
                  <div
                    className="dropdown-item"
                    key={e.id}
                    data-id={e.id}
                    data-name={e.name}
                    onClick={() => selectPartner(e)}
                  >
                    {e.name}
                  </div>
                ))}
            </div>
          </div>
          <CDropdown className="modal-dropdown-lg mb-4" disabled={!buildingContractor.id}>
            <CDropdownToggle>
              {selectedBC?.name
                ? selectedBC.id === buildingContractor.id
                  ? `${selectedBC.name} (HQ)`
                  : selectedBC.name
                : `${t('ProjectMaterialListOverview.CreateEmptyBasketModal.ChooseBranch')}`}
            </CDropdownToggle>
            <CDropdownMenu component="div">
              <CDropdownItem
                component="button"
                key="partner"
                data-id={buildingContractor?.id}
                data-name={buildingContractor?.name}
                onClick={() => selectBranch(buildingContractor)}
              >
                {`${buildingContractor.name} (HQ)`}
              </CDropdownItem>
              {selectedBcBranches &&
                selectedBcBranches.map((e, index) => (
                  <CDropdownItem
                    component="button"
                    key={index}
                    data-id={e?.id}
                    data-name={e?.name}
                    onClick={() => selectBranch(e)}
                  >
                    {e.name}
                  </CDropdownItem>
                ))}
            </CDropdownMenu>
          </CDropdown>
          <CFormSwitch
            className="mt-3"
            label={t('Backoffice.PartnerWidget.Prices.Label.ProjectSpecificPrices')}
            id="formSwitchCheckProjectSpecificPrices"
            onChange={() => {
              if (projectPrice) {
                setSelectedProject({})
              }
              setProjectPrice(!projectPrice)
            }}
          />
          <CDropdown className="modal-dropdown-lg" disabled={!projectPrice || !selectedBC.id}>
            <CDropdownToggle>
              {selectedProject?.name
                ? selectedProject.name
                : `${t('ProjectMaterialListOverview.CreateEmptyBasketModal.ChooseProject')}`}
            </CDropdownToggle>
            <CDropdownMenu component="div">
              {projects &&
                projects?.map((e, index) => (
                  <CDropdownItem
                    component="button"
                    data-id={e?.id}
                    data-name={e?.name}
                    key={index}
                    onClick={() => selectProject(e)}
                  >
                    {e.name}
                  </CDropdownItem>
                ))}
            </CDropdownMenu>
          </CDropdown>
          <div className="overflow-hidden">
            <CContainer fluid>
              {!error && (
                <CRow>
                  <CCol
                    className="d-flex justify-content-center"
                    disabled={!selectedBC.id || (projectPrice && !selectedProject.id)}
                  >
                    <UploadButton
                      onResultAvailable={(reader, fileName) => {
                        new Excel.Workbook().xlsx
                          .load(reader.result)
                          .then((workbook) => {
                            console.log(partner.id, selectedBC.id, selectedProject?.id)
                            let errorsInProducts = workbookPriceValidator(
                              workbook,
                              partner.id,
                              selectedBC.id,
                              selectedProject?.id,
                              t,
                            )
                            handleWorkbook(workbook)
                            handleFile(reader.result, fileName, errorsInProducts)
                            navigate('/backoffice/upload')
                          })
                          .catch((exception) => {})
                      }}
                    />
                  </CCol>
                </CRow>
              )}
              {error && (
                <>
                  <CRow>
                    <CCol className="d-flex justify-content-center">
                      <span>{t('Backoffice.PartnerWidget.Upload.ErrorMessage')}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol className="d-flex justify-content-center">
                      <CButton
                        className="mt-3"
                        onClick={() => {
                          setUploadModalVisible(false)
                          setError(false)
                        }}
                      >
                        OK
                      </CButton>
                    </CCol>
                  </CRow>
                </>
              )}
            </CContainer>
          </div>
        </CModalBody>
      </CModal>
      <CModal
        backdrop="static"
        alignment="center"
        visible={uploadModalVisible}
        onClose={() => {
          setUploadModalVisible(false)
          setError(false)
        }}
      >
        <CModalHeader>
          {t('Backoffice.PartnerWidget.Label.UploadInfo')} {partner.name}
        </CModalHeader>
        <CModalBody>
          <div className="overflow-hidden">
            <CContainer fluid>
              {!error && (
                <CRow>
                  <CCol className="d-flex justify-content-center">
                    {prices ? (
                      <UploadButton
                        onResultAvailable={(reader, fileName) => {
                          new Excel.Workbook().xlsx
                            .load(reader.result)
                            .then((workbook) => {
                              //@Kyriakos: Add prices logic here
                            })
                            .catch((exception) => {})
                        }}
                      />
                    ) : (
                      <UploadButton
                        onResultAvailable={(reader, fileName) => {
                          new Excel.Workbook().xlsx
                            .load(reader.result)
                            .then((workbook) => {
                              let errorsInProducts = workbookProductsValidator(workbook, partner.id)
                              handleWorkbook(workbook)
                              handleFile(reader.result, fileName, errorsInProducts)
                              navigate('/backoffice/upload')
                            })
                            .catch((exception) => {
                              console.error(exception)
                              setError(true)
                            })
                        }}
                      />
                    )}
                  </CCol>
                </CRow>
              )}
              {error && (
                <>
                  <CRow>
                    <CCol className="d-flex justify-content-center">
                      <span>{t('Backoffice.PartnerWidget.Upload.ErrorMessage')}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol className="d-flex justify-content-center">
                      <CButton
                        className="mt-3"
                        onClick={() => {
                          setUploadModalVisible(false)
                          setError(false)
                        }}
                      >
                        OK
                      </CButton>
                    </CCol>
                  </CRow>
                </>
              )}
            </CContainer>
          </div>
        </CModalBody>
      </CModal>
    </>
  )
}

export { getInitialsPartnerName, generateColor }
