import { CFormSelect } from '@coreui/react-pro'
import { useField } from 'formik'
import { FormInvalidFeedback } from '../FormInvalidFeedback'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'

export const FormSelect = ({
  options,
  placeholder,
  disabled = false,
  className,
  type,
  id,
  name,
}) => {
  const { t } = useTranslation()
  if (!placeholder) placeholder = t('Placeholder.Select')
  const [field, meta] = useField({ type, id, placeholder, name })
  const isValid = !!(meta.touched && meta.error)
  const feedbackMsg = isValid ? meta.error : ''

  return (
    <div className={cn('my-3', className)}>
      <CFormSelect
        {...field}
        id={id}
        placeholder={placeholder}
        floatingLabel={placeholder}
        className={className}
        name={name}
        invalid={isValid}
        valid={meta.touched && !isValid}
        options={[{ label: '', value: '' }, ...options]}
        disabled={disabled}
      />

      {isValid && <FormInvalidFeedback feedbackMsg={feedbackMsg} />}
    </div>
  )
}
