import React, { useContext, useEffect, useState } from 'react'
import jwt from 'jwt-decode'
import { CAvatar, CDropdown } from '@coreui/react-pro'
import { AuthContext } from '../../contexts/authContext'
import OptionsPopover from '../header/Popovers/OptionsPopover'
import {
  generateColor,
  getInitialsPartnerName,
} from 'src/views/pages/products/components/PartnerWidget'

export const UserDropdown = ({ openedUserPopover, setIsOpenedUserPopover, userPopoverRef }) => {
  const auth = useContext(AuthContext)
  const [authInfo, setAuthInfo] = useState('')

  useEffect(() => {
    if (auth.authStatus === 'SignedIn') {
      if (auth.sessionInfo.idToken) {
        setAuthInfo(jwt(auth.sessionInfo.idToken))
      }
    }
  }, [])

  return (
    <CDropdown
      className="backOffice-userDropdown"
      style={{
        zIndex: 10,
      }}
    >
      <div className="login-icon choice-button" ref={userPopoverRef}>
        {authInfo.first_name ? (
          <div onClick={() => setIsOpenedUserPopover(!openedUserPopover)}>
            <CAvatar
              sx={{ width: 32, height: 32 }}
              style={{
                background: generateColor(
                  getInitialsPartnerName(`${authInfo.first_name} ${authInfo.last_name}`),
                ),
                marginRight: '8px',
              }}
              textColor="white"
            >
              {getInitialsPartnerName(`${authInfo.first_name} ${authInfo.last_name}`)}
            </CAvatar>
          </div>
        ) : (
          <div className="nav-link user-loggedIn-wrapper">
            <div className="user-loggedIn-icon">C</div>
          </div>
        )}
        <OptionsPopover
          authInfo={authInfo}
          openedUserPopover={openedUserPopover}
          setIsOpenedUserPopover={setIsOpenedUserPopover}
        />
      </div>
    </CDropdown>
  )
}
