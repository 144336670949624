import React, { useCallback, useMemo, useRef, useState, useEffect, useContext } from 'react'
import {
  TrashCellRenderer,
  BadgeCellRenderer,
  NestedRowsRenderer,
  MultipleRowsRenderer,
  OverviewRowRenderer,
  CheckBoxCellRenderer,
  HyperLinkCellRenderer,
  cellRendererParamsPartnerIDs,
  cellRendererParamsContacts,
  cellRendererParamsAddresses,
  languageGetter,
  rolesGetter,
  dobGetter,
} from 'src/components/table/CustomCellRenderer'
import { AgGridReact } from 'ag-grid-react'
import { CContainer, CCol, CRow } from '@coreui/react-pro'
import { filterAny, defaultOnValueChangedHandler, limitTable2MaxColumns } from './lib/table-lib'
import { BackofficeContext } from 'src/contexts/backofficeContext'
import BackOfficeToolbar from 'src/components/BackOfficeToolbar'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { AuthContext, PERMISSIONS } from 'src/contexts/authContext'
import { useTranslation } from 'react-i18next'

let originalData

const BTable = ({
  buttonCallback = (data) => {},
  saveButtonCallback = () => () => {},
  onCellValueChanged = (params) => {
    defaultOnValueChangedHandler(params)
  },
  height = '80vh',
  width = '80vw',
  maxColumns = 10,
  columnDefs = [],
  data = [],
  buttonCaption = 'n/a',
  resizable = true,
  filter = true,
  sortable = true,
  showSaveButtonOnDataChange = true,
  showAddButton = true,
  showSendToServerButton = false,
  sendToServerCallback = () => () => {},
  pagination = true,
  fetchData,
  rowsSelected,
  setRowsSelected,
  EditForm,
  deleteRows,
  deactivateRows,
  setOffcanvasVisible,
  setEditOffcanvasVisible,
  setExistingStepNumber,
  identifier,
  setOverviewOffcanvasVisible,
  setDrawerMode,
  t,
  newPartnerId,
  setNewPartnerId,
  ...rest
}) => {
  const gridRef = useRef()
  const gridContainerRef = useRef()
  const [rowData, setRowData] = useState()
  const [columns, setColumns] = useState([])
  const [changeSet, setChangeSet] = useState(new Map())
  const [style, setStyle] = useState({
    height: identifier === 'partners' ? '95%' : '100%',
    width: '100%',
  })
  const authContext = useContext(AuthContext)
  const { handleUpdatedEntity, tableTranslation, countries } = useContext(BackofficeContext)
  const [isDestroyed, setIsDestroyed] = useState(false)

  const invertSelected = () => {
    gridRef.current.api.forEachNode((node) => node.setSelected(!node.selected))
  }

  const selectAll = () => {
    gridRef.current.api.forEachNode((node) => node.setSelected(true))
  }

  const cellRendererLookup = {
    TrashCellRenderer,
    BadgeCellRenderer,
    NestedRowsRenderer,
    MultipleRowsRenderer,
    OverviewRowRenderer,
    CheckBoxCellRenderer,
    HyperLinkCellRenderer,
    cellRendererParamsPartnerIDs,
    cellRendererParamsContacts,
    cellRendererParamsAddresses,
    languageGetter,
    rolesGetter,
    dobGetter,
    t,
  }

  useEffect(() => {
    const destroyGrid = () => {
      setIsDestroyed(true)
      setTimeout(() => recreateGrid(), 0)
    }

    const recreateGrid = () => {
      setIsDestroyed(false)
    }

    destroyGrid()
  }, [tableTranslation])

  const defaultColDef = useMemo(() => {
    return {
      resizable: resizable,
      filter: filter,
      sortable: sortable,
      maxWidth: 600,
      minWidth: 100,
    }
  }, [isDestroyed])

  const _onCellValueChanged = useCallback(
    (params) => {
      if (params.data.id === undefined) throw 'record does not contain an id'
      handleUpdatedEntity(params.data)
      onCellValueChanged(params)
      changeSet.set(params.data.id, params.data)
      setChangeSet(new Map(changeSet))
    },
    [isDestroyed],
  )

  useEffect(() => {
    if (isDestroyed) {
      return
    }
    originalData = data

    //we need to map the cellRenderer, cellRendererParams & valueGetter to the actual functions
    for (let i = 0; i < columnDefs.length; i++) {
      if (columnDefs[i].cellRenderer) {
        columnDefs[i].cellRenderer = cellRendererLookup[columnDefs[i].cellRenderer]
      }
      if (columnDefs[i].cellRendererParams) {
        columnDefs[i].cellRendererParams = cellRendererLookup[columnDefs[i].cellRendererParams]
      }
      if (columnDefs[i].valueGetter) {
        columnDefs[i].valueGetter = cellRendererLookup[columnDefs[i].valueGetter]
      }
      if (!columnDefs[i].headerKey) {
        columnDefs[i].headerKey = columnDefs[i].headerName
      }
      columnDefs[i].headerName = t(columnDefs[i].headerKey)
      console.log()
    }
    // if (!PERMISSIONS[authContext.role][identifier]?.includes('DELETE')) columnDefs.pop()
    // we need to limit the number of columns to maxColumns if table columns are not stored in local storage
    if (!localStorage.getItem(`${authContext.role}-${identifier}-columns`)) {
      if (columnDefs && columnDefs.length > 0) {
        columnDefs = limitTable2MaxColumns(columnDefs, maxColumns)
      }
    }
    setColumns(columnDefs)
  }, [data, isDestroyed])

  useEffect(() => {
    if (columns && columns.length > 0) {
      if (gridRef.current?.api) {
        gridRef.current.api.setColumnDefs(columns)
        if (
          gridRef.current?.columnApi?.getColumnState().find((item) => item.colId == 'countryName')
        ) {
          data = data.map((obj) => {
            return {
              ...obj,
              address: {
                ...obj.address,
                countryName: countries?.find((country) => country?.value === obj.address?.country)
                  ?.label,
              },
              countryName: countries?.find((country) => country?.value === obj.country)?.label,
            }
          })
          setRowData(data)
        } else {
          setRowData(data)
        }
      }
    }
  }, [columns, isDestroyed])

  const handleUserInput = (data, value) => {
    setRowData(filterAny(data, value))
  }

  const getRowId = useMemo(() => (params) => params.data.id, [])

  const getRowStyle = (params) => {
    const { data } = params
    if (newPartnerId && (data.name === newPartnerId || data.email === newPartnerId)) {
      return { background: '#ffffdd' }
    }
    return null
  }

  const autoSizeAll = useCallback(() => {
    gridRef.current?.columnApi?.autoSizeAllColumns()

    const columnsWidth = gridRef.current?.columnApi
      ?.getColumnState()
      .filter((column) => column.hide === false)
      .reduce((totalWidth, column) => column.width + totalWidth, 0)

    const gridWidth = gridContainerRef.current?.offsetWidth

    if (gridWidth > columnsWidth) gridRef.current?.columnApi.sizeColumnsToFit(gridWidth)
  }, [])

  return (
    !isDestroyed && (
      <CContainer fluid>
        <CRow>
          <CCol xs={12}>
            <BackOfficeToolbar
              name={buttonCaption}
              handleChange={(e) => {
                handleUserInput(originalData, e.target.value)
              }}
              handleClick={buttonCallback}
              showSaveButton={changeSet.size > 0 && showSaveButtonOnDataChange}
              showAddButton={showAddButton}
              showSendToServerButton={showSendToServerButton}
              handleSendToServer={() => sendToServerCallback()}
              columns={columns}
              setColumns={setColumns}
              rowsSelected={rowsSelected}
              invertSelected={invertSelected}
              selectAll={selectAll}
              deleteRows={deleteRows}
              deactivateRows={deactivateRows}
              fetchData={fetchData}
              setEditOffcanvasVisible={setEditOffcanvasVisible}
              setOffcanvasVisible={setOffcanvasVisible}
              identifier={identifier}
              setOverviewOffcanvasVisible={setOverviewOffcanvasVisible}
              setDrawerMode={setDrawerMode}
            />
          </CCol>

          <CCol xs={12}>
            <div className="d-flex justify-content-center align-items-center pt-1">
              <div className="ag-theme-alpine">
                <div style={style} ref={gridContainerRef}>
                  <AgGridReact
                    onModelUpdated={() => setRowsSelected(gridRef.current.api.getSelectedRows())}
                    getRowId={getRowId}
                    ref={gridRef}
                    rowData={rowData}
                    getRowStyle={getRowStyle}
                    columnDefs={columns}
                    defaultColDef={defaultColDef}
                    onCellValueChanged={_onCellValueChanged}
                    pagination={pagination}
                    localeText={tableTranslation}
                    onFirstDataRendered={autoSizeAll}
                    suppressColumnVirtualisation={true}
                    rowSelection={'multiple'}
                    onSelectionChanged={() => {
                      setRowsSelected(gridRef.current.api.getSelectedRows())
                    }}
                    {...rest}
                    context={{
                      identifier: identifier,
                      setEditOffcanvasVisible,
                      setOverviewOffcanvasVisible,
                      setExistingStepNumber,
                      setDrawerMode,
                      setOffcanvasVisible,
                    }}
                  ></AgGridReact>
                </div>
              </div>
            </div>
          </CCol>
        </CRow>
      </CContainer>
    )
  )
}
export default BTable
