import {
  CButton,
  CCloseButton,
  CImage,
  COffcanvas,
  COffcanvasBody,
  COffcanvasHeader,
  COffcanvasTitle,
} from '@coreui/react-pro'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import deactivate from '../../assets/images/cubotoo/deactivate.svg'
import edit from '../../assets/images/cubotoo/edit.svg'
import { AuthContext, PERMISSIONS } from 'src/contexts/authContext'

export const OverviewDrawer = ({
  children,
  rowsSelected,
  offcanvasVisible,
  setOffcanvasVisible,
  setDeactivateOffcanvasVisible,
  setEditOffcanvasVisible,
  existingStepNumber,
  identifier,
  heading,
}) => {
  const { t } = useTranslation()
  const authContext = useContext(AuthContext)

  const rowData = rowsSelected[0]

  const rowName = rowData?.name || rowData?.first_name + ' ' + rowData?.last_name

  const cancelEditing = () => {
    setOffcanvasVisible(false)
  }

  if (!rowData) return
  return (
    <COffcanvas
      placement="end"
      visible={offcanvasVisible}
      backdrop={false}
      onHide={() => setOffcanvasVisible(false)}
      className="deactivate-drawer"
    >
      <COffcanvasHeader className="justify-content-between align-items-stretch">
        <COffcanvasTitle component="div">
          <p className="edit-drawer__header-type m-0 text-capitalize">{heading}:</p>
          <h4>{rowName}</h4>
        </COffcanvasTitle>
        <div className="d-flex flex-column justify-content-between align-items-end">
          <CCloseButton className="text-reset" onClick={cancelEditing} />
          {Object.keys(PERMISSIONS[authContext.role][identifier])?.includes('EDIT') && (
            <CButton
              className="overview-drawer-button__option overview-drawer-button__option--cell-action"
              size="sm"
              color="transparent"
              onClick={() => {
                setOffcanvasVisible(false)
                setEditOffcanvasVisible(true)
              }}
            >
              <div className="d-inline">
                <CImage src={edit} className="p-2" />
              </div>
              {t('Backoffice.toolbar.edit.label')}
            </CButton>
          )}
        </div>
      </COffcanvasHeader>
      <COffcanvasBody className="drawer__offcanvas-sticky">
        <>
          {React.cloneElement(children, {
            rowData,
            existingStepNumber,
          })}
          {Object.keys(PERMISSIONS[authContext.role][identifier])?.includes('DEACTIVATE') && (
            <div className="d-flex align-items-center justify-content-between drawer__footer">
              <CButton
                className="btn-table__option btn-table__option--deactivate-action text-uppercase "
                size="sm"
                color="transparent"
                onClick={() => {
                  setOffcanvasVisible(false)
                  setDeactivateOffcanvasVisible(true)
                }}
              >
                <div className="d-inline">
                  <CImage src={deactivate} className="p-2 text-uppercase deactivate__icn" />
                </div>
                {t('Backoffice.toolbar.deactivate.label')}
              </CButton>
            </div>
          )}
        </>
      </COffcanvasBody>
    </COffcanvas>
  )
}
