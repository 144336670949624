import React, { useState, useContext, useEffect } from 'react'
import { CContainer } from '@coreui/react-pro'
import { Outlet } from 'react-router-dom'
import BackOfficeHeader from './BackOfficeHeader'
import BackOfficeSidebar from './BackOfficeSidebar'
import BackofficeContextWrapper from 'src/contexts/backofficeContext'
import { AuthContext } from '../../contexts/authContext'
import { GlobalVariablesContext } from '../../contexts/globalVariablesContext'
import ChangePassword from 'src/components/auth/modals/ChangePassword'

export default function BackOfficeLayout() {
  const [sidebarVisible, setSidebarVisible] = useState(true)
  const authContext = useContext(AuthContext)
  const { changePasswordModalVisible } = useContext(GlobalVariablesContext)
  const toggleSidebar = () => {
    setSidebarVisible((prev) => !prev)
  }

  useEffect(() => {
    if (authContext.authStatus === 'SignedOut') {
      authContext.signOut()
    }
  }, [])

  return (
    <section className="d-flex">
      <BackofficeContextWrapper>
        <BackOfficeSidebar sidebarVisible={sidebarVisible} />
        <CContainer fluid className="position-relative p-0">
          <BackOfficeHeader toggleSidebar={toggleSidebar} />
          <Outlet />
          {changePasswordModalVisible && (
            <ChangePassword modalVisibility={changePasswordModalVisible} />
          )}
        </CContainer>
      </BackofficeContextWrapper>
    </section>
  )
}
