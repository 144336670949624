import * as Yup from 'yup'
import Wizard, { WizardStep } from 'src/components/forms/Wizard'
import { FormTextInput } from 'src/components/forms/FormTextInput'
import { FormSelectInput } from 'src/components/forms/FormSelectInput'
import { FormCheckInput } from 'src/components/forms/FormCheckInput'
import React, { useContext } from 'react'
import { AuthContext, ROLE } from 'src/contexts/authContext'
import { UserDataContext } from 'src/contexts/userDataContext'
import { useTranslation } from 'react-i18next'
import FormSelectMultiplePartnerRoles, {
  partnerRolesValues,
} from 'src/components/forms/selects/FormSelectMultiplePartnerRoles'
import { FormDatePicker } from 'src/components/forms/FormDatePicker'
import { updatePerson, createPerson } from 'src/services/requests/users'
import { CCol, CRow } from '@coreui/react-pro'
import { FormLabel } from 'src/components/forms/FormLabel'

const Validator = require('jsonschema').Validator
const validator = new Validator()

export const UserForm = ({
  editMode,
  rowData,
  fetchData,
  setOffcanvasVisible,
  setDeactivateOffcanvasVisible,
  setIsLoading,
  unsavedChangesOffcanvasVisible,
  setUnsavedChangesOffcanvasVisible,
  initialValuesChanged,
  setInitialvaluesChanged,
  readOnly,
  partners,
  setExistingStepNumber,
  setNewPartnerId,
}) => {
  const authContext = useContext(AuthContext)
  const { userData } = useContext(UserDataContext)
  const { t } = useTranslation()

  const emailDomain = '@' + userData.email.split('@')[1]
  const userPermissions = [authContext.role]
  const isAdmin = userPermissions.includes(
    ROLE.CUBOTOO_ADMIN,
    ROLE.BUILDING_CONTRACTOR_ADMIN,
    ROLE.MANUFACTURER_ADMIN,
  )

  const prepopulateData = editMode || readOnly
  const initialValues = {
    id: prepopulateData ? rowData?.id : undefined,
    firstName: prepopulateData ? rowData.first_name : '',
    lastName: prepopulateData ? rowData.last_name : '',
    email: prepopulateData ? rowData.email : '',
    phone: prepopulateData ? rowData.phone : '',
    active: prepopulateData ? rowData.active : 'active',
    dateOfBirth: prepopulateData ? rowData.date_of_birth : '',
    language: prepopulateData ? rowData.language : 'de',
    partnerRoles: prepopulateData
      ? [
          ...rowData.partner_roles.map((role) => {
            return {
              buyLimit: role.buy_limit || '',
              partnerId: role.partner_id,
              currency: 'CHF',
              role: role.role,
              partnerType: partners.find((partner) => partner.id === role.partner_id)?.type,
            }
          }),
        ]
      : [{ ...partnerRolesValues }],
  }
  const handleSubmit = async (values) => {
    const body = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      phone: values.phone || undefined,
      date_of_birth: values.dateOfBirth || undefined,
      active: values.active,
      language: values.language,
    }

    if (values.partnerRoles && values.partnerRoles.length > 0) {
      body.partner_roles = values.partnerRoles.map((partner) => {
        return {
          buy_limit: partner.buyLimit || undefined,
          partner_id: partner.partnerId,
          currency: partner.currency,
          role: partner.role,
        }
      })
    }

    try {
      setIsLoading(true)
      if (editMode) {
        await updatePerson(rowData.id, JSON.stringify(body))
      } else {
        await createPerson(JSON.stringify(body))
        setNewPartnerId(body.email)
      }
      await fetchData()
      setOffcanvasVisible(false)
    } catch (error) {
      setIsLoading(false)
    }
    setIsLoading(false)
  }
  const formSchema = {
    first: {
      step: 'first',
      fields: ['firstName', 'lastName', 'email', 'phone', 'dateOfBirth', 'active', 'language'],
    },
    second: {
      step: 'second',
      fields: ['partnerRoles', 'partnerId', 'role', 'buyLimit', 'currency'],
    },
  }
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(t('Yup.validation.error.required')),
    lastName: Yup.string().required(t('Yup.validation.error.required')),
    email: Yup.string()
      .required(t('Yup.validation.error.required'))
      .email(t('Yup.validation.error.invalid.email'))
      .when([], {
        is: () => !isAdmin,
        then: Yup.string().matches(
          new RegExp(emailDomain),
          t('Yup.validation.error.invalid.domainEmail'),
        ),
      }),
    phone: Yup.string()
      .min(10, t('Yup.validation.error.invalid.phone.minLength'))
      .max(20, t('Yup.validation.error.invalid.phone.maxLength'))
      .nullable(),
    active: Yup.string().required(t('Yup.validation.error.required')),
    language: Yup.string()
      .required(t('Yup.validation.error.required'))
      .min(2, t('Yup.validation.error.language.length'))
      .max(2, t('Yup.validation.error.language.length')),
    dateOfBirth: Yup.string().typeError(t('Yup.validation.error.invalid.date')).nullable(),
    partnerRoles: Yup.array().of(
      Yup.object().shape({
        partnerId: Yup.string().required(t('Yup.validation.error.required')),
        role: Yup.string().required(t('Yup.validation.error.required')),
        buyLimit: Yup.number(t('Yup.validation.error.invalid.number')).positive(
          t('Yup.validation.error.invalid.positiv.number'),
        ),
        currency: Yup.string().required(t('Yup.validation.error.required')),
      }),
    ),
  })

  return (
    <Wizard
      editMode={editMode}
      readOnly={readOnly}
      initialValues={initialValues}
      setOffcanvasVisible={setOffcanvasVisible}
      setDeactivateOffcanvasVisible={setDeactivateOffcanvasVisible}
      setInitialvaluesChanged={setInitialvaluesChanged}
      initialValuesChanged={initialValuesChanged}
      unsavedChangesOffcanvasVisible={unsavedChangesOffcanvasVisible}
      setUnsavedChangesOffcanvasVisible={setUnsavedChangesOffcanvasVisible}
      validationSchema={validationSchema}
      setExistingStepNumber={setExistingStepNumber}
      allFormSchema={{
        first: formSchema.first,
        second: formSchema.second,
      }}
      onSubmit={(values) => handleSubmit(values)}
      stepsProperties={[
        { id: 'first', label: t('Backoffice.Wizard.AddUser.Step.userData') },
        { id: 'second', label: t('Backoffice.Wizard.AddUser.Step.partners') },
      ]}
      identifier={'users'}
    >
      <WizardStep>
        <FormTextInput
          placeholder={t('Backoffice.Wizard.AddUser.Label.FirstName')}
          type="text"
          id="firstName"
          name="firstName"
          disabled={readOnly}
        />
        <FormTextInput
          placeholder={t('Backoffice.Wizard.AddUser.Label.LastName')}
          type="text"
          id="lastName"
          name="lastName"
          disabled={readOnly}
        />
        <FormTextInput
          placeholder={t('Backoffice.Wizard.AddUser.Label.Email')}
          className="mb-2"
          type="text"
          id="email"
          name="email"
          disabled={readOnly}
        />
        <FormTextInput
          placeholder={t('Backoffice.Wizard.AddUser.Label.Phone')}
          className="mb-2"
          type="text"
          id="phone"
          name="phone"
          disabled={readOnly}
        />

        <FormDatePicker
          placeholder={t('Backoffice.Wizard.AddUser.Label.dateOfBirth')}
          locale="de-DE"
          maxDate={new Date()}
          id="dateOfBirth"
          name="dateOfBirth"
          disabled={readOnly}
          setInitialvaluesChanged={setInitialvaluesChanged}
          inputReadOnly={true}
        />

        <CCol md="12">
          <CRow>
            <FormLabel name={t('Backoffice.Wizard.AddUser.Label.userStatus')} />
          </CRow>
          <CRow>
            <CCol md="4">
              <FormCheckInput
                label={t('Backoffice.Wizard.AddUser.Label.userStatus.active')}
                value="active"
                type="radio"
                id="active"
                name="active"
                disabled={readOnly}
              />
            </CCol>
            <CCol md="4">
              <FormCheckInput
                label={t('Backoffice.Wizard.AddUser.Label.userStatus.inactive')}
                value="inactive"
                type="radio"
                id="active"
                name="active"
                disabled={readOnly}
              />
            </CCol>
          </CRow>
        </CCol>

        <FormSelectInput
          placeholder={t('Backoffice.Wizard.AddUser.Label.language')}
          className="mb-2 form-control-backoffice"
          type="text"
          id="language"
          name="language"
          disabled={readOnly}
          options={[
            { label: t('Global.Language.English'), value: 'en' },
            { label: t('Global.Language.German'), value: 'de' },
            { label: t('Global.Language.French'), value: 'fr' },
            { label: t('Global.Language.Italian'), value: 'it' },
          ]}
        />
      </WizardStep>
      <WizardStep>
        <FormSelectMultiplePartnerRoles partners={partners} readOnly={readOnly} />
      </WizardStep>
    </Wizard>
  )
}
