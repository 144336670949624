import React, { useContext } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { TABTYPE, useBackofficeParams } from 'src/hooks/useBackofficeParams'
import { AuthContext, ROLE } from '../../contexts/authContext'

const partnerTypesPermissions = [
  { type: TABTYPE.BUILDINGCONTRACTORS, role: ROLE.BUILDING_CONTRACTOR_ADMIN },
  { type: TABTYPE.MANUFACTURERS, role: ROLE.MANUFACTURER_ADMIN },
]

export const ProtectedPartnerRoute = () => {
  const { partnerType } = useBackofficeParams()
  const auth = useContext(AuthContext)
  const userPermissions = [auth.role]
  if (userPermissions.length === 0) {
    return <Navigate to="/signin" replace state={{ from: location }} />
  }

  const isAdmin = userPermissions.find((role) => role === ROLE.CUBOTOO_ADMIN)
  const partner = partnerTypesPermissions.find((partner) => partner.type === partnerType)
  const isPermitted = isAdmin || userPermissions.some((permission) => permission === partner.role)

  const location = useLocation()
  if (!isPermitted) {
    return <Navigate to="/" replace state={{ from: location }} />
  }
  return <Outlet />
}
