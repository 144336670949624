import React, { useState, useEffect, useContext } from 'react'
import uuid from 'react-uuid'
import BTable from 'src/components/table/Table'
import {
  CContainer,
  CRow,
  CCol,
  CNav,
  CNavItem,
  CButton,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CModalFooter,
} from '@coreui/react-pro'
import { useNavigate } from 'react-router-dom'
import RouterNavLink from 'src/components/RouterNavLink'
import { sheet2ColumnDef, readSheedData, getSheetByName } from 'src/components/excel/upload'
import { BackofficeContext, PRODUCT_TABTYPE } from 'src/contexts/backofficeContext'
import {
  getPriceUploadPreSignedURL,
  getProductUploadPreSignedURL,
} from 'src/services/requests/products'
import { useTranslation } from 'react-i18next'
import { uploadProductFileToPreSignedURL } from '../../../services/requests/uploads'
import Notifications from '../../../components/shared/notifications/NotificationsToaster'
import { Spinner } from 'src/components/Spinner'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import { getLegalNoticeProductUpload } from 'src/services/requests/strapi/legalNoticeProductUpload'

export default function ProductUpload() {
  const navigate = useNavigate()
  const [columnDefs, setColumnDefs] = useState([])
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [currentTab, setCurrentTab] = useState('products')
  const [isLoading, setIsLoading] = useState(false)
  const [errorModalVisible, setErrorModalVisible] = useState(false)
  const [uploadModalVisible, setUploadModalVisible] = useState(false)
  const [legalNoticeProductUpload, setLegalNoticeProductUpload] = useState('')
  const {
    handleSelectedProductTab,
    selectedProductTypeTab,
    workbook,
    file,
    fileName,
    errorsInProducts,
    selectedPartner,
    selectedBC,
    selectedProject,
  } = useContext(BackofficeContext)
  const [data, setData] = useState([])
  const { t, i18n } = useTranslation()
  const lang = i18n.language

  const handleTabChange = async (tabName) => {
    setData([])
    if (workbook) {
      sheet2ColumnDef(workbook, tabName, selectedBC?.id).then((columnDefs) => {
        setColumnDefs(columnDefs)
        setCurrentTab(tabName)
        const dataWithIds = readSheedData(
          getSheetByName(workbook, tabName),
          columnDefs,
          selectedBC?.id,
        ).map((row) => ({
          id: uuid(),
          ...row,
        }))
        setData(dataWithIds)
      })
    }
  }

  const fetchData = () => {
    if (workbook) {
      let sheetName = selectedBC?.id ? 'price_agreement' : 'products'
      sheet2ColumnDef(workbook, sheetName, selectedBC?.id).then((columnDefs) => {
        setColumnDefs(columnDefs)
        const dataWithIds = readSheedData(
          getSheetByName(workbook, sheetName),
          columnDefs,
          selectedBC?.id,
        ).map((row) => ({
          id: uuid(),
          ...row,
        }))
        setData(dataWithIds)
      })
    }
  }

  useEffect(() => {
    getLegalNoticeProductUpload(lang).then((response) => {
      setLegalNoticeProductUpload(response.data.data.attributes.legal_notice_text)
    })
  }, [lang])

  const sendToServer = () => {
    setIsLoading(true)
    if (selectedBC?.id) {
      getPriceUploadPreSignedURL(fileName, selectedPartner.id, selectedBC?.id, selectedProject?.id)
        .then((results) => uploadProductFileToPreSignedURL(results.data.preSignedUrl, file))
        .then((results) => {
          setIsLoading(false)
          setUploadModalVisible(false)
          setSuccess(t('Backoffice.ProductManagement.Label.FileUploaded'))
          setTimeout(() => {
            setSuccess(false)
          }, '3000')
        })
        .catch((error) => {
          setIsLoading(false)
          setError(error.message)
          setTimeout(() => {
            setError(false)
          }, '3000')
        })
    } else {
      getProductUploadPreSignedURL(fileName, selectedPartner.id)
        .then((results) => uploadProductFileToPreSignedURL(results.data.preSignedUrl, file))
        .then((results) => {
          setIsLoading(false)
          setUploadModalVisible(false)
          setSuccess(t('Backoffice.ProductManagement.Label.FileUploaded'))
          setTimeout(() => {
            setSuccess(false)
          }, '3000')
        })
        .catch((error) => {
          setIsLoading(false)
          setError(error.message)
          setTimeout(() => {
            setError(false)
          }, '3000')
        })
    }
  }

  useEffect(() => {
    const loadEffect = async () => {
      setData([])
      fetchData()
      if (
        Object.keys(errorsInProducts.invalidProducts).length > 0 ||
        (!selectedBC?.id &&
          (errorsInProducts.missingProducts.product_documents.length > 0 ||
            errorsInProducts.missingProducts.product_attributes.length > 0 ||
            errorsInProducts.missingProducts.product_dimensions.length > 0 ||
            errorsInProducts.missingProducts.product_ordering_units.length > 0 ||
            errorsInProducts.missingProducts.product_shipping_units.length > 0))
      ) {
        setErrorModalVisible(true)
      }
    }
    loadEffect()
  }, [workbook])

  return (
    <div className="center-content--wrapper">
      <Spinner
        message={t('Backoffice.ProductManagement.Label.UploadingFile')}
        pageloading={isLoading}
      >
        {error ? <Notifications type="error" message={error} /> : null}
        {success ? <Notifications type="success" message={success} /> : null}
        <CContainer fluid>
          <CRow>
            {selectedBC?.id ? (
              <CNav variant="underline">
                <CNavItem>
                  <RouterNavLink
                    to={''}
                    active={selectedProductTypeTab === 'price_agreement'}
                    onClick={() => {}}
                  >
                    {t('Backoffice.ParnterWidget.Prices.Upload.label')}
                  </RouterNavLink>
                </CNavItem>
              </CNav>
            ) : (
              <CNav variant="underline">
                <CNavItem>
                  <RouterNavLink
                    to={''}
                    active={selectedProductTypeTab === PRODUCT_TABTYPE.PRODUCT_DATA}
                    onClick={() => {
                      setData([])
                      handleSelectedProductTab(PRODUCT_TABTYPE.PRODUCT_DATA)
                      handleTabChange('products')
                    }}
                  >
                    {t('Backoffice.ProductUpload.Label.Productdata')}
                  </RouterNavLink>
                </CNavItem>
                <CNavItem>
                  <RouterNavLink
                    to={''}
                    active={selectedProductTypeTab === PRODUCT_TABTYPE.PRODUCT_DOCUMENTS}
                    onClick={() => {
                      setData([])
                      handleSelectedProductTab(PRODUCT_TABTYPE.PRODUCT_DOCUMENTS)
                      handleTabChange('product_documents')
                    }}
                  >
                    {t('Backoffice.ProductUpload.Label.Productdocuments')}
                  </RouterNavLink>
                </CNavItem>
                <CNavItem>
                  <RouterNavLink
                    to={''}
                    active={selectedProductTypeTab === PRODUCT_TABTYPE.PRODUCT_DIMENSIONS}
                    onClick={() => {
                      setData([])
                      handleSelectedProductTab(PRODUCT_TABTYPE.PRODUCT_DIMENSIONS)
                      handleTabChange('product_dimensions')
                    }}
                  >
                    {t('Backoffice.ProductUpload.Label.Productdimension')}
                  </RouterNavLink>
                </CNavItem>
                <CNavItem>
                  <RouterNavLink
                    to={''}
                    active={selectedProductTypeTab === PRODUCT_TABTYPE.PRODUCT_ATTRIBUTES}
                    onClick={() => {
                      setData([])
                      handleSelectedProductTab(PRODUCT_TABTYPE.PRODUCT_ATTRIBUTES)
                      handleTabChange('product_attributes')
                    }}
                  >
                    {t('Backoffice.ProductUpload.Label.Productattributes')}
                  </RouterNavLink>
                </CNavItem>
                <CNavItem>
                  <RouterNavLink
                    to={''}
                    active={selectedProductTypeTab === PRODUCT_TABTYPE.PRODUCT_ORDERUNITS}
                    onClick={() => {
                      setData([])
                      handleSelectedProductTab(PRODUCT_TABTYPE.PRODUCT_ORDERUNITS)
                      handleTabChange('product_ordering_units')
                    }}
                  >
                    {t('Backoffice.ProductUpload.Label.OrderingUnits')}
                  </RouterNavLink>
                </CNavItem>
                <CNavItem>
                  <RouterNavLink
                    to={''}
                    active={selectedProductTypeTab === PRODUCT_TABTYPE.PRODUCT_SHIPPINGUNITS}
                    onClick={() => {
                      setData([])
                      handleSelectedProductTab(PRODUCT_TABTYPE.PRODUCT_SHIPPINGUNITS)
                      handleTabChange('product_shipping_units')
                    }}
                  >
                    {t('Backoffice.ProductUpload.Label.ShippingUnits')}
                  </RouterNavLink>
                </CNavItem>
              </CNav>
            )}
          </CRow>

          <CRow>
            <CCol>
              <div className="mt-4">
                <BTable
                  height={'80vh'}
                  width={'80vw'}
                  maxColumns={100}
                  buttonCaption={t('Backoffice.Toolbar.SendToServerButton.Label')}
                  data={data}
                  columnDefs={columnDefs}
                  setRowsSelected={() => {}}
                  buttonCallback={() => setUploadModalVisible(true)}
                  saveButtonCallback={() => {}}
                  showAddButton={false}
                  showSendToServerButton={!errorModalVisible}
                  identifier={currentTab}
                  sendToServerCallback={() => {
                    sendToServer()
                  }}
                  alwaysShowHorizontalScroll={true}
                  t={t}
                />
              </div>
            </CCol>
          </CRow>
        </CContainer>

        <CModal
          backdrop="static"
          alignment="center"
          visible={errorModalVisible}
          onClose={() => {
            setErrorModalVisible(false)
            if (selectedBC?.id) {
              navigate('/backoffice/products/prices')
            } else {
              navigate('/backoffice/products')
            }
          }}
        >
          <CModalHeader>{t('Backoffice.ProductAndPriceManagement.ErrorModalLabel')}</CModalHeader>
          <CModalBody>
            <CContainer fluid>
              <CRow>
                <CCol>
                  {errorsInProducts.invalidProducts &&
                    Object.keys(errorsInProducts.invalidProducts).length > 0 &&
                    Object.values(errorsInProducts.invalidProducts).map((value, index) => {
                      if (selectedBC?.id) {
                        return (
                          <div key={index}>
                            <h5 key={index} className="mb-2">
                              {value.product}
                            </h5>
                            <p key={index} className="mb-2">
                              {value.error}
                            </p>
                          </div>
                        )
                      } else {
                        return (
                          <div key={index}>
                            {value.map((val, index) => {
                              return (
                                <p key={index} className="mb-2">
                                  {val.stack}
                                </p>
                              )
                            })}
                          </div>
                        )
                      }
                    })}

                  {errorsInProducts.missingProducts &&
                    errorsInProducts.missingProducts.product_documents?.length > 0 &&
                    errorsInProducts.missingProducts.product_documents.map((val, index) => {
                      return (
                        <p key={index} className="mb-2">
                          {t('Products.ProductUpload.DocumentsErrorMsg', {
                            value: val.ARTICLE_NUMBER,
                          })}
                        </p>
                      )
                    })}

                  {errorsInProducts.missingProducts &&
                    errorsInProducts.missingProducts.product_attributes?.length > 0 &&
                    errorsInProducts.missingProducts.product_attributes.map((val, index) => {
                      return (
                        <p key={index} className="mb-2">
                          {t('Products.ProductUpload.AttributesErrorMsg', {
                            value: val.ARTICLE_NUMBER,
                          })}
                        </p>
                      )
                    })}

                  {errorsInProducts.missingProducts &&
                    errorsInProducts.missingProducts.product_dimensions?.length > 0 &&
                    errorsInProducts.missingProducts.product_dimensions.map((val, index) => {
                      return (
                        <p key={index} className="mb-2">
                          {t('Products.ProductUpload.DimensionsErrorMsg', {
                            value: val.ARTICLE_NUMBER,
                          })}
                        </p>
                      )
                    })}

                  {errorsInProducts.missingProducts &&
                    errorsInProducts.missingProducts.product_ordering_units?.length > 0 &&
                    errorsInProducts.missingProducts.product_ordering_units.map((val, index) => {
                      return (
                        <p key={index} className="mb-2">
                          {t('Products.ProductUpload.OrderingUnitsErrorMsg', {
                            value: val.ARTICLE_NUMBER,
                          })}
                        </p>
                      )
                    })}

                  {errorsInProducts.missingProducts &&
                    errorsInProducts.missingProducts.product_shipping_units?.length > 0 &&
                    errorsInProducts.missingProducts.product_shipping_units.map((val, index) => {
                      return (
                        <p key={index} className="mb-2">
                          {t('Products.ProductUpload.ShippingUnitsErrorMsg', {
                            value: val.ARTICLE_NUMBER,
                          })}
                        </p>
                      )
                    })}

                  <div className="text-center">
                    <CButton
                      onClick={() => {
                        if (selectedBC?.id) {
                          navigate('/backoffice/products/prices')
                        } else {
                          navigate('/backoffice/products')
                        }
                      }}
                    >
                      {t('Products.ProductUpload.Back')}
                    </CButton>
                  </div>
                </CCol>
              </CRow>
            </CContainer>
          </CModalBody>
        </CModal>
        <CModal visible={uploadModalVisible} onClose={() => setUploadModalVisible(false)}>
          <CModalHeader onClose={() => setUploadModalVisible(false)}>
            <CModalTitle>{t('Backoffice.productUploadModal.Title')}</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <ReactMarkdown>{legalNoticeProductUpload}</ReactMarkdown>
          </CModalBody>
          <CModalFooter>
            <CButton color="secondary" onClick={() => setUploadModalVisible(false)}>
              {t('Backoffice.productUploadModal.Close')}
            </CButton>
            <CButton color="primary" onClick={sendToServer}>
              {t('Backoffice.Toolbar.SendToServerButton.Label')}
            </CButton>
          </CModalFooter>
        </CModal>
      </Spinner>
    </div>
  )
}
