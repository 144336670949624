import { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { AuthContext, ROLE } from 'src/contexts/authContext'

export const useNavigatePartnerTypePage = () => {
  const { role } = useContext(AuthContext)

  switch (role) {
    case ROLE.BUILDING_CONTRACTOR_ADMIN:
      return <Navigate to="buildingcontractors" replace />

    case ROLE.MANUFACTURER_ADMIN:
      return <Navigate to="manufacturers" replace />
    default:
      return <Navigate to="manufacturers" replace />
  }
}
