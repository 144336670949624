import React, { useContext, useState } from 'react'
import * as Yup from 'yup'
import { FormTextInput } from 'src/components/forms/FormTextInput'
import { useTranslation } from 'react-i18next'
import FormSelectMultiplePartnerRoles from 'src/components/forms/selects/FormSelectMultiplePartnerRoles'
import { updatePerson } from 'src/services/requests/users'
import { Formik, Form } from 'formik'
import { EditDrawerHeading } from 'src/components/table/EditDrawerHeading'
import { EditDrawerFooter } from 'src/components/table/EditDrawerFooter'
import { FormSelectInput } from 'src/components/forms/FormSelectInput'
import { FormCheckInput } from 'src/components/forms/FormCheckInput'
import { FormDatePicker } from 'src/components/forms/FormDatePicker'
import { CFormFeedback } from '@coreui/react-pro'
import { AuthContext, ROLE } from 'src/contexts/authContext'
import { UserDataContext } from 'src/contexts/userDataContext'

export const EditUserForm = ({
  rowData,
  fetchData,
  setEditOffcanvasVisible,
  setDeactivateOffcanvasVisible,
  setIsLoading,
  unsavedChangesOffcanvasVisible,
  setOverviewOffcanvasVisible,
  setUnsavedChangesOffcanvasVisible,
  initialValuesChanged,
  setInitialvaluesChanged,
}) => {
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  const authContext = useContext(AuthContext)
  const { userData } = useContext(UserDataContext)

  const emailDomain = '@' + userData.email.split('@')[1]

  const auth = useContext(AuthContext)
  const userPermissions = [auth.role]
  const isAdmin = userPermissions.includes(ROLE.CUBOTOO_ADMIN)

  const { t } = useTranslation()
  const handleSubmit = async (values) => {
    setIsLoading(true)
    const body = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      phone: values.phone || undefined,
      date_of_birth: values.dateOfBirth || undefined,
      active: values.active || undefined,
      language: values.language || undefined,
      partner_roles: values.partnerRoles.map((partner) => {
        return {
          buy_limit: partner.buyLimit || undefined,
          partner_id: partner.partnerId,
          currency: partner.currency || undefined,
          role: partner.role,
        }
      }),
    }

    try {
      await updatePerson(rowData.id, JSON.stringify(body))
      await fetchData()
      setEditOffcanvasVisible(false)
      setOverviewOffcanvasVisible(true)
    } catch (error) {
      setErrorMsg(error.response.data.error)
      setError(true)
    }
    setIsLoading(false)
  }

  if (!rowData) return

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        firstName: rowData.first_name,
        lastName: rowData.last_name,
        email: rowData.email,
        phone: rowData.phone || '',
        dateOfBirth: rowData.date_of_birth || '',
        active: rowData.active || 'active',
        language: rowData.language || 'de',
        partnerRoles: [
          ...rowData.partner_roles.map((role) => {
            return {
              buyLimit: role.buy_limit || '',
              partnerId: role.partner_id,
              currency: 'CHF',
              role: role.role,
            }
          }),
        ],
      }}
      onSubmit={(values) => handleSubmit(values)}
      validationSchema={Yup.object({
        firstName: Yup.string().required(t('Yup.validation.error.required')),
        lastName: Yup.string().required(t('Yup.validation.error.required')),
        email: Yup.string()
          .required(t('Yup.validation.error.required'))
          .email(t('Yup.validation.error.invalid.email'))
          .when([], {
            is: () => !isAdmin,
            then: Yup.string().matches(
              new RegExp(emailDomain),
              t('Yup.validation.error.invalid.domainEmail'),
            ),
          }),
        phone: Yup.string()
          .min(10, t('Yup.validation.error.invalid.phone.minLength'))
          .max(20, t('Yup.validation.error.invalid.phone.maxLength')),
        active: Yup.string().required(t('Yup.validation.error.required')),
        language: Yup.string().required(t('Yup.validation.error.required')),
        partnerRoles: Yup.array().of(
          Yup.object().shape({
            partnerId: Yup.string().required(t('Yup.validation.error.required')),
            role: Yup.string().required(t('Yup.validation.error.required')),
            buyLimit: Yup.number(t('Yup.validation.error.invalid.number')).positive(
              t('Yup.validation.error.invalid.positiv.number'),
            ),
            currency: Yup.string().required(t('Yup.validation.error.required')),
          }),
        ),
      })}
    >
      {(props) => (
        <Form onChange={() => setInitialvaluesChanged(true)}>
          <div className="edit-drawer__body-sideicons">
            <EditDrawerHeading text={t('Backoffice.Wizard.AddUser.Step.userData')} />
            <FormTextInput
              placeholder={t('Backoffice.Wizard.AddUser.Label.FirstName')}
              type="text"
              id="firstName"
              name="firstName"
            />
            <FormTextInput
              placeholder={t('Backoffice.Wizard.AddUser.Label.LastName')}
              type="text"
              id="lastName"
              name="lastName"
            />
            <FormTextInput
              placeholder={t('Backoffice.Wizard.AddUser.Label.Email')}
              className="mb-2"
              type="text"
              id="email"
              name="email"
            />
            <FormTextInput
              placeholder={t('Backoffice.Wizard.AddUser.Label.Phone')}
              className="mb-2"
              type="text"
              id="phone"
              name="phone"
            />
            <FormDatePicker
              disabled={true}
              placeholder={t('Backoffice.Wizard.AddUser.Label.dateOfBirth')}
              locale="de-DE"
              maxDate={new Date()}
              id="dateOfBirth"
              name="dateOfBirth"
            />
            <label className="pt-2">{t('Backoffice.Wizard.AddUser.Label.userStatus')}</label>
            <FormCheckInput
              label={t('Backoffice.Wizard.AddUser.Label.userStatus.active')}
              value="active"
              type="radio"
              id="active"
              name="active"
            />
            <FormCheckInput
              label={t('Backoffice.Wizard.AddUser.Label.userStatus.inactive')}
              value="inactive"
              type="radio"
              id="active"
              name="active"
            />
            <FormSelectInput
              placeholder={t('Backoffice.Wizard.AddUser.Label.language')}
              className="mb-2"
              type="text"
              id="language"
              name="language"
              options={[
                { label: t('Global.Language.English'), value: 'en' },
                { label: t('Global.Language.German'), value: 'de' },
                { label: t('Global.Language.French'), value: 'fr' },
                { label: t('Global.Language.Italian'), value: 'it' },
              ]}
            />
            <EditDrawerHeading
              text={t('Backoffice.Wizard.AddUser.Step.partners')}
              amount={props.values.partnerRoles.length}
            />
            <FormSelectMultiplePartnerRoles />
            {error && (
              <CFormFeedback className="text-start" invalid>
                {errorMsg}
              </CFormFeedback>
            )}
          </div>
          <EditDrawerFooter
            initialValuesChanged={initialValuesChanged}
            setEditOffcanvasVisible={setEditOffcanvasVisible}
            setDeactivateOffcanvasVisible={setDeactivateOffcanvasVisible}
            unsavedChangesOffcanvasVisible={unsavedChangesOffcanvasVisible}
            setUnsavedChangesOffcanvasVisible={setUnsavedChangesOffcanvasVisible}
          />
        </Form>
      )}
    </Formik>
  )
}
