import { CSpinner } from '@coreui/react-pro'
import React from 'react'

export const DrawerLoadingOverlay = ({ isLoading }) => {
  if (!isLoading) return null
  return (
    <div className="position-absolute bg-dark top-0 left-0 w-100 h-100 opacity-25">
      <div className="position-absolute top-50 start-50 translate-middle">
        <CSpinner color="secondary" style={{ width: '100px', height: '100px' }} />
      </div>
    </div>
  )
}
