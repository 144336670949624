import { CButton, COffcanvas, COffcanvasBody } from '@coreui/react-pro'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DrawerLoadingOverlay } from './DrawerLoadingOverlay'

export const DeactivateDrawer = ({
  offcanvasVisible,
  deleteRows,
  deactivateRows,
  setOffcanvasVisible,
  rowsSelected,
  identifier,
  drawerMode,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()
  const areMultipleRowsSelected = rowsSelected.length > 1

  useEffect(() => {
    rowsSelected.length === 0 && setOffcanvasVisible(false)
  })

  const handleDelete = async () => {
    try {
      setIsLoading(true)
      await deleteRows([...rowsSelected.map((row) => row.id)])
      setOffcanvasVisible(false)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const handleDeactivate = async () => {
    try {
      setIsLoading(true)
      await deactivateRows([...rowsSelected.map((row) => row.id)])
      setOffcanvasVisible(false)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }
  return (
    <COffcanvas
      placement="end"
      visible={offcanvasVisible}
      backdrop={false}
      onHide={() => setOffcanvasVisible(false)}
      className="deactivate-drawer"
    >
      <COffcanvasBody className="deactivate-drawer__body d-flex flex-column justify-content-center align-items-center">
        {areMultipleRowsSelected ? (
          <DeactivePartners
            rowsSelected={rowsSelected}
            identifier={identifier}
            drawerMode={drawerMode}
          />
        ) : (
          <DeactivePartner
            rowsSelected={rowsSelected}
            identifier={identifier}
            drawerMode={drawerMode}
          />
        )}
        {/* dynamic paragraph */}
        <p className="text-center">
          {areMultipleRowsSelected
            ? t(`Backoffice.drawer.${drawerMode}.ParagraphMultiple`, {
                value: identifier,
              })
            : t(`Backoffice.drawer.${drawerMode}.Paragraph`, {
                value: removeChars(identifier),
              })}
        </p>
        <CButton
          color="danger"
          onClick={drawerMode === 'delete' ? handleDelete : handleDeactivate}
          className="text-uppercase"
        >
          {t(`Backoffice.drawer.${drawerMode}.confirmButton`)}
        </CButton>
        <CButton
          color="ghost"
          style={{ marginTop: '10px' }}
          onClick={() => setOffcanvasVisible(false)}
          className="text-uppercase"
        >
          {/* dynamic keep button */}
          {t(`Backoffice.drawer.deactivate.DenyButton`, {
            value: areMultipleRowsSelected ? identifier : removeChars(identifier),
          })}
        </CButton>
      </COffcanvasBody>
      <DrawerLoadingOverlay isLoading={isLoading} />
    </COffcanvas>
  )
}

const DeactivePartner = ({ rowsSelected, identifier, drawerMode }) => {
  const { t } = useTranslation()
  const partnerName =
    rowsSelected[0]?.name || rowsSelected[0]?.first_name + ' ' + rowsSelected[0]?.last_name
  return (
    <>
      <h2 className="text-center">
        {t(`Backoffice.drawer.${drawerMode}.Header`, { value: removeChars(identifier) })}
      </h2>
      <p className="fw-bolder text-center">
        {t(`Backoffice.drawer.${drawerMode}.BoldParagraph`, { value: '' })}
        <br />
        {partnerName}?
      </p>
    </>
  )
}

const DeactivePartners = ({ rowsSelected, identifier, drawerMode }) => {
  const { t } = useTranslation()
  return (
    <>
      <h2 className="text-center">
        {t(`Backoffice.drawer.${drawerMode}.Header`, { value: identifier })}
      </h2>
      <p className="fw-bolder text-center">
        {t(`Backoffice.drawer.${drawerMode}.BoldParagraph`, {
          value: ` ${rowsSelected.length} ${identifier}?`,
        })}
      </p>
    </>
  )
}

const removeChars = (str) => {
  if (str.endsWith('es')) {
    return str.slice(0, -2)
  } else {
    return str.slice(0, -1)
  }
}
