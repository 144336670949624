import httpsV2 from '../../config/httpsV2'

export const getAllPartners = () => {
  return httpsV2.get('/middleware/partners')
}

export const getPartner = (id) => {
  return httpsV2.get('/middleware/partners?id=' + id)
}

export const getAllBuildingContractors = () => {
  return httpsV2.get('/middleware/partners/?type=building_contractor')
}

export const getAllManufacturerPartners = (branchId = '', status = '') => {
  const query = status ? '?status=' + status : ''
  return httpsV2.get('/middleware/manufacturer/partners/' + branchId + query)
}

export const getAllBuildingContractorsAndBranches = () => {
  return httpsV2.get('/middleware/partners/?type=building_contractor,branch')
}

export const getAllManufacturers = () => {
  return httpsV2.get('/middleware/partners/?type=manufacturer')
}

export const deletePartner = (id) => {
  return httpsV2.delete(`/middleware/partners/${id}`)
}

export const createManufacturer = (body) => {
  return httpsV2.post('/middleware/partners/manufacturers', body)
}

export const createBuildingContractor = (body) => {
  return httpsV2.post('/middleware/partners/building_contractors', body)
}

export const updateManufacturer = (id, body) => {
  return httpsV2.patch('/middleware/partners/manufacturers/' + id, body)
}

export const updateBuildingContractor = (id, body) => {
  return httpsV2.patch('/middleware/partners/building_contractors/' + id, body)
}

export const getAllBranches = () => {
  return httpsV2.get('/middleware/partners?type=branch')
}
