import moment from 'moment'

export const MAX_YEARS = 5

export const BULK_ACTIONS = {
  SET_DELIVERIES: 'BulkSetDeliveries',
  ADD_DELIVERIES: 'BulkAddDeliveries',
  FINALIZE_NOW: 'BulkFinalize',
  BUY_NOW: 'BuyNow',
  APPROVE: 'BulkApprove',
  REJECT: 'BulkReject',
  UPDATE: 'BulkUpdate',
  REQUEST: 'BulkSendRequest',
  DISCOUNT: 'Discount',
  BC_APPROVE: 'BcApprove',
  SEND_ERP: 'BulkErp',
  DELIVERY_COST: 'DeliveryCost',
  DELIVERY_SURCHARGES: 'DeliverySurcharges',
  MATERIAL_SURCHARGES: 'MaterialSurcharges',
}

export const PRIMARY_ACTIONS = [
  BULK_ACTIONS.REQUEST,
  BULK_ACTIONS.FINALIZE_NOW,
  BULK_ACTIONS.SEND_ERP,
  BULK_ACTIONS.REJECT,
  BULK_ACTIONS.APPROVE,
  BULK_ACTIONS.BC_APPROVE,
  BULK_ACTIONS.UPDATE,
]
export const MF_ACTIONS = [
  BULK_ACTIONS.DISCOUNT,
  BULK_ACTIONS.DELIVERY_COST,
  BULK_ACTIONS.DELIVERY_SURCHARGES,
  BULK_ACTIONS.MATERIAL_SURCHARGES,
]
// ignore deliveries for these actions
export const IGNORE_DELIVERIES = [BULK_ACTIONS.ADD_DELIVERIES, BULK_ACTIONS.BUY_NOW, ...MF_ACTIONS]

export const IGNORE_DELIVERIES_IN_PAST = [
  BULK_ACTIONS.ADD_DELIVERIES,
  BULK_ACTIONS.FINALIZE_NOW,
  BULK_ACTIONS.BUY_NOW,
  BULK_ACTIONS.SET_DELIVERIES,
  ...MF_ACTIONS,
]

export function getFormattedDate(date = new Date()) {
  return `${moment(date).format('DD.MM.YYYY')}, ${moment(date).format('HH:mm:ss')}`
}

export function getDisabledWeekendDates() {
  const disabledDates = []
  const currentDate = new Date()

  const endDate = new Date(
    currentDate.getFullYear() + MAX_YEARS,
    currentDate.getMonth(),
    currentDate.getDate(),
  )

  while (currentDate <= endDate) {
    if (currentDate.getDay() === 0 || currentDate.getDay() === 6) {
      disabledDates.push(new Date(currentDate))
    }
    currentDate.setDate(currentDate.getDate() + 1)
  }

  return disabledDates
}

export const checkPastDeliveries = (product) => {
  if (!product.deliveries) return true

  return product.deliveries?.some((delivery) => {
    const now = moment().startOf('day')
    const deliveryDate = moment(delivery.delivery_date)

    return deliveryDate.diff(now) < 0
  })
}

export const getMinimumOrderQuantity = (product) => {
  const orderingUnit = product?.orderingUnits.find((unit) => unit.id === product.orderingUnitId)
  return orderingUnit?.minimumOrderQuantity
}

export const getAddress = (address) => {
  return `${address?.street} ${address?.house_nr}
  ${address?.city}
  ${address?.postal_code},${address?.country}`
}

export const getProductName = (product, lang) => {
  const name = product?.find((item) => item?.language === lang || item?.language === 'de')
  return name?.value
}

export const getNotSelectedProducts = (products, state, checked, action) => {
  const ignoreDeliveries = ['BuyNow', 'BulkAddDeliveries']
  const ignorePastDeliveries = ['BuyNow', 'BulkAddDeliveries', 'BulkSetDeliveries', 'BulkFinalize']

  return products?.filter((product) => {
    return (
      !checked?.some((item) => item?.id === product?.id) && // not in checked list
      state.includes(product.state) && // filtering requiered state
      (ignorePastDeliveries.includes(action) || !checkPastDeliveries(product)) &&
      (ignoreDeliveries.includes(action) || product.deliveries?.length > 0)
    )
  })
}

export const getAllowedStates = (permissions, action) => {
  const permission = permissions?.find((permission) => permission.action === action)
  return permission ? permission.allowedStates : []
}

export const filterItemsWithDeliveries = (items) => {
  return items?.filter((item) => item?.deliveries?.length)
}

export const filterItemsWithoutPastDeliveries = (items) => {
  return items?.filter((item) => !checkPastDeliveries(item))
}

export const getFilteredListWithDeliveries = (filteredList = [], allowedStates = []) => {
  return filteredList?.filter(
    (product) => product.deliveries?.length && allowedStates.includes(product.state),
  )
}

export const ArrowIcon = ({ flip }) => {
  return (
    <svg
      fill="#000000"
      width="12"
      height="15"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 330 330"
      transform={flip ? 'rotate(270)' : 'rotate(90)'}
    >
      <path
        id="XMLID_222_"
        d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001 c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213 C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606 C255,161.018,253.42,157.202,250.606,154.389z"
      ></path>
    </svg>
  )
}

export const hashMappings = {
  '#new-items': 'new_items',
  '#in-negotiation': 'in_negotiation',
}

export const emptyDiscountItem = {
  value: 0,
  type: '',
  onNetPrice: false,
}

export const emptyDeliveryCostsItem = {
  id: 'new-0',
  value: '',
  type: '',
  with_surcharges: false,
  for_selected_product: false,
}

export const emptySurchargesItem = {
  id: 'new-0',
  description: '',
  per_base_unit: false,
  value: 0,
  type: '',
  delivery_id: '',
}
