import React from 'react'
import { CRow, CCard, CFormInput, CCol } from '@coreui/react-pro'
import * as Excel from 'exceljs'

const uploadWorkbookWrapper = (action, onUpload, postProcess, event) => {
  onUpload()
  uploadWorkbook(action, postProcess, event)
}

/**
 * Uploads the workbook and calls the function (action) to process the uploaded workbook.
 * The action function needs to trigger the callback function postProcess.
 *
 * @param {*} action
 * @param {*} onUpload
 * @param {*} postProcess
 * @param {*} event
 */
export const uploadWorkbook = (action, postProcess, event) => {
  let input = event.target
  let file = input.files[0]

  if (file) {
    let reader = new FileReader()

    reader.onload = (event) => {
      new Excel.Workbook().xlsx.load(event.target.result).then((workbook) => {
        action(workbook, postProcess, file)
      })
    }

    reader.readAsArrayBuffer(file)
  }
}

/**
 * Create columndefinitions (can be used by BTable) based on the first row of an excel file
 * @param {*} workbook instance
 * @param {*} name of sheet
 * @returns columndefinitions based on first row of excel file
 */
export const sheet2ColumnDef = async (wb, sheetName, selectedBC) => {
  const presavedColumns =
    localStorage.getItem(`${sheetName}-columns`) &&
    JSON.parse(localStorage.getItem(`${sheetName}-columns`))

  if (presavedColumns) return presavedColumns
  let columns = []
  let sheet = getSheetByName(wb, sheetName)
  let actualColumnCount = sheet.actualColumnCount

  for (let i = 1; i <= actualColumnCount; ++i) {
    let titleRowNumber = 1,
      dataRowNumber = 2
    if (selectedBC) {
      titleRowNumber = 3
      dataRowNumber = 4
    }
    if (sheet.getCell(titleRowNumber, i).value.toString().replaceAll(/\s/g, '').length === 0) {
      continue
    }

    columns.push({
      field: sheet.getCell(titleRowNumber, i).value.toString(),
      headerName: sheet.getCell(titleRowNumber, i).value.toString(),
      suppressSizeToFit: true,
      editable: false,
      cellRenderer:
        typeof sheet.getCell(dataRowNumber, i)?.value === 'string' &&
        sheet.getCell(dataRowNumber, i)?.value?.startsWith('http')
          ? 'HyperLinkCellRenderer'
          : undefined,
    })
  }

  return columns
}

/**
 * Iterate all the workbooks sheets and return the sheet names in an array
 *
 * @param {*} wb workbook
 */
const getWorksheetNames = (wb) => {
  let sheetNames = []
  wb.worksheets.forEach((sheet) => {
    sheetNames.push(sheet.name)
  })
  return sheetNames
}
//Returns the sheet with the given name if it exists
/**
 *
 * @param {*} wb workbook instance
 * @param {*} sheetName name of sheet as string
 * @returns sheet instance
 */
export const getSheetByName = (wb, sheetName) => {
  let result = null
  if (!wb) throw 'no workbook specified'
  wb.worksheets.forEach((sheet) => {
    if (sheet.name.toLowerCase() === sheetName) {
      result = sheet
    }
  })
  return result
}

/**
 * Read excel file data starting from row number 2. Note: row number indices start from 1 in exceljs
 * @param {workbook} wb
 * @returns table data
 */

export const readSheedData = (sheet, columndefs, selectedBC) => {
  let data = []
  sheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
    if (selectedBC) {
      if (rowNumber <= 3) return
    } else {
      if (rowNumber === 1) return
    }
    let obj = {}
    for (let i = 0; i < columndefs.length; ++i) {
      if (row.getCell(i + 1).value !== null && row.getCell(i + 1).value !== undefined) {
        obj[columndefs[i].field] = row.getCell(i + 1).value.toString()
      }
    }
    data.push(obj)
  })

  return data
}

export const Upload = ({
  visible = true,
  action = (workbook, postProcess, file) => {},
  onUpload = () => {},
  postUpload = () => {},
}) => {
  if (visible) {
    return (
      <CRow>
        <CCol>
          <CCard>
            <CFormInput
              size="sm"
              type="file"
              onChange={uploadWorkbookWrapper.bind(null, action, onUpload, postUpload)}
            />
          </CCard>
        </CCol>
      </CRow>
    )
  }
  return <></>
}
