import { CCol, CFormFeedback, CFormLabel, CRow } from '@coreui/react-pro'
import { Formik, Form } from 'formik'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormCheckInput } from 'src/components/forms/FormCheckInput'
import { FormSelectInput } from 'src/components/forms/FormSelectInput'
import { FormTextInput } from 'src/components/forms/FormTextInput'
import { FormSelectCountriesDisabled } from 'src/components/forms/selects/FormSelectCountriesDisabled'
import { EditDrawerFooter } from 'src/components/table/EditDrawerFooter'
import { EditDrawerHeading } from 'src/components/table/EditDrawerHeading'
import { AuthContext } from 'src/contexts/authContext'
import { updateBuildingContractor, updateManufacturer } from 'src/services/requests/partners'
import { createBuildingContractorBody, createManufacturerBody } from './createPartnerBody'
import { TABTYPE, useBackofficeParams } from 'src/hooks/useBackofficeParams'
import { updateManufacturerValidation } from './validationSchemas/updateManufacturer'
import { updateBuildingContractorValidation } from './validationSchemas/updateBuildingContractor'
import { FormCheckLanguages } from 'src/components/forms/FormCheckLanguages'

export const EditPartnerForm = ({
  rowData,
  fetchData,
  setEditOffcanvasVisible,
  setDeactivateOffcanvasVisible,
  setIsLoading,
  unsavedChangesOffcanvasVisible,
  setUnsavedChangesOffcanvasVisible,
  initialValuesChanged,
  setInitialvaluesChanged,
  setOverviewOffcanvasVisible,
}) => {
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const { partnerType } = useBackofficeParams()
  const [isDisabledByPartnerType, setIsDisabledByPartnerType] = useState(
    partnerType === TABTYPE.BUILDINGCONTRACTORS,
  )
  const authContext = useContext(AuthContext)
  const { t } = useTranslation()

  const handleSubmit = async (values) => {
    setIsLoading(true)
    const body =
      partnerType === TABTYPE.MANUFACTURERS
        ? { ...createManufacturerBody(values) }
        : { ...createBuildingContractorBody(values) }
    try {
      partnerType === TABTYPE.MANUFACTURERS
        ? await updateManufacturer(rowData.id, JSON.stringify(body))
        : await updateBuildingContractor(rowData.id, JSON.stringify(body))
      await fetchData()
      setEditOffcanvasVisible(false)
      setOverviewOffcanvasVisible(true)
    } catch (error) {
      setErrorMsg(error.response.data.error)
      setError(true)
    }
    setIsLoading(false)
  }

  if (!rowData) return

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        type: rowData.type,
        name: rowData.name,
        prefix: rowData.prefix,
        country: 'CH',
        domain: rowData.domain || '',
        languages: rowData.languages || [],
        vatNum: rowData.legal_entity.vat_num,
        purpose: rowData.legal_entity.purpose,
        nextDayDelivery: rowData.partner_config.next_day_delivery?.toString(),
        productAvailabilityDisplay: rowData.partner_config.product_availability_display,
        videoCall: rowData.partner_config.video_call?.toString(),
        hidePrices: rowData.partner_config.hide_prices?.toString(),
        percentage: rowData.partner_config.percentage,
        paymentTerms: rowData.partner_config.payment_terms,
        settlementFeeType: rowData.partner_config.settlement_fee_type,
        settlementFeePercentage: rowData.partner_config.settlement_fee_percentage,
        HGCPartnerId: rowData.id,
        contactEmail: rowData.partner_config.contact_email,
        street: rowData.address.street,
        houseNumber: rowData.address.house_nr,
        addressCountry: 'CH',
        city: rowData.address.city,
        postalCode: rowData.address.postal_code,
        lat: rowData.address.lat || '',
        lon: rowData.address.lon || '',
        billingEmail: rowData.address.contact_email,
      }}
      onSubmit={(values) => handleSubmit(values)}
      validationSchema={
        isDisabledByPartnerType
          ? () => updateBuildingContractorValidation(t)
          : () => updateManufacturerValidation(t)
      }
    >
      {(props) => (
        <Form onChange={() => setInitialvaluesChanged(true)}>
          <div className="edit-drawer__body">
            <EditDrawerHeading text={t('Backoffice.Wizard.AddPartner.Step.LegalEntity')} />
            <FormSelectInput
              disabled
              className="my-2"
              placeholder={t('Backoffice.Wizard.AddPartner.label.typeOfPartner')}
              options={[
                { label: t('Backoffice.Wizard.AddPartner.Type.MF'), value: 'manufacturer' },
                { label: t('Backoffice.Wizard.AddPartner.Type.BC'), value: 'building_contractor' },
              ]}
              id="type"
              name="type"
              onClick={(val) => {
                if (val.target.value === 'building_contractor') {
                  setIsDisabledByPartnerType(true)
                } else {
                  setIsDisabledByPartnerType(false)
                }
              }}
            />
            <FormTextInput
              placeholder={t('Backoffice.Wizard.AddPartner.Name')}
              type="text"
              id="name"
              name="name"
            />
            <FormSelectCountriesDisabled id="country" name="country" />
            <FormTextInput
              placeholder={t('Backoffice.Wizard.AddPartner.VAT')}
              type="text"
              id="vatNum"
              name="vatNum"
            />
            <FormTextInput
              placeholder={t('Backoffice.Wizard.AddPartner.HGCPartnerID')}
              type="text"
              id="HGCPartnerId"
              name="HGCPartnerId"
            />
            {!isDisabledByPartnerType && (
              <FormTextInput
                placeholder={t('Backoffice.Wizard.AddPartner.Prefix')}
                type="text"
                id="prefix"
                name="prefix"
              />
            )}
            <FormTextInput
              placeholder={t('Backoffice.Wizard.AddPartner.Domain')}
              type="text"
              id="domain"
              name="domain"
            />
            <FormTextInput
              placeholder={t('Backoffice.Wizard.AddPartner.Purpose')}
              type="text"
              id="purpose"
              name="purpose"
            />
            <EditDrawerHeading text={t('Backoffice.Wizard.AddPartner.Step.BillingAddress')} />
            <FormTextInput
              placeholder={t('Backoffice.Wizard.AddPartner.BillingEmail')}
              type="email"
              id="billingEmail"
              name="billingEmail"
            />
            <FormSelectCountriesDisabled id="addressCountry" name="addressCountry" />
            <FormTextInput
              placeholder={t('Backoffice.Wizard.AddPartner.Street')}
              type="text"
              id="street"
              name="street"
            />
            <FormTextInput
              placeholder={t('Backoffice.Wizard.AddPartner.HouseNr')}
              type="text"
              id="houseNumber"
              name="houseNumber"
            />
            <FormTextInput
              placeholder={t('Backoffice.Wizard.AddPartner.ZipCode')}
              type="text"
              id="postalCode"
              name="postalCode"
            />
            <FormTextInput
              className="my-2"
              placeholder={t('Backoffice.Wizard.AddPartner.label.city')}
              type="text"
              id="city"
              name="city"
            />
            <FormTextInput
              placeholder={t('Backoffice.Wizard.AddPartner.Lat')}
              type="number"
              id="lat"
              name="lat"
            />
            <FormTextInput
              placeholder={t('Backoffice.Wizard.AddPartner.Lon')}
              type="number"
              id="lon"
              name="lon"
            />
            <EditDrawerHeading text={t('Backoffice.Wizard.AddPartner.Step.Configuration')} />
            <FormTextInput
              placeholder={t('Backoffice.Wizard.AddPartner.PrimaryEmail')}
              type="email"
              id="contactEmail"
              name="contactEmail"
            />
            {!isDisabledByPartnerType && (
              <CCol md="12">
                <CRow>
                  <CFormLabel>{t('Backoffice.Wizard.AddPartner.NextDayDelivery.Label')}</CFormLabel>
                </CRow>
                <CRow>
                  <CCol md="4">
                    <FormCheckInput
                      className="my-2"
                      label={t('Global.Verbal.Positive')}
                      inline
                      type="radio"
                      id="nextDayDeliveryTrue"
                      name="nextDayDelivery"
                      value="true"
                    />
                  </CCol>
                  <CCol md="4">
                    <FormCheckInput
                      className="my-2"
                      label={t('Global.Verbal.Negative')}
                      inline
                      type="radio"
                      id="nextDayDeliveryFalse"
                      name="nextDayDelivery"
                      value="false"
                    />
                  </CCol>
                </CRow>
              </CCol>
            )}
            <CCol md="12">
              <CRow>
                <CFormLabel>{t('Backoffice.Wizard.AddPartner.UsesVideoCall')}</CFormLabel>
              </CRow>
              <CRow>
                <CCol md="4">
                  <FormCheckInput
                    label={t('Global.Verbal.Positive')}
                    inline
                    type="radio"
                    id="videoCallTrue"
                    name="videoCall"
                    value="true"
                  />
                </CCol>
                <CCol md="4">
                  <FormCheckInput
                    label={t('Global.Verbal.Negative')}
                    inline
                    type="radio"
                    id="videoCallFalse"
                    name="videoCall"
                    value="false"
                  />
                </CCol>
              </CRow>
            </CCol>
            <FormTextInput
              placeholder={t('Backoffice.Wizard.AddPartner.PaymentDeadline')}
              type="number"
              id="paymentTerms"
              name="paymentTerms"
            />
            {!isDisabledByPartnerType && (
              <>
                <FormTextInput
                  placeholder={t('Backoffice.Wizard.AddPartner.AvailabilityDisplay')}
                  type="text"
                  id="productAvailabilityDisplay"
                  name="productAvailabilityDisplay"
                />
                <FormSelectInput
                  placeholder={t('Backoffice.Wizard.AddPartner.SettlementFeeType')}
                  options={[
                    { label: 'Cash Collection', value: 'cash collection' },
                    { label: 'Factoring', value: 'factoring' },
                  ]}
                  id="settlementFeeType"
                  name="settlementFeeType"
                />
                <FormTextInput
                  placeholder={t('Backoffice.Wizard.AddPartner.SettlementFeePerc')}
                  type="number"
                  id="settlementFeePercentage"
                  name="settlementFeePercentage"
                />
                <FormTextInput
                  placeholder={t('Backoffice.Wizard.AddPartner.PlatformFeePerc')}
                  type="number"
                  id="percentage"
                  name="percentage"
                />
                <CCol md="12" disabled={isDisabledByPartnerType}>
                  <CRow>
                    <CFormLabel>{t('Backoffice.Wizard.AddPartner.HidePrices')}</CFormLabel>
                  </CRow>
                  <CRow>
                    <CCol md="4">
                      <FormCheckInput
                        label={t('Global.Verbal.Positive')}
                        inline
                        type="radio"
                        id="hidePricesTrue"
                        name="hidePrices"
                        value="true"
                      />
                    </CCol>
                    <CCol md="4">
                      <FormCheckInput
                        label={t('Global.Verbal.Negative')}
                        inline
                        type="radio"
                        id="hidePricesFalse"
                        name="hidePrices"
                        value="false"
                      />
                    </CCol>
                  </CRow>
                </CCol>

                <FormCheckLanguages />
              </>
            )}
            {error && (
              <CFormFeedback className="text-start" invalid>
                {errorMsg}
              </CFormFeedback>
            )}
          </div>
          <EditDrawerFooter
            initialValuesChanged={initialValuesChanged}
            setEditOffcanvasVisible={setEditOffcanvasVisible}
            setDeactivateOffcanvasVisible={setDeactivateOffcanvasVisible}
            unsavedChangesOffcanvasVisible={unsavedChangesOffcanvasVisible}
            setUnsavedChangesOffcanvasVisible={setUnsavedChangesOffcanvasVisible}
          />
        </Form>
      )}
    </Formik>
  )
}
