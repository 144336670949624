import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { FormSelect } from './FormSelect'
import { BackofficeContext } from 'src/contexts/backofficeContext'

export const FormSelectCountriesDisabled = ({ ...props }) => {
  const { countries } = useContext(BackofficeContext)

  const { t } = useTranslation()
  return (
    <FormSelect
      disabled
      placeholder={t('Backoffice.Wizard.AddBranch.label.country')}
      options={props.countries ?? countries}
      {...props}
    />
  )
}
