import React, { useState, useEffect, useContext } from 'react'
import { PartnerForm } from './forms/FormPartner.js'
import BTable from 'src/components/table/Table'
import {
  partnersManufacturersColumnDefs,
  partnersBuildingContractorsColumnDefs,
} from './lib/backoffice-lib.js'
import {
  deletePartner,
  updateManufacturer,
  updateBuildingContractor,
  getAllManufacturers,
  getAllBuildingContractors,
} from '../../../services/requests/partners'
import { BackofficeContext } from '../../../contexts/backofficeContext'
import Notifications from '../../../components/shared/notifications/NotificationsToaster'
import updateManufacturerSchema from './validationSchemas/updateManufacturerSchema.json'
import updateBuildingContractorSchema from './validationSchemas/updateBuildingContractorSchema.json'
import { useTranslation } from 'react-i18next'
import PartnerTypesTabs from './components/PartnerTypesTabs'
import { TABTYPE, useBackofficeParams } from 'src/hooks/useBackofficeParams'
import { AuthContext, excludeRoles, ROLE } from '../../../contexts/authContext'
import { Spinner } from 'src/components/Spinner'
import AccessComponent from 'src/components/AccessComponent'
import { EditPartnerForm } from './EditPartnerForm'
import { DeactivateDrawer } from 'src/components/table/DeactivateDrawer'
import { EditDrawer } from 'src/components/table/EditDrawer'
import { OverviewDrawer } from 'src/components/table/OverviewDrawer'
import { GlobalVariablesContext } from 'src/contexts/globalVariablesContext.js'

const Validator = require('jsonschema').Validator
const validator = new Validator()

export default function BackOfficePartners() {
  const [data, setData] = useState([])
  const { updatedEntity, setUpdatedEntity } = useContext(BackofficeContext)
  const { partnerType } = useBackofficeParams()
  const authContext = useContext(AuthContext)
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)
  const [rowsSelected, setRowsSelected] = useState([])
  const [offcanvasVisible, setOffcanvasVisible] = useState(false)
  const [drawerMode, setDrawerMode] = useState('deactivate')
  const [editOffcanvasVisible, setEditOffcanvasVisible] = useState(false)
  const [overviewOffcanvasVisible, setOverviewOffcanvasVisible] = useState(false)
  const [existingStepNumber, setExistingStepNumber] = useState(0)
  const { error } = useContext(GlobalVariablesContext)
  const [newPartnerId, setNewPartnerId] = useState()

  const presavedColumns =
    localStorage.getItem(`${authContext.role}-partners-columns`) &&
    JSON.parse(localStorage.getItem(`${authContext.role}-partners-columns`))

  const fetchData = () => {
    let getPartners
    switch (partnerType) {
      case TABTYPE.MANUFACTURERS:
        getPartners = getAllManufacturers
        break
      case TABTYPE.BUILDINGCONTRACTORS:
        getPartners = getAllBuildingContractors
        break
    }
    getPartners().then((response) => {
      setData(response.data)
      setIsLoading(false)
    })
  }

  const updatePartners = async () => {
    let partnersForUpdate = updatedEntity
    let promiseArray = []
    for (let count = 0; count < partnersForUpdate.length; count++) {
      !partnersForUpdate[count].address.lon
        ? delete partnersForUpdate[count].address.lon
        : (partnersForUpdate[count].address.lon = parseFloat(partnersForUpdate[count].address.lon))
      !partnersForUpdate[count].address.lat
        ? delete partnersForUpdate[count].address.lat
        : (partnersForUpdate[count].address.lat = parseFloat(partnersForUpdate[count].address.lat))
      !partnersForUpdate[count].parent_id && delete partnersForUpdate[count].parent_id
      delete partnersForUpdate[count].created_at
      delete partnersForUpdate[count].updated_at
      partnersForUpdate[count].partner_config.video_call =
        partnersForUpdate[count].partner_config.video_call === 'true'
      partnersForUpdate[count].partner_config.payment_terms = parseInt(
        partnersForUpdate[count].partner_config.payment_terms,
      )

      let validity
      if (partnerType === TABTYPE.MANUFACTURERS) {
        partnersForUpdate[count].partner_config.hide_prices =
          partnersForUpdate[count].partner_config.hide_prices === 'true'
        partnersForUpdate[count].partner_config.next_day_delivery =
          partnersForUpdate[count].partner_config.next_day_delivery === 'true'
        partnersForUpdate[count].partner_config.percentage = parseFloat(
          partnersForUpdate[count].partner_config.percentage,
        )
        partnersForUpdate[count].partner_config.settlement_fee_percentage = parseFloat(
          partnersForUpdate[count].partner_config.settlement_fee_percentage,
        )
        validity = validator.validate(partnersForUpdate[count], updateManufacturerSchema)
      } else {
        delete partnersForUpdate[count].languages
        delete partnersForUpdate[count].prefix
        delete partnersForUpdate[count].partner_config.hide_prices
        delete partnersForUpdate[count].partner_config.next_day_delivery
        delete partnersForUpdate[count].partner_config.percentage
        delete partnersForUpdate[count].partner_config.settlement_fee_type
        delete partnersForUpdate[count].partner_config.settlement_fee_percentage
        delete partnersForUpdate[count].partner_config.product_availability_display
        validity = validator.validate(partnersForUpdate[count], updateBuildingContractorSchema)
      }
    }
    switch (partnerType) {
      case TABTYPE.MANUFACTURERS:
        partnersForUpdate.forEach((partner) => {
          promiseArray.push(
            updateManufacturer(partner).catch((error) => {
              return error
            }),
          )
        })
        break
      case TABTYPE.BUILDINGCONTRACTORS:
        partnersForUpdate.forEach((partner) => {
          promiseArray.push(
            updateBuildingContractor(partner).catch((error) => {
              return error
            }),
          )
        })
        break
    }
    Promise.all(promiseArray).then((results) => {
      results.forEach((result) => {
        if (result.response && result.response.status > 201) {
          console.log('error encountered')
        }
      })
      setUpdatedEntity([])
      fetchData()
    })
  }

  useEffect(() => {
    const loadEffect = async () => {
      await authContext.getSessionInfo()
      fetchData()
    }
    loadEffect()

    return () => setUpdatedEntity([])
  }, [partnerType])

  const deleteRows = async (rowIds) => {
    const deleteRowsRequests = await rowIds.map(async (rowId) => await deletePartner(rowId))

    Promise.all(deleteRowsRequests).then(() => {
      fetchData()
    })
  }

  const deactivateRows = async (selectedRows) => {
    const deactivateRowsRequests = selectedRows.map(async (rowId) => {
      if (partnerType === TABTYPE.MANUFACTURERS) {
        await updateManufacturer(rowId, { status: 'inactive' })
      } else {
        await updateBuildingContractor(rowId, { status: 'inactive' })
      }
    })

    await Promise.all(deactivateRowsRequests)
    fetchData()
  }

  return (
    <>
      <div className="partners-container">
        <Spinner
          message={t('Backoffice.BackOfficePartners.Label.FetchPartners')}
          pageloading={isLoading}
        >
          {error ? <Notifications type="error" message={error} /> : null}
          <AccessComponent
            allowedPermissions={excludeRoles([
              ROLE.BUILDING_CONTRACTOR_ADMIN,
              ROLE.MANUFACTURER_ADMIN,
            ])}
          >
            <PartnerTypesTabs />
          </AccessComponent>
          <BTable
            buttonCaption={t('Backoffice.AddPartnerButton.Label')}
            identifier={'partners'}
            data={data}
            buttonCallback={() => setEditOffcanvasVisible(true)}
            saveButtonCallback={() => {
              updatePartners()
            }}
            columnDefs={
              presavedColumns
                ? presavedColumns
                : partnerType === 'manufacturers'
                ? partnersManufacturersColumnDefs(t)
                : partnersBuildingContractorsColumnDefs(t)
            }
            fetchData={fetchData}
            EditForm={EditPartnerForm}
            rowsSelected={rowsSelected}
            setRowsSelected={setRowsSelected}
            deleteRows={deleteRows}
            deactivateRows={deactivateRows}
            setOffcanvasVisible={setOffcanvasVisible}
            setEditOffcanvasVisible={setEditOffcanvasVisible}
            setOverviewOffcanvasVisible={setOverviewOffcanvasVisible}
            t={t}
            setExistingStepNumber={setExistingStepNumber}
            setDrawerMode={setDrawerMode}
            newPartnerId={newPartnerId}
            setNewPartnerId={setNewPartnerId}
          />
          <EditDrawer
            rowsSelected={rowsSelected}
            offcanvasVisible={editOffcanvasVisible}
            setOffcanvasVisible={setEditOffcanvasVisible}
            fetchData={fetchData}
            editMode={rowsSelected.length > 0}
            heading={t('Backoffice.layout.subpage.partners')}
          >
            <PartnerForm
              rowsSelected={rowsSelected ?? undefined}
              rowData={rowsSelected[0] ?? undefined}
              editMode={rowsSelected.length > 0}
              fetchData={fetchData}
              setOffcanvasVisible={setEditOffcanvasVisible}
              setDeactivateOffcanvasVisible={setOffcanvasVisible}
              setOverviewOffcanvasVisible={setOverviewOffcanvasVisible}
              readOnly={false}
              setExistingStepNumber={setExistingStepNumber}
              setNewPartnerId={setNewPartnerId}
            />
          </EditDrawer>
          <DeactivateDrawer
            offcanvasVisible={offcanvasVisible}
            setOffcanvasVisible={setOffcanvasVisible}
            deleteRows={deleteRows}
            deactivateRows={deactivateRows}
            rowsSelected={rowsSelected}
            identifier={'partners'}
            setIsLoading={setIsLoading}
            drawerMode={drawerMode}
          />
          <OverviewDrawer
            offcanvasVisible={overviewOffcanvasVisible}
            setOffcanvasVisible={setOverviewOffcanvasVisible}
            setDeactivateOffcanvasVisible={setOffcanvasVisible}
            deleteRows={deleteRows}
            rowsSelected={rowsSelected}
            setEditOffcanvasVisible={setEditOffcanvasVisible}
            identifier={'partners'}
            heading={t('Backoffice.layout.subpage.partners')}
          >
            <PartnerForm
              rowsSelected={rowsSelected ?? undefined}
              rowData={rowsSelected[0] ?? undefined}
              editMode={rowsSelected.length > 0}
              fetchData={fetchData}
              setOffcanvasVisible={setEditOffcanvasVisible}
              setDeactivateOffcanvasVisible={setOffcanvasVisible}
              setOverviewOffcanvasVisible={setOverviewOffcanvasVisible}
              setIsLoading={setIsLoading}
              readOnly={true}
              setExistingStepNumber={setExistingStepNumber}
            />
          </OverviewDrawer>
        </Spinner>
      </div>
    </>
  )
}
