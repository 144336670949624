import { CFormSelect } from '@coreui/react-pro'
import { useField } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const FormSelect = ({ options, placeholder, ...props }) => {
  const { t } = useTranslation()
  if (!placeholder) placeholder = t('Placeholder.Select')
  const [field, meta] = useField(props)
  const [newOptions, setNewOptions] = useState(options)
  const isValid = !!(meta.touched && meta.error)
  const feedbackMsg = isValid ? meta.error : ''

  useEffect(() => {
    setNewOptions([placeholder, ...options])
  }, [])
  return (
    <CFormSelect
      {...field}
      {...props}
      invalid={isValid}
      valid={meta.touched && !isValid}
      feedbackInvalid={feedbackMsg}
      options={newOptions}
    />
  )
}

export const FormSelectPartners = () => {
  const getOptions = async () => {
    const res = await getAllBuildingContractors()
    const data = res.data

    const selectOptions = data.map((partner) => {
      return { label: partner.name, value: partner.id }
    })
    return selectOptions
  }
  return (
    <FormSelect
      className="my-2"
      placeholder="Partner"
      name="partner"
      id="partner"
      selectPlaceholder="Partner"
      getOptions={getOptions}
    />
  )
}
