import React, { useState, useEffect, useContext } from 'react'
import BTable from 'src/components/table/Table'
import { projectsColumnDefs } from './lib/backoffice-lib'
import { deleteProject, getAllProjects, updateProject } from 'src/services/requests/projects'
import Notifications from '../../../components/shared/notifications/NotificationsToaster'
import { getAllBranches, getAllBuildingContractors } from 'src/services/requests/partners'
import { useTranslation } from 'react-i18next'
import { AuthContext, ROLE } from '../../../contexts/authContext'
import { Conditional } from 'src/components/Conditional'
import { Spinner } from 'src/components/Spinner'
import { EditProjectForm } from './EditProjectForm'
import { ProjectForm } from './forms/FormProject.js'
import { DeactivateDrawer } from 'src/components/table/DeactivateDrawer'
import { EditDrawer } from 'src/components/table/EditDrawer'
import { OverviewDrawer } from 'src/components/table/OverviewDrawer'
import { GlobalVariablesContext } from 'src/contexts/globalVariablesContext'
import { getUsersPmvs } from 'src/services/requests/projectMaterialView'
import { getAllManufacturerPartners } from 'src/services/requests/partners'

export default function BackOfficeProjects() {
  const [data, setData] = useState([])
  const [partners, setPartners] = useState([])
  const [branches, setBranches] = useState([])
  const authContext = useContext(AuthContext)
  const { t, i18n } = useTranslation()
  const selectedLanguage = i18n.language
  const [widgetViewWVisible, setWidgetViewVisible] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [rowsSelected, setRowsSelected] = useState([])
  const [offcanvasVisible, setOffcanvasVisible] = useState(false)
  const [drawerMode, setDrawerMode] = useState('deactivate')
  const [editOffcanvasVisible, setEditOffcanvasVisible] = useState(false)
  const [overviewOffcanvasVisible, setOverviewOffcanvasVisible] = useState(false)
  const [existingStepNumber, setExistingStepNumber] = useState(0)
  const { error } = useContext(GlobalVariablesContext)
  const [newPartnerId, setNewPartnerId] = useState()
  const [allMfPartners, setAllMfPartners] = useState([])

  const presavedColumns =
    localStorage.getItem(`${authContext.role}-projects-columns`) &&
    JSON.parse(localStorage.getItem(`${authContext.role}-projects-columns`))

  const fetchData = async () => {
    const [projectResponse, bcResponse, brResponse, resAllPartners] = await Promise.all([
      getAllProjects(),
      getAllBuildingContractors(),
      getAllBranches(),
      getAllManufacturerPartners(),
    ])

    const updatedBranches = brResponse.data.map((branch) => {
      if (branch.status === 'inactive') {
        return { ...branch, name: `${branch.name} (inactive)` }
      } else {
        return branch
      }
    })
    setBranches(updatedBranches)
    setPartners(bcResponse.data)
    setAllMfPartners(resAllPartners.data)

    const branchIdsSet = new Set(updatedBranches.map((br) => br.id))
    const partnerIdsSet = new Set(bcResponse.data.map((pr) => pr.id))
    const mergedSet = new Set([...partnerIdsSet, ...branchIdsSet])

    let filteredData = projectResponse.data.map((proj) => ({
      ...proj,
      filtered_contacts:
        proj.contacts.length === 1 &&
        proj.contacts[0].bc_partner_id !== null &&
        !mergedSet.has(proj.contacts[0].bc_partner_id)
          ? [{ firstname: '', lastname: '' }]
          : [...proj.contacts],
    }))

    if ([ROLE.MANUFACTURER_ADMIN, ROLE.MANUFACTURER].includes(authContext.role)) {
      const pmvResponse = await getUsersPmvs()
      const pmvProjects = pmvResponse?.data?.map((item) => item.buildingProjectName)
      filteredData = filteredData?.filter((proj) => pmvProjects.includes(proj.name))
    }

    setData(filteredData)
    setIsLoading(false)
  }

  useEffect(() => {
    const loadEffect = async () => {
      fetchData()
    }
    loadEffect()
  }, [selectedLanguage])

  const deleteRows = async (rowIds) => {
    const deleteRowsRequests = await rowIds.map(async (rowId) => await deleteProject(rowId))

    Promise.all(deleteRowsRequests).then(() => {
      fetchData()
    })
  }

  const deactivateRows = async (selectedRows) => {
    const deactivateRowsRequests = selectedRows.map(async (rowId) => {
      await updateProject(rowId, { status: 'inactive' })
    })

    await Promise.all(deactivateRowsRequests)
    fetchData()
  }

  const getPartners = () => {
    if (authContext.role === ROLE.MANUFACTURER_ADMIN || authContext.role === ROLE.MANUFACTURER) {
      return allMfPartners
    } else {
      return partners
    }
  }

  return (
    <>
      <Conditional condition={widgetViewWVisible}>
        <Spinner
          message={t('Backoffice.BackOfficeProjects.Label.FetchProjects')}
          pageloading={isLoading}
        >
          {error ? <Notifications type="error" message={error} /> : null}
          <BTable
            buttonCaption={t('Backoffice.projects.buttonCaption')}
            identifier={'projects'}
            data={data}
            buttonCallback={() => {
              setExistingStepNumber(0)
              setEditOffcanvasVisible(true)
            }}
            columnDefs={presavedColumns ? presavedColumns : projectsColumnDefs(t)}
            fetchData={fetchData}
            EditForm={EditProjectForm}
            rowsSelected={rowsSelected}
            setRowsSelected={setRowsSelected}
            deleteRows={deleteRows}
            deactivateRows={deactivateRows}
            setOffcanvasVisible={setOffcanvasVisible}
            setEditOffcanvasVisible={setEditOffcanvasVisible}
            setOverviewOffcanvasVisible={setOverviewOffcanvasVisible}
            setExistingStepNumber={setExistingStepNumber}
            setDrawerMode={setDrawerMode}
            t={t}
            newPartnerId={newPartnerId}
            setNewPartnerId={setNewPartnerId}
          />
          <EditDrawer
            rowsSelected={rowsSelected}
            offcanvasVisible={editOffcanvasVisible}
            setOffcanvasVisible={setEditOffcanvasVisible}
            fetchData={fetchData}
            existingStepNumber={existingStepNumber}
            heading={t('Backoffice.layout.subpage.projects')}
          >
            <ProjectForm
              rowsSelected={rowsSelected ?? undefined}
              rowData={rowsSelected[0] ?? undefined}
              editMode={rowsSelected.length > 0}
              fetchData={fetchData}
              setOffcanvasVisible={setEditOffcanvasVisible}
              setDeactivateOffcanvasVisible={setOffcanvasVisible}
              setOverviewOffcanvasVisible={setOverviewOffcanvasVisible}
              setIsLoading={setIsLoading}
              partners={getPartners()}
              branches={branches}
              readOnly={false}
              deleteRows={deleteRows}
              setExistingStepNumber={setExistingStepNumber}
              setNewPartnerId={setNewPartnerId}
            />
          </EditDrawer>
          <DeactivateDrawer
            offcanvasVisible={offcanvasVisible}
            setOffcanvasVisible={setOffcanvasVisible}
            deleteRows={deleteRows}
            deactivateRows={deactivateRows}
            rowsSelected={rowsSelected}
            identifier={'projects'}
            drawerMode={drawerMode}
          />
          <OverviewDrawer
            offcanvasVisible={overviewOffcanvasVisible}
            setOffcanvasVisible={setOverviewOffcanvasVisible}
            deleteRows={deleteRows}
            rowsSelected={rowsSelected}
            setDeactivateOffcanvasVisible={setOffcanvasVisible}
            setEditOffcanvasVisible={setEditOffcanvasVisible}
            existingStepNumber={existingStepNumber}
            identifier={'projects'}
            heading={t('Backoffice.layout.subpage.projects')}
          >
            <ProjectForm
              rowsSelected={rowsSelected ?? undefined}
              rowData={rowsSelected[0] ?? undefined}
              editMode={rowsSelected.length > 0}
              fetchData={fetchData}
              setOffcanvasVisible={setEditOffcanvasVisible}
              setDeactivateOffcanvasVisible={setOffcanvasVisible}
              setOverviewOffcanvasVisible={setOverviewOffcanvasVisible}
              setIsLoading={setIsLoading}
              readOnly={true}
              partners={getPartners()}
              branches={branches}
              setExistingStepNumber={setExistingStepNumber}
            />
          </OverviewDrawer>
        </Spinner>
      </Conditional>
    </>
  )
}
