import React, { useState } from 'react'
import * as Yup from 'yup'
import Wizard, { WizardStep } from 'src/components/forms/Wizard'
import { FormCheckInput } from 'src/components/forms/FormCheckInput'
import { FormLabel } from 'src/components/forms/FormLabel'
import { CCol, CFormSelect, CRow } from '@coreui/react-pro'

import {
  updateMfErpIntegration,
  createMfErpIntegration,
} from 'src/services/requests/erpIntegration'
import { FormSelect } from 'src/components/forms/selects/FormSelect'
import { SearchableSelect } from 'src/components/forms/selects/SearchableSelect'

export default function FormErpIntegration({
  editMode = false,
  readOnly = false,
  rowData,
  setOffcanvasVisible = () => {},
  setDeactivateOffcanvasVisible = () => {},
  setExistingStepNumber = () => {},
  initialValuesChanged = false,
  setInitialvaluesChanged = () => {},
  unsavedChangesOffcanvasVisible,
  setUnsavedChangesOffcanvasVisible = () => {},
  fetchData = () => {},
  mfPartners = [],
  t,
}) {
  const [_canFinalizeWithoutERPConfirmation, setCanFinalizeWithoutERPConfirmation] = useState(
    rowData?.canFinalizeWithoutERPConfirmation || false,
  )

  const initialValues = {
    name: rowData?.partner_id,
    erp_type: rowData?.erp_type || '',
    protocol: rowData?.protocol || '',
    // status: rowData?.status || '',
    canFinalizeWithoutERPConfirmation: rowData?.canFinalizeWithoutERPConfirmation || '',
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('Yup.validation.error.required')),
    erp_type: Yup.string().required(t('Yup.validation.error.required')),
    protocol: Yup.string().required(t('Yup.validation.error.required')),
    // status: Yup.string().required('required'),
    canFinalizeWithoutERPConfirmation: Yup.boolean(),
  })

  const formSchema = {
    step: 'mfErpIntegration',
    fields: [
      'name',
      'erp_type',
      'protocol',
      //  'status',
      'canFinalizeWithoutERPConfirmation',
    ],
  }

  const handleSubmit = (values) => {
    if (editMode) {
      const body = {
        erp_type: values.erp_type,
        protocol: values.protocol,
        status: values.status,
        canFinalizeWithoutERPConfirmation: _canFinalizeWithoutERPConfirmation,
      }
      updateMfErpIntegration(rowData.id, JSON.stringify(body))
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          fetchData()
          setOffcanvasVisible(false)
        })
    } else {
      const body = {
        partner_id: values.name,
        erp_type: values.erp_type,
        protocol: values.protocol,
        connection: {
          connection: true,
        },
        canFinalizeWithoutERPConfirmation: _canFinalizeWithoutERPConfirmation,
        // status: false,
      }
      createMfErpIntegration(JSON.stringify(body))
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          fetchData()
          setOffcanvasVisible(false)
        })
    }
  }

  const partnerOptions = mfPartners?.map((partner) => {
    return { label: partner.name, value: partner.id }
  })

  return (
    <Wizard
      editMode={editMode}
      readOnly={readOnly}
      rowData={rowData}
      initialValues={initialValues}
      setOffcanvasVisible={setOffcanvasVisible}
      setDeactivateOffcanvasVisible={setDeactivateOffcanvasVisible}
      setInitialvaluesChanged={setInitialvaluesChanged}
      initialValuesChanged={initialValuesChanged}
      unsavedChangesOffcanvasVisible={unsavedChangesOffcanvasVisible}
      setUnsavedChangesOffcanvasVisible={setUnsavedChangesOffcanvasVisible}
      validationSchema={validationSchema}
      setExistingStepNumber={setExistingStepNumber}
      allFormSchema={formSchema}
      onSubmit={(values) => handleSubmit(values)}
      identifier={'mfErpIntegration'}
    >
      <WizardStep>
        <SearchableSelect
          name="name"
          label={t('Backoffice.MfErpIntegration.table.columns.partnerName')}
          placeholder={t('Backoffice.MfErpIntegration.table.columns.partnerName')}
          id="partner_name"
          options={partnerOptions}
          disabled={readOnly}
        />
        <FormSelect
          className="my-2 form-control-backoffice"
          placeholder={t('Backoffice.MfErpIntegration.table.columns.erpType')}
          name="erp_type"
          label={t('Backoffice.MfErpIntegration.table.columns.erpType')}
          id="erp_type"
          options={[{ value: 'SAP', label: 'SAP' }]}
          disabled={readOnly}
        />
        <FormSelect
          className="my-2 form-control-backoffice"
          name="protocol"
          label={t('Backoffice.MfErpIntegration.table.columns.protocol')}
          placeholder={t('Backoffice.MfErpIntegration.table.columns.protocol')}
          id="protocol"
          options={[{ value: 'EDIFACT_AS2', label: 'EDIFACT_AS2' }]}
          disabled={readOnly}
        />
        {/* <CCol md="12">
            <CRow>
              <FormLabel name="Status" />
            </CRow>
            <CRow>
              <CCol md="4">
                <FormCheckInput
                  label="active"
                  value="active"
                  type="radio"
                  id="status"
                  name="status"
                  disabled={readOnly}
                />
              </CCol>
              <CCol md="4">
                <FormCheckInput
                  label="inactive"
                  value="inactive"
                  type="radio"
                  id="status"
                  name="status"
                  disabled={readOnly}
                />
              </CCol>
            </CRow>
          </CCol> */}
        <FormCheckInput
          label={t('Backoffice.MfErpIntegration.table.columns.finalizeWithoutConfirmation')}
          id="canFinalizeWithoutERPConfirmation"
          name="finalize_without_confirmation"
          value="canFinalizeWithoutERPConfirmation"
          disabled={readOnly}
          checked={_canFinalizeWithoutERPConfirmation}
          onChange={(e) => setCanFinalizeWithoutERPConfirmation(e.target.checked)}
        />
      </WizardStep>
    </Wizard>
  )
}
