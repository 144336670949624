import { useState, useEffect } from 'react'

import * as yup from 'yup'

export const useValidEmail = (initialValue) => {
  const [email, setEmail] = useState(initialValue)
  const [emailIsValid, setEmailIsValid] = useState(true)

  useEffect(() => {
    const emailSchema = yup.object().shape({
      email: yup.string().email().required(),
    })

    if (email.length === 0) {
      setEmailIsValid(true)
      return
    }

    const isValid = emailSchema.isValidSync({ email })

    setEmailIsValid(isValid)
  }, [email])

  return { email, setEmail, emailIsValid }
}

export const useValidPassword = (initialValue) => {
  const [password, setPassword] = useState(initialValue)
  const [passwordIsValid, setPasswordIsValid] = useState(true)

  useEffect(() => {
    const passwordSchema = yup.object().shape({
      password: yup.string().min(8).required(),
    })

    if (password.length === 0) {
      setPasswordIsValid(true)
      return
    }

    const isValid = passwordSchema.isValidSync({ password })

    setPasswordIsValid(isValid)
  }, [password])

  return { password, setPassword, passwordIsValid }
}

export const useValidTempPassword = (initialValue) => {
  const [tempPassword, setTempPassword] = useState(initialValue)
  const [tempPasswordIsValid, setTempPasswordIsValid] = useState(true)

  useEffect(() => {
    const tempPasswordSchema = yup.object().shape({
      tempPassword: yup.string().min(8).required(),
    })

    if (tempPassword.length === 0) {
      setTempPasswordIsValid(true)
      return
    }

    const isValid = tempPasswordSchema.isValidSync({ tempPassword })

    setTempPasswordIsValid(isValid)
  }, [tempPassword])

  return { tempPassword, setTempPassword, tempPasswordIsValid }
}

export const useValidUsername = (initialValue) => {
  const [username, setUsername] = useState(initialValue)
  const [usernameIsValid, setUsernameIsValid] = useState(true)

  useEffect(() => {
    const usernameSchema = yup.object().shape({
      username: yup.string().email().required(),
    })

    if (username.length === 0) {
      setUsernameIsValid(true)
      return
    }

    const isValid = usernameSchema.isValidSync({ username })

    setUsernameIsValid(isValid)
  }, [username])

  return { username, setUsername, usernameIsValid }
}

export const useValidCode = (initialValue) => {
  const [code, setCode] = useState(initialValue)
  const [codeIsValid, setCodeIsValid] = useState(true)

  useEffect(() => {
    const codeSchema = yup.object().shape({
      code: yup.string().min(6).required(),
    })

    if (code.length === 0) {
      setCodeIsValid(true)
      return
    }

    const isValid = codeSchema.isValidSync({ code })

    setCodeIsValid(isValid)
  }, [code])

  return { code, setCode, codeIsValid }
}

export const validatePasswordSchema = (password) => {
  let c1 = password?.length >= 8
  let c2 = /[@#$%^&*!,.\-_+=:;|~`<>'"()?[\]{}\\/]+/
  let c3 = /[A-Z]/
  let c4 = /[a-z]/
  let c5 = /\d/
  return c1 && c2.test(password) && c3.test(password) && c4.test(password) && c5.test(password)
}

export const useValidatedPassword = (initialValue) => {
  const [password, setPassword] = useState(initialValue)
  const [passwordIsValid, setPasswordIsValid] = useState(false)

  useEffect(() => {
    const isValid = validatePasswordSchema(password)
    setPasswordIsValid(isValid)
  }, [password])

  return { password, setPassword, passwordIsValid }
}
