import httpsV2 from '../../config/httpsV2'

export const getMfErpIntegrations = () => {
  return httpsV2.get('/middleware/partner-erp-integration')
}

export const getMfErpIntegrationById = (id) => {
  return httpsV2.get('/middleware/partner-erp-integration/' + id)
}

export const createMfErpIntegration = (body) => {
  return httpsV2.post('/middleware/partner-erp-integration', body)
}

export const updateMfErpIntegration = (id, body) => {
  return httpsV2.patch('/middleware/partner-erp-integration/' + id, body)
}

export const deleteMfErpIntegration = (id) => {
  return httpsV2.delete('/middleware/partner-erp-integration/' + id)
}
export const deactivateMfErpIntegration = (id) => {
  return httpsV2.delete('/middleware/partner-erp-integration/' + id)
}
