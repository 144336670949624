import { CButton } from '@coreui/react-pro'
import { Formik, Form } from 'formik'
import React, { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import WizardStepper from './Stepper'
import { EditDrawerFooter } from 'src/components/table/EditDrawerFooter'
import uuid from 'react-uuid'

const Wizard = ({
  editMode,
  children,
  initialValues,
  onSubmit,
  stepsProperties,
  setOffcanvasVisible,
  setDeactivateOffcanvasVisible,
  setIsLoading,
  unsavedChangesOffcanvasVisible,
  setUnsavedChangesOffcanvasVisible,
  initialValuesChanged,
  setInitialvaluesChanged,
  validationSchema,
  allFormSchema,
  readOnly,
  existingStepNumber = 0,
  setExistingStepNumber,
  identifier,
  isLoading = false,
}) => {
  const [stepNumber, setStepNumber] = useState(existingStepNumber)
  const steps = React.Children.toArray(children)
  const [snapshot, setSnapshot] = useState(initialValues)
  const { t } = useTranslation()
  const step = steps[stepNumber]
  const totalSteps = steps.length
  const isLastStep = stepNumber === totalSteps - 1
  const prevStepNumberRef = useRef(0)
  const [formikKey, setFormikKey] = useState()
  // const formikKey = editMode ? initialValues.id : readOnly ? uuid() : 'new'

  useEffect(() => {
    setStepNumber(existingStepNumber)
  }, [existingStepNumber])

  useEffect(() => {
    setExistingStepNumber(stepNumber)
  }, [stepNumber])

  useEffect(() => {
    setSnapshot(initialValues)
    if (editMode && readOnly) {
      setFormikKey(uuid())
    } else {
      setFormikKey(initialValues.id ?? 'new')
    }
  }, [initialValues])

  const next = () => {
    //setSnapshot(values)
    setStepNumber(Math.min(stepNumber + 1, totalSteps - 1))
  }

  const previous = () => {
    //setSnapshot(values)
    setStepNumber(Math.max(stepNumber - 1, 0))
  }

  const handleSubmit = async (values, bag) => {
    if (step.props.onSubmit) {
      await step.props.onSubmit(values, bag)
    }
    if (editMode || isLastStep) {
      return onSubmit(values, bag)
    } else {
      bag.setTouched({})
      next(values)
    }
  }

  return (
    <Formik
      key={formikKey}
      initialValues={snapshot}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {(formik) => (
        <Form
          onChange={() => setInitialvaluesChanged(true)}
          className="d-flex flex-column justify-content-between vh-75 stepper__form-height font-spartan"
        >
          <div>
            <div
              className={`stepper__sticky py-4 ${isLoading && 'bg-inherit'}`}
              disabled={isLoading}
            >
              <WizardStepper
                stepNumber={stepNumber}
                steps={stepsProperties}
                formik={formik}
                setStepNumber={setStepNumber}
                prevStepNumberRef={prevStepNumberRef}
                allFormSchema={allFormSchema}
                readOnly={readOnly}
              />
            </div>
            <div className="stepper__form">{step}</div>
          </div>
          {isLastStep && !editMode && !readOnly && (
            <div
              className={`d-flex justify-content-between mt-3 drawer__footer ${
                isLoading && 'bg-inherit'
              } `}
              disabled={isLoading}
            >
              <CButton
                className="drawer__footer-back-btn"
                variant="outline"
                onClick={() => previous()}
                type="button"
              >
                {t('Backoffice.Wizard.back')}
              </CButton>
              <CButton
                className="mr-auto drawer__footer-btn"
                disabled={!formik.isValid}
                type="submit"
              >
                {t('Backoffice.Wizard.submit')}
              </CButton>
            </div>
          )}
          {!isLastStep && stepNumber == 0 && !editMode && !readOnly && (
            <div className="d-flex justify-content-end mt-3 drawer__footer">
              <CButton className="drawer__footer-next-btn" onClick={() => next()} type="button">
                {t('Backoffice.Wizard.next')}
              </CButton>
            </div>
          )}
          {!isLastStep && stepNumber >= 1 && !editMode && !readOnly && (
            <div className="d-flex justify-content-between mt-3 drawer__footer">
              <CButton
                className="drawer__footer-back-btn"
                variant="outline"
                onClick={() => previous()}
                type="button"
              >
                {t('Backoffice.Wizard.back')}
              </CButton>
              <CButton className="drawer__footer-next-btn" onClick={() => next()} type="button">
                {t('Backoffice.Wizard.next')}
              </CButton>
            </div>
          )}
          {editMode && !readOnly && (
            <EditDrawerFooter
              initialValuesChanged={initialValuesChanged}
              setEditOffcanvasVisible={setOffcanvasVisible}
              setOffcanvasVisible={setOffcanvasVisible}
              setDeactivateOffcanvasVisible={setDeactivateOffcanvasVisible}
              unsavedChangesOffcanvasVisible={unsavedChangesOffcanvasVisible}
              setUnsavedChangesOffcanvasVisible={setUnsavedChangesOffcanvasVisible}
              isValid={formik.isValid}
              identifier={identifier}
            />
          )}
        </Form>
      )}
    </Formik>
  )
}

export const WizardStep = ({ children }) => children

export default Wizard
