import { useLocation, useParams } from 'react-router-dom'

export const TABTYPE = {
  MANUFACTURERS: 'manufacturers',
  BUILDINGCONTRACTORS: 'buildingContractors',
}

export const useBackofficeParams = () => {
  const location = useLocation()
  const { partnerType: partnerTypeParams } = useParams()

  const partnerType = Object.values(TABTYPE).find((tab) => tab.toLowerCase() === partnerTypeParams)

  const splitPathname = location.pathname.split('/')
  let selectedTabOption = ''
  if (splitPathname[3] && splitPathname[3] === 'prices') {
    selectedTabOption = splitPathname[2] + '/' + splitPathname[3]
  } else {
    selectedTabOption = splitPathname[2]
  }
  const selectedTab = selectedTabOption || ''

  return { selectedTab, partnerType }
}
