import { useTranslation } from 'react-i18next'
import { FormCheckInput } from '../FormCheckInput'
import { FormLabel } from '../FormLabel'

export const FormContactType = ({ name, readOnly }) => {
  const { t } = useTranslation()
  return (
    <div>
      <FormLabel name={t('Backoffice.Wizard.AddProject.label.contactType')} />
      <FormCheckInput
        type="radio"
        label={t('Backoffice.Wizard.AddProject.contactType.public.label')}
        value="public"
        name={name}
        disabled={readOnly}
      />
      <FormCheckInput
        type="radio"
        name={name}
        label={t('Backoffice.Wizard.AddProject.contactType.private.label')}
        value="private"
        disabled={readOnly}
      />
    </div>
  )
}
