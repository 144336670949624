import httpsV2 from '../../config/httpsV2'

export const getProjectMaterialPreview = (branchId, contextId, restrictedForNewItems) => {
  if (restrictedForNewItems === undefined) {
    return httpsV2.get(`/public/pmv/?branchId=${branchId}&contextId=${contextId}`)
  } else {
    return httpsV2.get(
      `/public/pmv/?branchId=${branchId}&contextId=${contextId}&restrictedForNewItems=${restrictedForNewItems}`,
    )
  }
}
