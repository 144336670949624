/**
 * Created options for the BSelect component based on column definitions
 * @param {*} columnDefs2Options
 * @returns Options based on column definitions
 */
export const columnDefs2Options = (columnDefs) => {
  return columnDefs.map((item) => {
    return {
      value: item,
      label: item.headerName,
    }
  })
}

/**
 * Filter out all colum definitions where headerName is empty, or where column is not visible
 * @param {*} columnDefs
 * @returns filtered column definitions
 */
export const filterUndefinedLabels = (options) => {
  return options.filter((option) => {
    return option.label !== undefined
  })
}

/**
 * Looks up the column definition with the given id and changes its visibility according to the provided visibility parameter
 * @param {*} columnDefs column definitions
 * @param {*} id id of the columns definition
 * @param {*} visibility show or hide (true,false)
 * @returns
 */
export const setVisibility = (columnDefs, field, visibility) => {
  return columnDefs.map((item, index) => {
    if (item.field === field) {
      item.hide = !visibility
    }
    return item
  })
}
