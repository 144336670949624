import httpsV2 from '../../config/httpsV2'

export const getAllPersons = () => {
  return httpsV2.get('/middleware/persons')
}

export const createPerson = (body) => {
  return httpsV2.post('/middleware/persons', body)
}

export const updatePerson = (id, body) => {
  return httpsV2.patch('/middleware/persons/' + id, body)
}

export const deletePerson = (id) => {
  return httpsV2.delete('/middleware/persons/' + id)
}
