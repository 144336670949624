import CIcon from '@coreui/icons-react'
import { CButton } from '@coreui/react-pro'
import { cilPlus } from '@coreui/icons'
import { useTranslation } from 'react-i18next'

export const FormAddAnotherButton = ({ push, values }) => {
  const { t } = useTranslation()
  return (
    <div className="d-flex justify-content-end">
      <CButton color="link" className="d-flex link-button" onClick={() => push(values)}>
        <CIcon icon={cilPlus} size="xl" color="secondary" className="text-secondary" />
        <span className="text-uppercase">{t('Backoffice.Wizard.addAnotherButton')}</span>
      </CButton>
    </div>
  )
}
