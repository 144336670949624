import { CCol, CFormCheck, CRow } from '@coreui/react-pro'
import { useField } from 'formik'
import { useEffect } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormInvalidFeedback } from './FormInvalidFeedback'
import { FormLabel } from './FormLabel'

export const FormCheckLanguages = () => {
  const [error, setError] = useState('')
  const { t } = useTranslation()
  return (
    <div>
      <CCol md="12">
        <CRow>
          <FormLabel name={t('Backoffice.Wizard.AddPartner.AvailableLanguages')} />
        </CRow>
        <CRow>
          <CCol md="4">
            <FormCheckLanguage label={t('Global.Language.German')} value="de" setError={setError} />
          </CCol>
          <CCol md="4">
            <FormCheckLanguage label={t('Global.Language.French')} value="fr" setError={setError} />
          </CCol>
        </CRow>
        <CRow>
          <CCol md="4">
            <FormCheckLanguage
              label={t('Global.Language.English')}
              value="en"
              setError={setError}
            />
          </CCol>
          <CCol md="4">
            <FormCheckLanguage
              label={t('Global.Language.Italian')}
              value="it"
              setError={setError}
            />
          </CCol>
        </CRow>
      </CCol>
      {error && <FormInvalidFeedback feedbackMsg={error} />}
    </div>
  )
}

export const FormCheckLanguage = ({ value, label, setError }) => {
  const name = 'languages'
  const type = 'checkbox'
  const [field, meta] = useField({ name, value, type })

  useEffect(() => {
    const isValid = !!meta.error
    const feedbackMsg = isValid ? meta.error : ''
    setError(feedbackMsg)
  }, [meta.error])

  return <CFormCheck {...field} label={label} name="languages" value={value} type={type} />
}
