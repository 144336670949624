import axios from 'axios'

export const instance = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
})

instance.interceptors.response.use(
  (res) => {
    return res
  },
  async (error) => {
    if (error.response) {
      // Token was expired
      if (error.response.status === 401 || error.response.code === 'ERR_NETWORK') {
        window.localStorage.setItem('authStatus', 'SignedOut')
      }
    }
    return Promise.reject(error)
  },
)

export default instance
