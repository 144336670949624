import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import { EditDrawerFooter } from 'src/components/table/EditDrawerFooter'
import { FormCheckInput } from 'src/components/forms/FormCheckInput'
import { FormLabel } from 'src/components/forms/FormLabel'
import { CCol, CFormFeedback, CRow } from '@coreui/react-pro'
import { updateMfErpIntegration } from 'src/services/requests/erpIntegration'
import { FormSelect } from './components/FormSelect'
import { useTranslation } from 'react-i18next'

export default function EditErpIntegrationForm({
  rowData,
  fetchData = () => {},
  setEditOffcanvasVisible = () => {},
  setDeactivateOffcanvasVisible = () => {},
  unsavedChangesOffcanvasVisible,
  setUnsavedChangesOffcanvasVisible = () => {},
  initialValuesChanged,
  setInitialvaluesChanged = () => {},
}) {
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const { t } = useTranslation()

  // submit form
  const handleSubmit = async (values) => {
    const body = {
      erp_type: values.erp_type,
      protocol: values.protocol,
      status: values.status,
      finalize_without_confirmation: values.canFinalizeWithoutERPConfirmation,
    }

    try {
      await updateMfErpIntegration(rowData?.id, JSON.stringify(body))
      await fetchData()
      setEditOffcanvasVisible(false)
    } catch (err) {
      console.error(err)
      setErrorMsg(err.message.data.error)
      setError(true)
    }
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        name: rowData.partner_id,
        erp_type: rowData.erp_type,
        protocol: rowData.protocol,
        // status: rowData.status,
        finalize_without_confirmation: rowData?.finalize_without_confirmation,
      }}
      onSubmit={(values) => handleSubmit(values)}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(t('Yup.validation.error.required')),
        erp_type: Yup.string().required(t('Yup.validation.error.required')),
        protocol: Yup.string().required(t('Yup.validation.error.required')),
        // status: Yup.string().required('required'),
        canFinalizeWithoutERPConfirmation: Yup.boolean(),
      })}
    >
      <Form onChange={() => setInitialvaluesChanged(true)}>
        <div className="edit-drawer__body">
          <FormSelect
            name="name"
            label={t('Backoffice.MfErpIntegration.table.columns.partnerName')}
            placeholder={t('Backoffice.MfErpIntegration.table.columns.partnerName')}
            id="partner_name"
            disabled={readOnly}
          />
          <FormSelect
            className="my-2 form-control-backoffice"
            placeholder={t('Backoffice.MfErpIntegration.table.columns.erpType')}
            name="erp_type"
            label={t('Backoffice.MfErpIntegration.table.columns.erpType')}
            id="erp_type"
            options={[{ value: 'SAP', label: 'SAP' }]}
            disabled={readOnly}
          />
          <FormSelect
            className="my-2 form-control-backoffice"
            name="protocol"
            label={t('Backoffice.MfErpIntegration.table.columns.protocol')}
            placeholder={t('Backoffice.MfErpIntegration.table.columns.protocol')}
            id="protocol"
            options={[{ value: 'EDIFACT_AS2', label: 'EDIFACT_AS2' }]}
            disabled={readOnly}
          />
          {/* <CCol md="12">
            <CRow>
              <FormLabel name="Status" />
            </CRow>
            <CRow>
              <CCol md="4">
                <FormCheckInput
                  label="active"
                  value="active"
                  type="radio"
                  id="status"
                  name="status"
                  disabled={readOnly}
                />
              </CCol>
              <CCol md="4">
                <FormCheckInput
                  label="inactive"
                  value="inactive"
                  type="radio"
                  id="status"
                  name="status"
                  disabled={readOnly}
                />
              </CCol>
            </CRow>
          </CCol> */}
          <FormCheckInput
            label={t('Backoffice.MfErpIntegration.table.columns.finalizeWithoutConfirmation')}
            id="canFinalizeWithoutERPConfirmation"
            name="finalize_without_confirmation"
            value="canFinalizeWithoutERPConfirmation"
            disabled={readOnly}
          />
          {error && (
            <CFormFeedback className="text-start" invalid>
              {errorMsg}
            </CFormFeedback>
          )}
        </div>
        <EditDrawerFooter
          initialValuesChanged={initialValuesChanged}
          setEditOffcanvasVisible={setEditOffcanvasVisible}
          setDeactivateOffcanvasVisible={setDeactivateOffcanvasVisible}
          unsavedChangesOffcanvasVisible={unsavedChangesOffcanvasVisible}
          setUnsavedChangesOffcanvasVisible={setUnsavedChangesOffcanvasVisible}
        />
      </Form>
    </Formik>
  )
}
