import { cilFolder } from '@coreui/icons'
import { cisBuildingBusiness } from '@coreui/icons-pro'
import CIcon from '@coreui/icons-react'
import {
  CImage,
  CNavGroup,
  CNavItem,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
} from '@coreui/react-pro'
import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import RouterNavLink from 'src/components/RouterNavLink'
import cubotooLogoWhite from 'src/assets/images/avatars/logo-cubotoo-white.svg'
import { useTranslation } from 'react-i18next'
import { useBackofficeParams } from '../../hooks/useBackofficeParams'
import AccessComponent from 'src/components/AccessComponent'
import { ROLE } from 'src/contexts/authContext'

const NavLinks = [
  {
    name: 'partners',
    allowedRoles: [ROLE.BUILDING_CONTRACTOR_ADMIN, ROLE.MANUFACTURER_ADMIN, ROLE.CUBOTOO_ADMIN],
  },
  { name: 'branches', allowedRoles: [ROLE.BUILDING_CONTRACTOR_ADMIN, ROLE.CUBOTOO_ADMIN] },
  {
    name: 'projects',
    allowedRoles: [
      ROLE.BUILDING_CONTRACTOR_ADMIN,
      ROLE.MANUFACTURER_ADMIN,
      ROLE.CUBOTOO_ADMIN,
      ROLE.LEAD_BUYER,
      ROLE.BUYER,
    ],
  },
  {
    name: 'users',
    allowedRoles: [ROLE.BUILDING_CONTRACTOR_ADMIN, ROLE.MANUFACTURER_ADMIN, ROLE.CUBOTOO_ADMIN],
  },
  { name: 'products', allowedRoles: [ROLE.MANUFACTURER_ADMIN, ROLE.CUBOTOO_ADMIN] },
  { name: 'products/prices', allowedRoles: [ROLE.MANUFACTURER_ADMIN, ROLE.CUBOTOO_ADMIN] },
  { name: 'mfErpIntegration', allowedRoles: [ROLE.CUBOTOO_ADMIN] },
  { name: 'calendar', allowedRoles: [ROLE.CUBOTOO_ADMIN] },
]

const Toggler = () => {
  const { t } = useTranslation()
  return (
    <>
      <CIcon icon={cilFolder} className="" size="xl" />
      <span className="ps-2 break-spaces spartan-font">
        {t('Backoffice.layout.title.partnerManagement')}
      </span>
    </>
  )
}
const TogglerProducts = () => {
  const { t } = useTranslation()
  return (
    <>
      <CIcon icon={cisBuildingBusiness} className="" size="xl" />
      <span className="ps-2 break-spaces spartan-font">
        {t('Backoffice.layout.title.productManagement')}
      </span>
    </>
  )
}

export default function BackOfficeSidebar({ sidebarVisible }) {
  const { selectedTab } = useBackofficeParams()
  const [activeTab, setActiveTab] = useState(selectedTab)
  const { t } = useTranslation()
  const location = useLocation()

  useEffect(() => {
    setActiveTab(selectedTab)
  }, [location.pathname])

  return (
    <CSidebar className="d-flex bg-primary min-vh-100" colorScheme="dark" visible={sidebarVisible}>
      <CSidebarNav>
        <CSidebarBrand className="d-none d-md-flex bg-primary">
          <RouterNavLink className="navbar-link" to="/cubotoo">
            <CImage
              rounded
              src={cubotooLogoWhite}
              className="mx-auto my-2"
              fluid
              width={181.21}
              height={53.49}
            />
          </RouterNavLink>
        </CSidebarBrand>

        <CNavGroup visible toggler={<Toggler />}>
          {NavLinks.map((item) => (
            <AccessComponent key={item.name} allowedPermissions={[...item.allowedRoles]}>
              <CNavItem>
                <div className="d-flex flex-row">
                  {item.name === activeTab ? <div className="backoffice-sidebar-active"></div> : ''}
                  <RouterNavLink
                    to={item.name}
                    active={item.name === activeTab}
                    onClick={() => setActiveTab(item.name)}
                  >
                    <span className="break-spaces spartan-font">
                      {t(`Backoffice.layout.subpage.${item.name.replace('/', '.')}`)}
                    </span>
                  </RouterNavLink>
                </div>
              </CNavItem>
            </AccessComponent>
          ))}
        </CNavGroup>
      </CSidebarNav>
    </CSidebar>
  )
}
