import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormSelect } from './FormSelect'
import { CButton, CCol, CRow } from '@coreui/react-pro'
import { FormSelectBranchRole } from './FormSelectBranchRole'
import { AuthContext, MF_ROLES, ROLE } from 'src/contexts/authContext'
import AccessComponent from 'src/components/AccessComponent'
import { useEffect } from 'react'
import { FormCheckInput } from '../FormCheckInput'
import { useFormikContext } from 'formik'
import { SearchableSelect } from './SearchableSelect'

export const FormSelectPartnerRole = ({
  partnerRole,
  partners,
  options,
  id,
  name,
  index,
  readOnly,
}) => {
  const { setFieldValue, values } = useFormikContext()
  const [partner, setPartner] = useState()
  const [partnerType, setPartnerType] = useState(
    values.partnerRoles[index]?.partnerType ?? 'building_contractor',
  )
  const { t } = useTranslation()
  const { role } = useContext(AuthContext)

  const partnerParents = partners.filter((partner) => {
    if (partner.type === 'manufacturer') return true
    return partner.parent_id === null
  })

  useEffect(() => {
    const partner = partners.find((partner) => partner.id === partnerRole.partnerId)
    setPartner(partner)
  }, [partners])

  return (
    <>
      {!readOnly && (
        <AccessComponent allowedPermissions={[ROLE.CUBOTOO_ADMIN]}>
          <CCol md="12">
            <CRow>
              <CCol md="6">
                <FormCheckInput
                  className="custom-radio"
                  label={'Building Contractor'}
                  value="building_contractor"
                  type="radio"
                  id="building_contractor"
                  name="partnerType"
                  checked={partnerType === 'building_contractor'}
                  onChange={() => {
                    setPartner('')
                    setFieldValue(name, '')
                    setFieldValue(`partnerRoles.${index}.role`, '')
                    setPartnerType('building_contractor')
                    setFieldValue(`partnerRoles.${index}.partnerType`, 'building_contractor')
                  }}
                />
              </CCol>
              <CCol md="6">
                <FormCheckInput
                  label={'Manufacturer'}
                  value="manufacturer"
                  type="radio"
                  id="manufacturer"
                  name="partnerType"
                  checked={partnerType === 'manufacturer'}
                  onChange={() => {
                    setPartner('')
                    setFieldValue(name, '')
                    setFieldValue(`partnerRoles.${index}.role`, '')
                    setPartnerType('manufacturer')
                    setFieldValue(`partnerRoles.${index}.partnerType`, 'manufacturer')
                  }}
                />
              </CCol>
            </CRow>
          </CCol>
        </AccessComponent>
      )}
      {(partnerType === 'manufacturer' || [role].includes(ROLE.MANUFACTURER_ADMIN)) && (
        <>
          <SearchableSelect
            placeholder={t('Backoffice.Wizard.label.partner')}
            name={name}
            id={id}
            className="my-2 form-control-backoffice"
            disabled={readOnly}
            options={options.filter((partner) => partner.type === 'manufacturer')}
          />
          <FormSelect
            placeholder={t('Backoffice.Wizard.AddUser.Select.Role.PlaceHolder')}
            id={`partnerRoles.${index}.role`}
            name={`partnerRoles.${index}.role`}
            className="my-2 form-control-backoffice"
            disabled={readOnly}
            options={MF_ROLES.map((role) => {
              return {
                value: role,
                label: t(`Global.roles.${role}`),
              }
            })}
          />
        </>
      )}
      {(partnerType === 'building_contractor' || [role].includes(ROLE.BUILDING_CONTRACTOR_ADMIN)) &&
        options.filter((partner) => partner.type === 'building_contractor').length > 0 && (
          <FormSelectBranchRole
            index={index}
            partners={partnerParents}
            partner={partner}
            name={name}
            id={id}
            readOnly={readOnly}
            options={options.filter((partner) => partner.type === 'building_contractor')}
          />
        )}
    </>
  )
}
