import React, { useContext } from 'react'
import { AuthContext } from '../contexts/authContext'

const checkPermissions = (userPermissions, allowedPermissions) => {
  if (allowedPermissions.length === 0) {
    return true
  }
  return userPermissions.some((permission) => allowedPermissions.includes(permission))
}

const AccessComponent = ({ allowedPermissions, children }) => {
  const auth = useContext(AuthContext)
  const permitted = checkPermissions([auth.role], allowedPermissions)

  if (permitted) {
    return children
  }
  return null
}

export default AccessComponent
