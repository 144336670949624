import axios from 'axios'

export const instance = axios.create({
  baseURL: process.env.REACT_APP_STRAPI_API_URL,
  headers: {
    'Content-type': 'application/json',
  },
})

instance.interceptors.request.use(
  (config) => {
    const token = process.env.REACT_APP_STRAPI_API_TOKEN
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

export default instance
