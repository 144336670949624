import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

const MetaDecorator = () => {
  const { t } = useTranslation()

  return (
    <Helmet>
      <meta property="og:title" content={t('Meta.Wrapper.Title')} />
      <meta name="description" content={t('Meta.Wrapper.Description')} />
      <meta property="og:description" content={t('Meta.Wrapper.Description')} />
    </Helmet>
  )
}

export default MetaDecorator
