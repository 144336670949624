import httpsV2 from '../../config/httpsV2'

export const getAllProjects = () => {
  return httpsV2.get('/middleware/building-projects')
}

export const getBranchProjects = (branchId) => {
  return httpsV2.get(`/middleware/building-projects?partnerId=${branchId}`)
}

export const createProject = (body) => {
  return httpsV2.post('/middleware/building-projects', body)
}

export const updateProject = (id, body) => {
  return httpsV2.patch('/middleware/building-projects/' + id, body)
}

export const deleteProject = (id) => {
  return httpsV2.delete('/middleware/building-projects/' + id)
}
