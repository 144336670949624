import React, { useState } from 'react'
import { ApiGateway } from '../services/ApiGateway'

export const ServiceContext = React.createContext({})
export const ServiceContextProvider = ServiceContext.Provider

const ServiceContextWrapper = (props) => {
  const [baskets, _setBaskets] = useState([]) //list of baskets retrieved from the backend
  const [products, _setProducts] = useState([]) //llist of products retrieved from the backend

  //TODO: add further data like e.g users, ... . For each data entity add the corresponding set function

  /**
   *
   * @param {*} baskets array of basket objects
   */
  const setBaskets = (baskets) => {
    //Note: we are doing the spreading here, so the devs don't have to take spreading into account
    _setBaskets([...baskets])
  }

  /**
   *
   * @param {*} baskets array of basket objects
   */
  const setProducts = (products) => {
    //Note: we are doing the spreading here, so the devs don't have to take spreading into account
    _setProducts([...products])
  }

  /**
   * Input of search field
   */
  const getProductsBySearchTerm = (searchString) => {
    ApiGateway().getProductsBySearchTerm(searchString, setProducts)
  }

  return (
    <>
      <ServiceContextProvider
        value={{ baskets, setBaskets, products, setProducts, getProductsBySearchTerm }}
      >
        {props.children}
      </ServiceContextProvider>
    </>
  )
}
export default ServiceContextWrapper
