import React, { useState, useEffect, useContext } from 'react'
import BTable from 'src/components/table/Table'
import { deleteBranch, getAllBranches, updateBranch } from 'src/services/requests/branches'
import { getAllBuildingContractors } from 'src/services/requests/partners'
import Notifications from '../../../components/shared/notifications/NotificationsToaster'
import { BranchForm } from './forms/FormBranch.js'
import { branchesColumnDefs } from './lib/backoffice-lib'
import { useTranslation } from 'react-i18next'
import { BackofficeContext } from 'src/contexts/backofficeContext'
import { AuthContext } from '../../../contexts/authContext'
import { Spinner } from 'src/components/Spinner'
import { EditBranchForm } from './EditBranchForm'
import { DeactivateDrawer } from 'src/components/table/DeactivateDrawer'
import { EditDrawer } from 'src/components/table/EditDrawer'
import { OverviewDrawer } from 'src/components/table/OverviewDrawer'
import { GlobalVariablesContext } from 'src/contexts/globalVariablesContext'

export default function BackOfficeBranches() {
  const [data, setData] = useState([])
  const [partners, setPartners] = useState([])
  const { t } = useTranslation()
  const authContext = useContext(AuthContext)
  const { updatedEntity } = useContext(BackofficeContext)
  const [isLoading, setIsLoading] = useState(true)
  const [rowsSelected, setRowsSelected] = useState([])
  const [offcanvasVisible, setOffcanvasVisible] = useState(false)
  const [drawerMode, setDrawerMode] = useState('deactivate')
  const [editOffcanvasVisible, setEditOffcanvasVisible] = useState(false)
  const [overviewOffcanvasVisible, setOverviewOffcanvasVisible] = useState(false)
  const [existingStepNumber, setExistingStepNumber] = useState(0)
  const { error } = useContext(GlobalVariablesContext)
  const [newPartnerId, setNewPartnerId] = useState()

  const presavedColumns =
    localStorage.getItem(`${authContext.role}-branches-columns`) &&
    JSON.parse(localStorage.getItem(`${authContext.role}-branches-columns`))

  const fetchData = () => {
    getAllBuildingContractors().then((building_contractor_response) => {
      setPartners(building_contractor_response.data)
      getAllBranches().then((branches_response) => {
        let branchData = branches_response.data?.map((branch) => {
          return {
            ...branch,
            parent_name: building_contractor_response.data?.find((bc) => bc.id === branch.parent_id)
              ?.name,
          }
        })
        setData(branchData)
        setIsLoading(false)
      })
    })
  }

  const updateBranches = async () => {
    let promiseArray = []
    updatedEntity.forEach((entity) => {
      const updatedBranch = {
        type: entity.type,
        name: entity.name,
        country: entity.country,
        parent_id: entity.parent_id,
        legal_entity: {
          vat_num: entity.legal_entity.vat_num,
          purpose: entity.legal_entity.purpose,
        },
        address: {
          street: entity.address.street,
          postal_code: entity.address.postal_code,
          house_nr: entity.address.house_nr,
          city: entity.address.city,
          country: entity.address.country,
          contact_email: entity.address.country,
          lat: 38951595.71404502,
          lon: 6877521.518594325,
        },
      }
      promiseArray.push(
        updateBranch(entity.id, updatedBranch).catch((error) => {
          return error
        }),
      )
    })

    fetchData()
  }

  useEffect(() => {
    const loadEffect = async () => {
      fetchData()
    }
    loadEffect()
  }, [])

  const deleteRows = async (rowIds) => {
    const deleteRowsRequests = await rowIds.map(async (rowId) => await deleteBranch(rowId))

    Promise.all(deleteRowsRequests).then(() => {
      fetchData()
    })
  }

  const deactivateRows = async (selectedRows) => {
    const deactivateRowsRequests = selectedRows.map(async (rowId) => {
      await updateBranch(rowId, { status: 'inactive' })
    })

    await Promise.all(deactivateRowsRequests)
    fetchData()
  }

  return (
    <>
      <Spinner
        message={t('Backoffice.BackOfficeBranches.Label.FetchBranches')}
        pageloading={isLoading}
      >
        {error ? <Notifications type="error" message={error} /> : null}
        <BTable
          buttonCaption={t('Backoffice.branches.buttonCaption')}
          identifier={'branches'}
          data={data}
          buttonCallback={() => setEditOffcanvasVisible(true)}
          saveButtonCallback={() => {
            updateBranches()
          }}
          columnDefs={presavedColumns ? presavedColumns : branchesColumnDefs(t)}
          fetchData={fetchData}
          EditForm={EditBranchForm}
          rowsSelected={rowsSelected}
          setRowsSelected={setRowsSelected}
          deleteRows={deleteRows}
          deactivateRows={deactivateRows}
          setOffcanvasVisible={setOffcanvasVisible}
          setEditOffcanvasVisible={setEditOffcanvasVisible}
          setOverviewOffcanvasVisible={setOverviewOffcanvasVisible}
          setDrawerMode={setDrawerMode}
          t={t}
          setExistingStepNumber={setExistingStepNumber}
          newPartnerId={newPartnerId}
          setNewPartnerId={setNewPartnerId}
        />
        <EditDrawer
          rowsSelected={rowsSelected}
          offcanvasVisible={editOffcanvasVisible}
          setOffcanvasVisible={setEditOffcanvasVisible}
          fetchData={fetchData}
          editMode={rowsSelected.length > 0}
          t={t}
          heading={t('Backoffice.layout.subpage.branches')}
        >
          <BranchForm
            rowsSelected={rowsSelected ?? undefined}
            rowData={rowsSelected[0] ?? undefined}
            editMode={rowsSelected.length > 0}
            fetchData={fetchData}
            setOffcanvasVisible={setEditOffcanvasVisible}
            setDeactivateOffcanvasVisible={setOffcanvasVisible}
            setOverviewOffcanvasVisible={setOverviewOffcanvasVisible}
            setIsLoading={setIsLoading}
            partners={partners}
            readOnly={false}
            deleteRows={deleteRows}
            setExistingStepNumber={setExistingStepNumber}
            setNewPartnerId={setNewPartnerId}
          />
        </EditDrawer>
        <DeactivateDrawer
          offcanvasVisible={offcanvasVisible}
          setOffcanvasVisible={setOffcanvasVisible}
          deleteRows={deleteRows}
          deactivateRows={deactivateRows}
          rowsSelected={rowsSelected}
          identifier={'branches'}
          drawerMode={drawerMode}
        />
        <OverviewDrawer
          offcanvasVisible={overviewOffcanvasVisible}
          setOffcanvasVisible={setOverviewOffcanvasVisible}
          setDeactivateOffcanvasVisible={setOffcanvasVisible}
          deleteRows={deleteRows}
          rowsSelected={rowsSelected}
          setEditOffcanvasVisible={setEditOffcanvasVisible}
          identifier={'branches'}
          heading={t('Backoffice.layout.subpage.branches')}
        >
          <BranchForm
            rowsSelected={rowsSelected ?? undefined}
            rowData={rowsSelected[0] ?? undefined}
            editMode={rowsSelected.length > 0}
            fetchData={fetchData}
            setOffcanvasVisible={setEditOffcanvasVisible}
            setDeactivateOffcanvasVisible={setOffcanvasVisible}
            setOverviewOffcanvasVisible={setOverviewOffcanvasVisible}
            setIsLoading={setIsLoading}
            readOnly={true}
            partners={partners}
            setExistingStepNumber={setExistingStepNumber}
          />
        </OverviewDrawer>
      </Spinner>
    </>
  )
}
