/**
 * Filter the given array of objects. The array returned by the filter will contain only those objects,
 * where any property of an object includes the provided value
 *
 * @param {*} data array of objects to be filtered
 * @param {*} value string to be contained in any property of the given object array
 * @returns the objects of the given array where any property of an object includes the provided value
 */
export const filterAny = (data, value) => {
  if (value.length === 0) return data
  let result = data.filter((item) => {
    return searchInObject(item, value)
  })
  return result
}

/**
 * Default onValueChanged handler of ag-grid. Can be exchanged by any handler, just pass your own handler in the
 * onValueChanged property of the BTable
 * @see https://stackoverflow.com/questions/62222534/ag-grid-change-cell-color-on-cell-value-change modified @Coding Professor soludion did work
 * @param {*} params
 */
export const defaultOnValueChangedHandler = (params) => {
  if (params.oldValue !== params.newValue) {
    params.colDef.cellStyle = (p) =>
      p.rowIndex.toString() === params.node.id
        ? { backgroundColor: 'yellow' }
        : { backgroundColor: 'white' }

    params.api.refreshCells({
      force: true,
      columns: [params.column.getId()],
      rowNodes: [params.node],
    })
  }
}

/**
 * Hide all table columns which have an column index greater than the given limit.
 * The purpose of this function is to limit the maximum numbers of columns beeing
 * displayed by default in the shared table component
 * @param {*} columnDefs
 * @param {*} limit
 * @returns the potentially modified input columnDefs
 */
export const limitTable2MaxColumns = (columnDefs, limit) => {
  return columnDefs.map((item, index) => {
    if (index > limit) {
      item.hide = true
    }
    return item
  })
}

/**
 * Filter an array of objects. Only those objects where the specified property includes the given
 * value will be returned in the filtered array
 *
 * @param {*} data array of objects
 * @param {*} property object property to read
 * @param {*} value value to be included in object's property value
 * @returns filtered array
 */
export const filterData = (data, property, value) => {
  let result = data.filter((item) => {
    return item[property].toLowerCase().includes(value.toLowerCase())
  })
  return result
}

/**
 * Returns a sortfunction which alphabetically sorts an object based on the provided property
 * @param {*} property
 * @returns
 */
export const alphabeticalSort = (property) => {
  return (a, b) => {
    if (a[property] < b[property]) {
      return -1
    }
    if (a[property] > b[property]) {
      return 1
    }
    return 0
  }
}

const searchInObject = (object, value) => {
  if (object && typeof object === 'object') {
    const objectKeys = Object.keys(object)

    for (let i = 0; i < objectKeys.length; i++) {
      const key = objectKeys[i]
      const fieldValue = object[key]

      if (Object.prototype.hasOwnProperty.call(object, key)) {
        if (fieldValue !== null && typeof fieldValue === 'object') {
          if (searchInObject(fieldValue, value)) {
            return true
          }
        } else if (
          typeof fieldValue === 'string' &&
          fieldValue.toLowerCase().includes(value.toLowerCase())
        ) {
          return true
        }
      }
    }
  }

  return false
}
